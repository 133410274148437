import React from 'react';
import ReviewCard from '../../College/ReviewCard/ReviewCard';
import user1 from '../../../assets/user1.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Reviews = () => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000" className={`${className} mr-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        );
    }
    
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000" className={`${className} ml-5`} style={{ ...style, display: "block", cursor: "pointer" }} onClick={onClick}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024, // Tablets and iPads
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    centerPadding: '0', // No extra padding on sides
                }
            },
            {
                breakpoint: 768, // Mobile devices
                settings: {
                    slidesToShow: 1,
                    centerMode: true, // Centered slide on mobile
                    centerPadding: '15%', // Padding to make slide centered
                }
            }
        ],
    };
    

    return (
        <div className="px-5 pt-10 pb-14 bg-[#F2FCEB]">
            <p className="text-center md:text-lg mb-2 font-[500] text-[#666666]">STUDENT EXPERIENCE</p>
            <p className="text-black text-xl text-center font-medium mb-10">Real Voices, Real Stories: See How Our Students Are Shaping Their Futures!</p>
            <Slider {...settings}>
                <div className="p-5 md:p-10   relative w-full 
                                    sm:w-[100%] md:w-[100%] lg:w-[90%] xl:w-[90%]
                                     rounded-[20px] overflow-hidden bg-gray-200'">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
                <div className="p-5 md:p-10">
                    <ReviewCard rating={4.5} name={"Samantha Payne"} username={"samantha.90"} profile_img={user1}/>
                </div>
            </Slider>
        </div>
    );
}

export default Reviews;
