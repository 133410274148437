import React, { useState, useEffect, useMemo } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { FaSort } from 'react-icons/fa';

const AllPlacements = () => {
  const [placements, setPlacements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // Sorting state
  const navigate = useNavigate();

  
  const totalPages = Math.ceil(placements.length / entriesPerPage);

  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id;

  useEffect(() => {
    const fetchPlacements = async () => {
      try {
        const response = await axios.get(`${Base_URL}/placement/university/${login_id}`);
        setPlacements(response.data);
      } catch (error) {
        console.error('Error fetching placements:', error.response ? error.response.data : error.message);
      }
    };

    fetchPlacements();
  }, [login_id]);

  const handleEditClick = (placement) => {
    navigate(`/dashboard/placements/edit/${placement.placement_id}`, { state: { placement, login_id } });
  };

  const handleDeleteClick = async (id) => {
    try {
      const url = `${Base_URL}/placement/${id}`;
      await axios.delete(url);
      setPlacements((prevPlacements) => prevPlacements.filter((placement) => placement.placement_id !== id));
      toast.success('Placement deleted successfully.');
    } catch (error) {
      console.error('Error deleting placement:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete placement.');
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredEntries.length / entriesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Sorting logic
  const sortedPlacements = useMemo(() => {
    let sortablePlacements = [...placements];
    if (sortConfig.key) {
      sortablePlacements.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        // Handle sorting by date fields
        if (sortConfig.key === 'recruitment_date') {
          aValue = new Date(a[sortConfig.key]);
          bValue = new Date(b[sortConfig.key]);
        }

        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
      });
    }
    return sortablePlacements;
  }, [placements, sortConfig]);

  // Handle sorting
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <IoArrowUpOutline /> : <IoArrowDownOutline />;
    }
    return <FaSort color="lightgrey" />;
  };

  const filteredEntries = useMemo(() => {
    return sortedPlacements.filter((placement) =>
      placement.student_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedPlacements, searchTerm]);

  const currentEntries = filteredEntries.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const tableCellStyle = {
    color: 'rgb(115, 123, 139)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13.125px',
    fontWeight: 400,
    lineHeight: '19.6px',
    padding: '9.1px 13.125px',
    borderBottomColor: 'rgb(230, 230, 230)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Placements" breadcrumb={['Placements', 'All Placements']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins text-lg font-semibold text-gray-800">All Placements</div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/dashboard/placements/add')}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-between mb-4 pb-2">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ height: '30px', width: '200px', borderRadius: '5px', borderColor: '#d0d0d0', padding: '0 10px' }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {['student_name', 'course', 'company_name', 'recruitment_date', 'average_salary_package'].map(
                  (key, index) => (
                    <th
                      key={key}
                      className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer"
                      onClick={() => handleSort(key)}
                    >
                      <div className="flex items-center space-x-1">
                        <span>{['Student Name', 'Course', 'Company Name', 'Recruitment Date', 'Average Package'][index]}</span>
                        {getSortIcon(key)}
                      </div>
                    </th>
                  )
                )}
                <th className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((placement) => (
                  <tr key={placement.placement_id} className="hover:bg-gray-50 border-b">
                    <td style={tableCellStyle}>{placement.student_name}</td>
                    <td style={tableCellStyle}>{placement.course}</td>
                    <td style={tableCellStyle}>{placement.company_name}</td>
                    <td style={tableCellStyle}>
                      {new Date(placement.recruitment_date).toISOString().split('T')[0]}
                    </td>
                    <td style={tableCellStyle}>{placement.average_salary_package}</td>
                    <td style={tableCellStyle}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(placement)}
                          className="p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(placement.placement_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    No placements found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllPlacements;