import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux";

const EditAdmissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admission = location.state; // Get the admission data passed from AllAdmissions
  const token = useSelector((state) => state.auth.token);

  const [formData, setFormData] = useState({
    university_name: admission?.university_name || "",
    admission_desc: admission?.admission_desc || "",
  });

  const [loading, setLoading] = useState(false); // Track loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      const updatedAdmission = {
        admission_desc: formData.admission_desc,
      };

      // Make PUT request to update the admission
      await axios.put(`${Base_URL}/update/admissions/${admission.id}`, updatedAdmission, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`  // Add token authorization
      },
      withCredentials: true  // Enable withCredentials for the request
      });

      toast.success("Admission updated successfully!");
      navigate("/admin/admissions/all"); // Navigate back to the admissions list after success
    } catch (error) {
      console.error("Failed to update admission:", error.response?.data || error.message);
      toast.error("Failed to update admission. Please try again.");
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Admissions" subTitle="Edit Admission" />

      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* University Name */}
            <div className="flex flex-col">
              <label htmlFor="university_name" className="mb-2 font-medium text-sm">
                University Name
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                value={formData.university_name}
                className="border border-gray-300 p-2 rounded bg-gray-200"
                disabled
              />
            </div>

            {/* Admission Details (CKEditor) */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="admission_details" className="mb-2 font-medium text-sm">
                Admission Details
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.admission_desc}
                onChange={(event, editor) => {
                  setFormData({ ...formData, admission_desc: editor.getData() });
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
          >
            {loading ? ( // Show spinner when loading
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>

        {/* Toast for notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditAdmissions;
