import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';  // Import jwt-decode
import { Base_URL } from '../../apiConfig';

// Async thunk for login/signup
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (data, { rejectWithValue }) => {
    const { formData, actionType } = data;
    try {
      const endpoint = actionType === 0 ? '/auth/login' : '/auth/signup';
      const response = await axios.post(`${Base_URL}${endpoint}`, formData);
      console.log('login',response.data)
      return response.data; // Return the response (which includes the token)
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    registration_id: null,  // Store user id (extracted from token)
    reg_type: null, 
    name:null  ,      // Store user type (extracted from token)
    email:null,
    mobilenumber:null,
    token: Cookies.get('token') || null, // Retrieve token from cookies on initial load
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.registration_id = null;
      state.reg_type = null;
      state.token = null;
      state.name=null;
      state.email=null;
      state.mobilenumber=null;
      Cookies.remove('token'); // Clear the token from cookies
    },
    clearAuthError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        const token = action.payload.token; // Store token from response

        if (typeof token !== 'string' || token.trim() === '') {
          console.error('Invalid token specified, must be a string:', token);
          return; // Early exit if the token is invalid
        }

        // Decode the token to get the registration_id and reg_type
        const decodedToken = jwtDecode(token);
        console.log('decodedToken',decodedToken)
        state.registration_id = decodedToken.registration_id || action.payload.id; // Fallback to ID from response
        state.reg_type = decodedToken.reg_type;
        state.name= decodedToken.name// This assumes the reg_type is in the token
        state.token=token
        state.email=decodedToken.email
        state.mobilenumber=decodedToken.mobilenumber

        // Persist the token in cookies (set it with an expiration time, e.g., 1 day)
        Cookies.set('token', token, { expires: 1 });
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, clearAuthError } = authSlice.actions;
export default authSlice.reducer;
