import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useSelector } from "react-redux";

import { Base_URL } from '../../../apiConfig';
const AdminStreamForm = () => {
  const [formData, setFormData] = useState({
    stream_id: '',
    about: '',
    eligibility: '',
    jobRoles: '',
    workDescription: '',
    salary: '',
    prepBooks: '',
  });
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const { registration_id, token,name } = useSelector((state) => state.auth);
  useEffect(() => {
    // Fetch data for the dropdown options
    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/streams`);
        setDropdownOptions(response.data);
      } catch (error) {
        toast.error('Failed to load stream options.');
      }
    };
    fetchStreams();
  }, []);

  const handleInputChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.stream_id) {
      toast.error('Please select a stream.');
      return;
    }

    const dataToSubmit = {
      streams_id: selectedOption, 
      about:formData.about,
      eligibility: formData.eligibility,
      job_roles: formData.jobRoles,
      work_description: formData.workDescription,
      salary: formData.salary,
      prep_books: formData.prepBooks,
    };

    try {
      await axios.post(`${Base_URL}/create/streams_details`, dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`
        },
        withCredentials: true // This allows cookies to be sent with the request
      });

      toast.success('Stream created successfully!');
      setFormData({
        stream_id: '',
        about: '',
        eligibility: '',
        jobRoles: '',
        workDescription: '',
        salary: '',
        prepBooks: '',
      });
    } catch (error) {
      toast.error('Failed to create stream. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Admin Dashboard" subTitle="Manage Streams" />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Stream Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Stream Dropdown */}
            <div className="flex flex-col">
              <label htmlFor="stream_id" className="mb-2 font-medium text-sm">Stream</label>
              <select
                id="stream_id"
                name="stream_id"
                value={selectedOption}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                required
              >
                <option value="">Select a Stream</option>
                {dropdownOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.stream_name}
                  </option>
                ))}
              </select>
            </div>

            {/* About */}
            <div className="flex flex-col">
              <label htmlFor="about" className="mb-2 font-medium text-sm">About</label>
              <textarea
                id="about"
                name="about"
                value={formData.about}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter About"
                required
              />
            </div>

            {/* Eligibility */}
            <div className="flex flex-col">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
              <input
                type="text"
                id="eligibility"
                name="eligibility"
                value={formData.eligibility}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Eligibility"
                required
              />
            </div>

            {/* Job Roles */}
            <div className="flex flex-col">
              <label htmlFor="jobRoles" className="mb-2 font-medium text-sm">Job Roles</label>
              <input
                type="text"
                id="jobRoles"
                name="jobRoles"
                value={formData.jobRoles}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Job Roles"
                required
              />
            </div>

            {/* Work Description */}
            <div className="flex flex-col">
              <label htmlFor="workDescription" className="mb-2 font-medium text-sm">Work Description</label>
              <textarea
                id="workDescription"
                name="workDescription"
                value={formData.workDescription}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter Work Description"
                required
              />
            </div>

            {/* Salary */}
            <div className="flex flex-col">
              <label htmlFor="salary" className="mb-2 font-medium text-sm">Salary</label>
              <input
                type="text"
                id="salary"
                name="salary"
                value={formData.salary}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Salary"
                required
              />
            </div>

            {/* Prep Books */}
            <div className="flex flex-col">
              <label htmlFor="prepBooks" className="mb-2 font-medium text-sm">Prep Books</label>
              <textarea
                id="prepBooks"
                name="prepBooks"
                value={formData.prepBooks}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter Prep Books"
                required
              />
            </div>
          </div>
          <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">Submit</button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminStreamForm;
