import React from 'react';

const CareerAbout = ({ data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">About</h2>
      <p>{data?.about || "No data available."}</p>
    </div>
  );
};

export default CareerAbout;
