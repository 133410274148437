import React,{ useState,useEffect} from 'react';
import college_logo from '../../../assets/college_logo.png';
import Arrow from '../../../assets/landing/Arrow.png';
import EventCard from './EventCard/EventCard';
import NewsCard from './NewsCard/NewsCard';

const NewsEvents = ({collegeDetails,logoPath}) => {
    const [featuredNews, setFeaturedNews] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchNews = async () => {
        try {
          const response = await fetch(`https://api.collegedwarka.com/api/admin-news-announcements/university/${collegeDetails.university_id}`);
          const data = await response.json();
          
          if (data.length > 0) {
            setFeaturedNews(data[0]);
          }
        } catch (error) {
          console.error('Error fetching featured news:', error);
        }
      };
  
      fetchNews();
    }, []);  

    if (!featuredNews) {
      return <p>Loading...</p>;
    }
  
    return (
        <div className="py-8 px-5 bg-[#DCFFC4] overflow-x-hidden">
            {/* University Header */}
            <div className="text-black text-base font-medium flex flex-col gap-5">
                <div className="flex flex-col md:flex-row items-center justify-center gap-3 py-2">
                    <div className="h-[80px] w-[80px]">
                        <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="text-3xl text-black font-semibold text-center ">
                        {collegeDetails.university_name}
                    </p>
                </div>

                <p className="text-lg text-black font-semibold mb-5 text-center ">
                    News and Events
                </p>
            </div>

            {/* Featured News */}
            <div className="py-10">
                <div className="flex flex-col md:flex-row items-center relative">
                    <div className="bg-gray-500 flex-shrink-0 h-72 w-full max-w-xs rounded-xl overflow-hidden">
                        <img
                            src={featuredNews.picture ? `https://api.collegedwarka.com/api/files${featuredNews.picture}` : college_logo}
                            alt="news-image"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="text-card-container bg-white rounded-xl p-5 border-2 relative -translate-y-10 md:-translate-x-10 mt-auto z-10 md:z-50">
                        <img
                            src={Arrow}
                            alt="Arrow Icon"
                            className="arrow-icon absolute right-0 top-0 mt-2 mr-2"
                        />
                        <div className="badge-container flex mb-2 justify-center md:justify-start">
                            <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl">
                                FEATURED NEWS
                            </p>
                        </div>

                        <div>
                            <p className="text-black text-lg font-semibold mt-5">
                                {featuredNews.title}
                            </p>
                            <p className="text-xs font-medium mt-2 mb-3">
                                {new Date(featuredNews.announcement_date).toLocaleDateString()}
                            </p>
                            <p className="text-base mt-2 news_display_content">
                                {featuredNews.content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Commenting out Recent News and Upcoming Events sections
            <div className="py-10 px-4 md:px-6 flex flex-col gap-8 md:flex-row md:gap-8">
                <div className="flex-1 md:w-[30%] w-full mx-auto"> 
                    <p className="text-black text-base font-bold mb-6 text-center md:text-left leading-[26px] md:leading-normal hidden md:block">
                        Recent News
                    </p>

                    <div className="flex flex-col gap-16 justify-center items-center md:items-start">
                        <NewsCard
                            title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
                            desc={"Lorem ipsum odor amet, consectetuer adipiscing elit..."}
                            date={"October 23, 2024"}
                            image={college_logo}
                            className="w-full h-auto"
                        />
                        <NewsCard
                            title={"Lorem ipsum odor amet, consectetuer adipiscing elit "}
                            desc={"Lorem ipsum odor amet, consectetuer adipiscing elit..."}
                            date={"October 23, 2024"}
                            image={college_logo}
                            className="w-full h-auto"
                        />
                    </div>
                </div>

                <div className="flex-1 md:w-[50%] w-full mx-auto h-auto md:h-[577px] mt-10 md:mt-0">
                    <p className="text-black text-base md:ml-10 font-bold mb-6 text-center md:text-left leading-[26px] md:leading-normal">
                        Upcoming Events
                    </p>

                    <div className="bg-white flex flex-col py-5 md:h-[467px] md:w-[550px] md:ml-10 rounded-xl border-2 border-gray-200">
                        <EventCard
                            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            location={"Lorem ipsum odor amet"}
                            date={30}
                            month={"AUG"}
                            index={0}
                        />
                        <EventCard
                            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            location={"Lorem ipsum odor amet"}
                            date={30}
                            month={"AUG"}
                            index={1}
                        />
                        <EventCard
                            title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                            location={"Lorem ipsum odor amet"}
                            date={30}
                            month={"AUG"}
                            index={2}
                        />
                    </div>
                </div>
            </div>
            */}
        </div>
    )
}

export default NewsEvents;
