import React, { useState, useEffect, useMemo } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { FaSort } from 'react-icons/fa';

const AllTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const navigate = useNavigate();
  const totalPages = Math.ceil(testimonials.length / entriesPerPage);
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id;

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await axios.get(`${Base_URL}/student-testimonials/university/${login_id}`);
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching testimonials:', error.response ? error.response.data : error.message);
      }
    };
    fetchTestimonial();
  }, [login_id]);

  const handleEditClick = (testimonial) => {
    navigate(`/dashboard/testimonials/edit/${testimonial.testimonial_id}`, {
      state: { testimonial, login_id },
    });
  };

  const handleDeleteClick = async (id) => {
    try {
      const url = `${Base_URL}/student-testimonials/${id}`;
      await axios.delete(url);
      setTestimonials((prevTestimonials) =>
        prevTestimonials.filter((testimonial) => testimonial.testimonial_id !== id)
      );
      toast.success('Testimonial deleted successfully.');
    } catch (error) {
      console.error('Error deleting testimonial:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete testimonial.');
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredEntries.length / entriesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <IoArrowUpOutline /> : <IoArrowDownOutline />;
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedTestimonials = useMemo(() => {
    let sortableTestimonials = [...testimonials];
    if (sortConfig.key) {
      sortableTestimonials.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        return sortConfig.direction === 'ascending'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }
    return sortableTestimonials;
  }, [testimonials, sortConfig]);

  const filteredEntries = useMemo(() => {
    return sortedTestimonials.filter((testimonial) =>
      testimonial.student_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedTestimonials, searchTerm]);

  const currentEntries = filteredEntries.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const tableCellStyle = {
    color: 'rgb(115, 123, 139)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13.125px',
    fontWeight: 400,
    lineHeight: '19.6px',
    padding: '9.1px 13.125px',
    borderBottomColor: 'rgb(230, 230, 230)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Testimonials" breadcrumb={['Testimonials', 'All Testimonials']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins text-lg font-semibold text-gray-800">All Testimonials</div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/dashboard/testimonials/add')}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-between mb-4 pb-2">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ height: '30px', width: '200px', borderRadius: '5px', borderColor: '#d0d0d0', padding: '0 10px' }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {['student_name', 'course', 'testimonial_text'].map((key, index) => (
                  <th
                    key={key}
                    className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer"
                    onClick={() => handleSort(key)}
                  >
                    <div className="flex items-center space-x-1">
                      <span>{['Name', 'Course', 'Testimonial Text'][index]}</span>
                      {getSortIcon(key)}
                    </div>
                  </th>
                ))}
                <th className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((testimonial) => (
                  <tr key={testimonial.testimonial_id} className="hover:bg-gray-50 border-b">
                    <td style={tableCellStyle}>{testimonial.student_name}</td>
                    <td style={tableCellStyle}>{testimonial.course}</td>
                    <td style={tableCellStyle}>{testimonial.testimonial_text}</td>
                    <td style={tableCellStyle}>
                      <div className="flex space-x-2">
                      <button onClick={() => handleEditClick(testimonial)} className="p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen">
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(testimonial.testimonial_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    No testimonials found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default AllTestimonial;