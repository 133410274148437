import React, { useState, useEffect } from 'react';
import { Parser } from 'htmlparser2';
import axios from 'axios';
import Rankings from '../Overview/Rankings/Rankings';
import PlacementCard from './PlacementCard/PlacementCard';

const Placements = ({ collegeDetails }) => {
    const [placements, setPlacements] = useState([]);
    const [placementDesc, setPlacementDesc] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlacements = async () => {
            try {
                const response = await axios.get(`https://api.collegedwarka.com/api/placement-admin/university/${collegeDetails.university_id}`);
                const placementData = response.data;

                setPlacements(placementData);
                setPlacementDesc(placementData[0]?.placement_desc || '');
            } catch (err) {
                setError('Failed to fetch placement data.');
            } finally {
                setLoading(false);
            }
        };

        fetchPlacements();
    }, [collegeDetails.university_id]);

    const parseHtml = (html) => {
        const result = [];
        let currentElement = null;
        const elementStack = [];

        const parser = new Parser(
            {
                onopentag(name, attributes) {
                    const newElement = { name, attributes, children: [] };
                    if (currentElement) {
                        currentElement.children.push(newElement);
                        elementStack.push(currentElement);
                    } else {
                        result.push(newElement);
                    }
                    currentElement = newElement;
                },
                ontext(text) {
                    if (currentElement) {
                        currentElement.children.push(text);
                    } else {
                        result.push(text);
                    }
                },
                onclosetag(tagname) {
                    if (elementStack.length > 0) {
                        currentElement = elementStack.pop();
                    } else {
                        currentElement = null;
                    }
                },
            },
            { decodeEntities: true }
        );

        parser.write(html);
        parser.end();

        return result;
    };

    const renderContent = (content) => {
        return content.map((item, index) => {
            if (typeof item === "string") {
                return item;
            }

            const Tag = item.name;

            const isVoidElement = [
                "br",
                "img",
                "hr",
                "input",
                "meta",
                "link",
            ].includes(Tag);

            const props = {
                ...item.attributes,
                key: index,
                className: Tag === "table" 
                    ? "min-w-full border-collapse border border-gray-300 my-4"
                    : "",
            };

            if (Tag === "tr") {
                props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
            }

            if (Tag === "td" || Tag === "th") {
                props.className = "border border-gray-300 px-4 py-2";
            }

            if (isVoidElement) {
                return React.createElement(Tag, props);
            }

            return React.createElement(
                Tag,
                props,
                item.children && renderContent(item.children)
            );
        });
    };

    if (loading) {
        return <p className="text-center text-black font-semibold">Loading placements...</p>;
    }

    if (error) {
        return <p className="text-center text-red-500 font-semibold">{error}</p>;
    }

    const parsedHtml = placementDesc ? parseHtml(placementDesc) : [];

    return (
        <div className="py-8 px-5 bg-[#DCFFC4] min-h-screen flex flex-col items-center">
            <div className="text-black text-base font-medium flex flex-col gap-5 items-center">
                <div className="flex items-center justify-center gap-3 py-2">
                    <p className="md:text-3xl text-black font-semibold text-[20px]">
                        {collegeDetails.university_name}
                    </p>
                </div>

                <p className="text-center text-lg text-black font-[500] mb-5">Placements</p>

                {/* Display the placement description using HTML parser */}
                <div className="mb-8 prose max-w-none">
                    {placementDesc ? renderContent(parsedHtml) : <p>No placement information available.</p>}
                </div>
            </div>

            {/* Rankings */}
            {/* <Rankings /> */}

            {/* Placement Cards */}
            {/* <div className="py-5 w-full">
                <p className="py-3 text-center text-lg text-black font-semibold mb-5">
                    Top Company Placements
                </p>
                
                <div className="flex flex-wrap gap-5 justify-center text-black p-3">
                    {placements.map((placement, index) => (
                        <PlacementCard 
                            key={index}
                            company={placement.name}
                            role={placement.course || 'N/A'}
                            package={placement.keywords?.[0]?.details || 'N/A'}
                            image={`https://api.collegedwarka.com/api/files${placement.pictures?.[0] || '/default_image.png'}`}
                        />
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default Placements;

