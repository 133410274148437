import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Added useLocation
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const EditNews = () => {
  const { id } = useParams();
  const location = useLocation(); // To access passed props
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // For loading spinner

  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id || 1; // Get login_id dynamically from Redux

  const [formData, setFormData] = useState({
    university_id: login_id,
    title: '',
    content: '',
    announcement_date: '',
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');

  useEffect(() => {
    const newsData = location.state?.news; // Get passed news data
    if (newsData) {
      const formattedDate = new Date(newsData.announcement_date).toISOString().split('T')[0];
      setFormData({
        title: newsData.title,
        content: newsData.content,
        announcement_date: formattedDate,
        university_id: login_id,
      });
    }
  }, [location, login_id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('picture', image);
      }

      const response = await axios.put(`${Base_URL}/news-announcements/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Updated news data response:', response.data);
      toast.success('News data updated successfully!');
      setTimeout(() => {
        navigate('/dashboard/news/all');
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to update news data:', error.response?.data || error.message);
      toast.error('Failed to update news data. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Edit News"
        breadcrumb={['News', 'Edit News']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="title" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="content" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Content</label>
              <textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="announcement_date" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Announcement Date</label>
              <input
                type="date"
                id="announcement_date"
                name="announcement_date"
                value={formData.announcement_date}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="picture" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Upload Picture</label>
              <div className="flex border border-gray-300 rounded-md mt-2 overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]">
                  <span>Choose File</span>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button with spinner */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Updating...
              </div>
            ) : (
              'Update'
            )}
          </button>
          {error && <div className="text-red-600 mt-2">{error}</div>}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditNews;