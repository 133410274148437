import React from 'react';
import './ApplyButton.css';

const ApplyButton = ({title ,onClick,background}) => {
  return (
    <div className=" w-full my-4 ">
      <button onClick={onClick} className="w-full text-white rounded-md md:px-4 py-2 " style={{background:background}}>{title}</button>
    </div>
  );
};

export default ApplyButton;


