import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../apiConfig';
import axios from 'axios';
import nodata from '../../assets/landing/no_data.png';
import filter from '../../assets/landing/filter.png';
import SortIcon from '../../assets/landing/SortIcon.png';

const AllCoursesList = () => {
  const [wishlist, setWishlist] = useState(new Set());
  const [programList, setProgramList] = useState([]);
  const [subCoursesList, SetSubCoursesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [modeList, setModeList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredData, setFilteredData] = useState(null);

  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState([]);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
const [activeMobileFilter, setActiveMobileFilter] = useState('Mode'); // Default filter type

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch all filter options on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [programRes, subCoursesRes, cityRes, stateRes, modeRes, durationRes] = await Promise.all([
          axios.get(`${Base_URL}/get/program_master`),
          axios.get(`${Base_URL}/get/sub_courses_master`),
          axios.get(`${Base_URL}/get/city`),
          axios.get(`${Base_URL}/get/state`),
          axios.get(`${Base_URL}/get/mode_master`),
          axios.get(`${Base_URL}/get/duration_master`),
        ]);
        
        setProgramList(programRes.data);
        SetSubCoursesList(subCoursesRes.data);
        setCityList(cityRes.data);
        setStateList(stateRes.data);
        setModeList(modeRes.data);
        setDurationList(durationRes.data);
      } catch (error) {
        console.error('Failed to fetch filter data:', error);
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${Base_URL}/course-master/details`);
        setCourses(response.data);
      } catch (error) {
        toast.error('Failed to fetch courses');
        console.error('Failed to fetch courses:', error);
      }
    };

    fetchData();
    fetchCourses();
  }, []);

  // Read filters from URL on page load or when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode') ? params.get('mode').split(',').map(Number) : [];
    const duration = params.get('duration') ? params.get('duration').split(',').map(Number) : [];

    setSelectedMode(mode);
    setSelectedDuration(duration);
  }, [location.search]);

  // Fetch filtered data based on selected filters
  useEffect(() => {
    window.scrollTo(0, 0);
    const isAllFiltersEmpty = selectedMode.length === 0 && selectedDuration.length === 0;

    if (isAllFiltersEmpty) {
      // Reset to show all courses and navigate to the all courses route
      setFilteredData(null);
      if (location.pathname.includes("/filter")) navigate(`/courses/all`);
      return;
    }

    const fetchFilteredCourses = async () => {
      const filters = [
        { mode_master_id: selectedMode },
        { duration_master_id: selectedDuration },
      ];
      const filtersString = JSON.stringify(filters);

      try {
        const response = await axios.get(`${Base_URL}/course-master/filter`, { params: { filter: filtersString } });
        setFilteredData(response.data);
      } catch (error) {
        console.error('Error fetching filtered courses:', error);
      }
    };

    fetchFilteredCourses();

    const queryString = new URLSearchParams({
      mode: selectedMode.join(','),
      duration: selectedDuration.join(','),
    }).toString();

    navigate(`/courses/all/filter?${queryString}`);
  }, [selectedMode, selectedDuration]);

  const handleCheckboxChange = (filterType, value) => {
    const updateFilter = (selectedFilter, setSelectedFilter) => {
      setSelectedFilter(prev =>
        prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
      );
    };

    if (filterType === 'Mode') updateFilter(selectedMode, setSelectedMode);
    else if (filterType === 'Duration') updateFilter(selectedDuration, setSelectedDuration);
  };

  const handleClearAllFilters = filter => {
    if (filter === 'Mode') setSelectedMode([]);
    else if (filter === 'Duration') setSelectedDuration([]);
  };

  const getFilterData = filter => {
    switch (filter) {
      case 'Mode': return modeList;
      case 'Duration': return durationList;
      default: return [];
    }
  };
  const handleMobileFilterClick = () => {
    setIsMobileFilterOpen(true);
  };
  
  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const handleWishlistClick = (id) => {
    const newWishlist = new Set(wishlist);
    if (newWishlist.has(id)) {
      newWishlist.delete(id);
    } else {
      newWishlist.add(id);
    }
    setWishlist(newWishlist);
  };
  const displayData = Array.isArray(filteredData) ? filteredData : Array.isArray(courses) ? courses : [];

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-6 mt-20 flex flex-col lg:flex-row gap-6">
        <div className="w-full mt-8 hidden md:block lg:w-1/4 font-dm-sans space-y-6">
          {['Mode', 'Duration'].map((filter, idx) => (
            <div key={idx} className="p-4 border rounded-lg shadow-lg bg-[#FCFCFC]">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-dm-sans text-sm font-bold">{filter}</h3>
                <span
                  className="font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer"
                  onClick={() => handleClearAllFilters(filter)}
                >
                  Clear All
                </span>
              </div>
              <input
                type="text"
                className="w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-4"
                placeholder={`Search ${filter}`}
              />
              <ul className="space-y-2">
                {getFilterData(filter)?.map(item => (
                  <li key={item.id} className="flex items-center text-[14px] font-dm-sans font-medium">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={filter === 'Mode' ? selectedMode.includes(item.id) : selectedDuration.includes(item.id)}
                      onChange={() => handleCheckboxChange(filter, item.id)}
                    />
                    <span>{filter === 'Mode' ? item.mode_title : item.duration_title}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="w-full lg:w-3/4 flex flex-col gap-8 lg:pr-16 lg:pl-4">
          <div className="flex justify-center">
            <h2 className="font-dm-sans text-2xl lg:text-3xl text-center">All Courses</h2>
          </div>

          <div className="flex flex-col items-center gap-8">
            <div className="flex flex-col gap-8 w-full lg:max-w-6xl">
              {displayData?.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full">
                  <img src={nodata} alt="No data" className="w-40 h-40 mb-4" />
                  <p className="text-lg font-semibold text-gray-600">No courses found.</p>
                  <div className="pt-2 text-[#468EFF] cursor-pointer" onClick={() => navigate('/')}>
                    Home
                  </div>
                </div>
              ) : (
                displayData?.map(course => (
                  <div key={course.id} className="relative border-2 border-[#D1D1D1] rounded-[20px] shadow-lg p-5 flex flex-col lg:flex-row md:items-center items-start">
                    <div className="w-full flex flex-col justify-between p-2 relative">
                      <h3 className="font-dm-sans font-medium text-lg mb-1 cursor-pointer">{course.course_master_name}</h3>
                      <div className="flex flex-col lg:flex-row justify-end items-end mt-1 gap-4">
                        <div className="border border-[#3ACA2E] font-semibold text-sm px-3 py-2">
                        <div className=" text-slate-700">Total Semesters</div>
                        <div className="font-bold text-black-400 text-center">{course.semester}</div>
                        </div>
                        <div className="border border-[#3ACA2E]  font-semibold text-sm px-3 py-2">
                        <div className=" text-slate-700"> Average Fees</div>
                        <div className="font-bold text-black-400 text-center"> {course.avg_fee}</div>
                        </div>
                      </div>
                      <span className="text-gray-600 text-base mt-4">
                        {course.intro || 'Check the detailed description about this course.'}
                      </span>
                      
                      {/* Move the Apply Now button here to be under the intro and set it to full width */}
                      <button
          className="w-full p-1 bg-[#3ACA2E] text-white font-dm-sans md:text-[14px] text-[10px] font-bold rounded-[20px] mt-4 flex items-center justify-center"
          onClick={() => navigate(`/courses/details/${course.course_master_id}/${course.course_master_name}`)}
        >
          View Details
        </button>
                    </div>
                       {/* Wishlist Heart Icon */}
                  <div className="absolute top-2 right-2">
                    <button onClick={() => handleWishlistClick(course.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-6 w-6 ${wishlist.has(course.id) ? 'text-red-500' : 'text-gray-400'}`}
                        fill={wishlist.has(course.id) ? 'red' : 'none'} 
                        viewBox="0 0 24 24"
                        stroke={wishlist.has(course.id) ? 'red' : 'currentColor'} 
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                        />
                      </svg>
                    </button>
                  </div>
                  </div>
                ))
                
              )}
            </div>
          </div>
        </div>

          {/* Sticky Footer Division for Mobile */}
    <div className="fixed bottom-0 left-0 w-full bg-[#ffffff] border-t border-[#DADADA] lg:hidden">
      <div className="flex items-center h-16">
        {/* Column 1 */}
        <div className="flex items-center justify-center w-1/2">
          <img src={SortIcon} alt="Sort" className="w-4 h-4 mr-2" />
          <p className="text-sm font-dm-sans text-[#929292]">Sort By</p>
        </div>

        {/* Vertical Line Separator */}
        <div className="h-full border-l border-[#929292]"></div>

        {/* Column 2 */}
        <div className="flex items-center justify-center w-1/2" onClick={handleMobileFilterClick}>
          <img src={filter} alt="Filter" className="w-6 h-6 mr-2" />
          <p className="text-sm font-dm-sans text-[#929292]">Filter</p>
        </div>
      </div>
    </div>

    {/* Mobile Filter Modal */}
    {isMobileFilterOpen && (
      <div className="fixed inset-0 z-50 bg-white flex flex-col lg:hidden mt-10">
        <div className="flex items-center justify-between p-4 border-b border-[#DADADA]">
          <button onClick={handleCloseMobileFilter} className="text-[#929292]">
            &#10005;
          </button>
        </div>

        <div className="flex flex-grow">
          <div className="w-1/3 bg-white border-r border-b border-t border-[#929292] overflow-hidden">
            <ul className="space-y-2 p-0 mt-5 text-[16px] font-[700] font-dm-sans text-[#000000]">
              {['Mode', 'Duration'].map((filter, index) => (
                <li
                  key={index}
                  onClick={() => setActiveMobileFilter(filter)}
                  className={`p-2 cursor-pointer w-full ${activeMobileFilter === filter ? 'bg-[#F2F2F2] border-t border-[#00000040]' : 'bg-white'}`}
                  style={{ height: '38px' }}
                >
                  {filter}
                </li>
              ))}
            </ul>
          </div>

          <div className="w-2/3 bg-white overflow-hidden p-4 ml-4">
            <ul className="space-y-2 mt-5">
              {getFilterData(activeMobileFilter)?.map((item) => (
                <li key={item.id} className="flex items-center p-2 text-[14px] font-dm-sans font-[700]">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={
                      activeMobileFilter === 'Mode'
                        ? selectedMode.includes(item.id)
                        : selectedDuration.includes(item.id)
                    }
                    onChange={() => handleCheckboxChange(activeMobileFilter, item.id)}
                  />
                  <span>{activeMobileFilter === 'Mode' ? item.mode_title : item.duration_title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Clear and Apply Buttons */}
        <div className="fixed bottom-0 left-0 w-full bg-[#ffffff] border border-[#929292] lg:hidden">
          <div className="flex items-center h-16">
            <div className="flex items-center justify-center w-1/2" onClick={() => handleClearAllFilters(activeMobileFilter)}>
              <p className="text-[14px] font-dm-sans font-[500] text-[#929292]">Clear All</p>
            </div>
            <div className="h-full border-l border-[#929292]"></div>
            <div className="flex items-center justify-center w-1/2" onClick={handleCloseMobileFilter}>
              <p className="text-[14px] font-dm-sans font-[500] text-[#929292]">Apply Filters</p>
            </div>
          </div>
        </div>
      </div>
    )}
      </div>
      <Footer />
    </>
  );
};

export default AllCoursesList;
