import React, { useEffect, useState } from 'react';
import { Parser } from 'htmlparser2';
import { useParams, useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { Base_URL } from '../../../apiConfig';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const CourseDetail = () => {
  const { university_id, id } = useParams();
  const [courseDetail, setCourseDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isWishlist, setIsWishlist] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        if (!university_id || !id) {
          throw new Error("Missing required parameters");
        }

        

        const response = await fetch(`${Base_URL}/courses/university/${university_id}/program/${id}`);
        
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }
        
        const data = await response.json();
        console.log("Fetched course data:", data);
        
        if (!data || data.length === 0) {
          throw new Error("No course data found");
        }

        setCourseDetail(data);
      } catch (err) {
        console.error("Error fetching course details:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [university_id, id]);

  window.scrollTo(0, 0);

  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag(tagname) {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return item;
      }

      const Tag = item.name;

      const isVoidElement = [
        "br",
        "img",
        "hr",
        "input",
        "meta",
        "link",
      ].includes(Tag);

      const props = {
        ...item.attributes,
        key: index,
        className: Tag === "table" 
          ? "min-w-full border-collapse border border-gray-300 my-4"
          : "",
      };

      if (Tag === "tr") {
        props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
      }

      if (Tag === "td" || Tag === "th") {
        props.className = "border border-gray-300 px-4 py-2";
      }

      if (isVoidElement) {
        return React.createElement(Tag, props);
      }

      return React.createElement(
        Tag,
        props,
        item.children && renderContent(item.children)
      );
    });
  };

  const handleWishlistToggle = (courseId) => {
    setIsWishlist(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  const handleViewMore = (courseId) => {
    navigate(`/subcourse/${university_id}/${courseId}`);
  };

  if (loading) return <p>Loading details...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!courseDetail) return <p>No course details available.</p>;

  return (
    <>
      <Header />
      <div className="mx-auto p-6 bg-[#F2FCEB]" style={{ marginTop: "120px" }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {courseDetail.map((course) => (
            <div key={course.university_programs_id} className="bg-white border rounded-lg border-gray-500 p-6 mb-6">
              {/* Course Details Content */}
              <div className="bg-white border font-[500] border-gray-500 p-6 w-full prose max-w-none">
                {course?.course_details || course?.program_details ? (
                  renderContent(parseHtml(course.course_details || course.program_details))
                ) : (
                  <p>No course details available.</p>
                )}
              </div>

              <div className="bg-white p-6 rounded-lg border border-gray-500 my-6 w-full">
                <div className="grid grid-cols-1 md:grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className="bg-white p-4 border-gray-500 rounded-lg shadow-lg relative w-full">
                    <FaHeart
                      className={`absolute top-2 right-2 cursor-pointer text-xl ${isWishlist[course?.course_master_id] ? 'text-red-500' : 'text-gray-400'}`}
                      onClick={() => handleWishlistToggle(course?.course_master_id)}
                    />

                    <h3 className="text-xl font-semibold text-center mb-4">
                      {course?.course_master_name || 'N/A'}
                    </h3>

                    <div className="flex justify-center gap-4 mb-4">
                      <div className="w-1/2 bg-white border-2 border-[#3ACA2E] p-4 text-center">
                        <p className="text-sm font-500 text-gray-600">Fees</p>
                        <p className="text-lg font-semibold text-black-500">
                          {course?.course_fees || 'N/A'}
                        </p>
                      </div>
                      <div className="w-1/2 bg-white border-2 border-[#3ACA2E] p-4 text-center">
                        <p className="text-sm font-500 text-gray-600">Duration</p>
                        <p className="text-lg font-semibold text-black-500">
                          {course?.course_duration || 'N/A'} 
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        className="bg-[#3ACA2E] text-white py-2 px-4 rounded-[20px] inline-block w-full text-center"
                        onClick={() => handleViewMore(course?.id)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetail;
