import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import CareerSearch from "./CareerSearch";
import CareersGrid from "./CareersGrid";
import Footer from '../components/Footer/Footer';
import CareerTop from "./CareerTop";

function CareerPage() {
 

  return (
    <div>
      <Header />

      <div className="pt-[100px] bg-[#F2FCEB]">
        <CareerTop />
        <CareerSearch />
        <CareersGrid />
        <div className="mt-20">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default CareerPage;
