import React, { useState, useEffect } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';

const AllSubCareerForm = () => {
  const [subCareers, setSubCareers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = subCareers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(subCareers.length / entriesPerPage);

  useEffect(() => {
    const fetchSubCareers = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/SubCareerMaster`);
        setSubCareers(response.data.reverse());
      } catch (error) {
        console.error('Error fetching sub careers:', error.response ? error.response.data : error.message);
      }
    };

    fetchSubCareers();
  }, []);

  const handleEditClick = (subCareer) => {
    navigate(`/admin/career/EditSubCareerForm/${subCareer.id}`, { 
      state: {
        id: subCareer.id,
        name: subCareer.name,
        career_master_id: subCareer.career_master_id,
        description: subCareer.description,
        created_by: subCareer.created_by
      }
    });
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${Base_URL}/sub_career_master/${id}`);
      setSubCareers(prevSubCareers => prevSubCareers.filter(subCareer => subCareer.id !== id));
      toast.success('Sub Career deleted successfully.');
    } catch (error) {
      console.error('Error deleting sub career:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete sub career.');
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredEntries = currentEntries.filter((subCareer) =>
    subCareer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Sub Careers" breadcrumb={['Career', 'All Sub Careers']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            All Sub Careers
          </div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/admin/career/subCareerForm', { state: { subTitle: 'All Sub Careers', mainTitle: 'Sub Career' } })}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-between mb-4 pb-2">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {['Sub Career ID', 'Sub Career Name', 'Career', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((subCareer) => (
                  <tr key={subCareer.id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)', verticalAlign: 'top' }}>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      {subCareer.id}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      {subCareer.name}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      {subCareer.career_master_name || 'N/A'}
                    </td>
                    
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      <div className="flex space-x-2">
                        <button onClick={() => handleEditClick(subCareer)} className="p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen">
                          <HiPencil />
                        </button>
                        <button onClick={() => handleDeleteClick(subCareer.id)} className="p-1 rounded bg-red-500 text-white hover:bg-red-700">
                          <HiTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">No sub careers found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllSubCareerForm; 