import React, { useState, useEffect, useMemo } from "react";
import { HiPencil, HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../headerSection/HeaderSection";
import { Base_URL } from "../../../apiConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux"; // For fetching login_id from Redux
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { FaSort } from "react-icons/fa";

const AllProfessors = () => {
  const [professors, setProfessors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // Sorting state
  const navigate = useNavigate();

  // Fetch login_id from Redux or localStorage
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id;

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const totalPages = Math.ceil(professors.length / entriesPerPage);
  const tableCellStyle = {
    color: "rgb(115, 123, 139)",
    fontFamily: "Poppins, sans-serif",
    fontSize: "13.125px",
    fontWeight: 400,
    lineHeight: "19.6px",
    padding: "9.1px 13.125px",
    borderBottomColor: "rgb(230, 230, 230)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
  };

  // Handle sorting column and direction
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <IoArrowUpOutline />;
      }
      if (sortConfig.direction === "descending") {
        return <IoArrowDownOutline />;
      }
    }
    return <FaSort color='lightgrey' />;
  };

  // Sort professors based on the sortConfig
  const sortedProfessors = useMemo(() => {
    let sortableProfessors = [...professors];
    if (sortConfig.key) {
      sortableProfessors.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProfessors;
  }, [professors, sortConfig]);

  const filteredEntries = useMemo(() => {
    return sortedProfessors.filter((professor) =>
      professor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedProfessors, searchTerm]);

  const currentEntries = filteredEntries.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const getStatusText = (status) => {
    return status === 1 ? "Active" : "Inactive";
  };

  useEffect(() => {
    const fetchProfessors = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/faculty/university/${login_id}`
        );
        console.log(response.data);
        setProfessors(response.data);
      } catch (error) {
        console.error(
          "Error fetching professors:",
          error.response ? error.response.data : error.message
        );
      }
    };

    if (login_id) {
      fetchProfessors();
    }
  }, [login_id]);

  const handleEditClick = (professor) => {
    navigate(`/dashboard/professors/edit/${professor.faculty_id}`, {
      state: { professor },
    });
  };

  const handleDeleteClick = async (id) => {
    try {
      const url = `${Base_URL}/faculty/${id}`;
      await axios.delete(url);
      setProfessors((prevProfessors) =>
        prevProfessors.filter((professor) => professor.faculty_id !== id)
      );
      toast.success("Professor deleted successfully.");
    } catch (error) {
      console.error(
        "Error deleting professor:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to delete professor.");
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <HeaderSection
        title='All Professors'
        breadcrumb={["Professors", "All Professors"]}
      />

      <div className='bg-white p-6 rounded-lg shadow mb-4'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
              lineHeight: "26.25px",
              height: "45px",
            }}
          >
            All Professors
          </div>
          <button
            className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
            onClick={() => navigate("/dashboard/professors/add")}
          >
            + Add New
          </button>
        </div>

        <div className='flex items-center justify-between mb-4 pb-2'>
          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className='border border-gray-300 rounded'
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          <div className='flex items-center space-x-4'>
            <span
              className='text-sm text-gray-600'
              style={{
                color: "rgb(130, 130, 130)",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Search:
            </span>
            <input
              type='text'
              className='px-3 py-1 border border-gray-300 rounded'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: "30px",
                width: "200px",
                borderRadius: "5px",
                borderColor: "#d0d0d0",
                padding: "0 10px",
              }}
            />
          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr className='bg-white'>
                {[
                  "name",
                  "email",
                  "years_of_experience",
                  "qualification",
                  "department",
                  "status",
                ].map((key, index) => (
                  <th
                    key={key}
                    className='text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer'
                    onClick={() => handleSort(key)}
                  >
                    <div className='flex items-center space-x-1'>
                      <span>
                        {
                          [
                            "Name",
                            "Email",
                            "Experience",
                            "Qualifications",
                            "Department",
                            "Status",
                          ][index]
                        }
                      </span>
                      {getSortIcon(key)}
                    </div>
                  </th>
                ))}
                <th className='text-left border-b px-4 py-2 text-sm font-semibold text-gray-700'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((professor) => (
                  <tr
                    key={professor.faculty_id}
                    className='hover:bg-white hover:shadow-md border-b'
                    style={{
                      borderTopWidth: "1px",
                      borderTopColor: "rgb(230, 230, 230)",
                      verticalAlign: "top",
                    }}
                  >
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      {professor.name}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      {professor.email}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      {professor.years_of_experience}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      {professor.qualification}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      {professor.department}
                    </td>
                    <td
                      className={`text-left border-t border-b ${
                        professor.status === 1
                          ? "border-green-500"
                          : "border-red-500"
                      }`}
                      style={{ ...tableCellStyle }}
                    >
                      <span
                        className={`inline-block py-1 px-3 rounded-full text-sm ${
                          professor.status === 1
                            ? "bg-green-100 text-green-600 border border-green-500"
                            : "bg-red-100 text-red-600 border border-red-500"
                        }`}
                      >
                        {getStatusText(professor.status)}
                      </span>
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{ ...tableCellStyle }}
                    >
                      <div className='flex space-x-2'>
                        <button
                          onClick={() => handleEditClick(professor)}
                          className='p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen'
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteClick(professor.faculty_id)
                          }
                          className='p-2 rounded bg-red-500 text-white hover:bg-red-700'
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8' className='text-center py-4'>
                    No professors found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllProfessors;
