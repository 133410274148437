import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Login from './Login';
import SignUp from './SIgnUp';
import { clearAuthError } from '../../redux/slice/authSlice';

const Authentication = ({ initialMode, onClose }) => {
  const [isLogin, setIsLogin] = useState(initialMode === 'login');
  const dispatch = useDispatch();

  const toggleMode = () => {
    setIsLogin(!isLogin);
    dispatch(clearAuthError());
  };

  useEffect(() => {
    return () => {
      dispatch(clearAuthError());
    };
  }, [dispatch]);

  return (
    <div className="flex h-full w-full"style={{minHeight:'inherit'}}>
      {isLogin ? (
        <Login toggleMode={toggleMode} onClose={onClose} />
      ) : (
        <SignUp toggleMode={toggleMode} onClose={onClose} />
      )}
    </div>
  );
};

export default Authentication;
