import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/college.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FaBell } from "react-icons/fa";
import { Base_URL } from "../apiConfig";
import ExamData from "../components/Exams/ExamData/ExamData";

const componentMap = {
  1: { name: "Overview" },
  2: { name: "Eligibility" },
  3: { name: "Application Form" },
  4: { name: "Exam Pattern" },
  5: { name: "Syllabus" },
  6: { name: "How To Prepare" },
  7: { name: "Previous Year Papers" },
  8: { name: "Admit Card" },
  9: { name: "Result" },
  10: { name: "Counselling Process" },
};

const Exam = () => {

  const [preview, setPreview] = useState(1);
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mainSectionRef = useRef(null);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0); // Track last scroll position
  const [originalTop, setOriginalTop] = useState(0); // Track original top position
  const subheaderRef = useRef(null);

  
  useEffect(() => {
    // Delay setting the original position until the component fully loads
    setTimeout(() => {
      if (subheaderRef.current) {
        setOriginalTop(subheaderRef.current.getBoundingClientRect().top + window.scrollY);
      }
    }, 100); // Adjust the delay as needed, here it’s 100ms
  
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // Toggle sticky state only when crossing original position threshold
      if (currentScrollY >= originalTop - 90 && !isSticky) {
        setIsSticky(true);
      } else if (currentScrollY < originalTop - 90 && isSticky) {
        setIsSticky(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalTop, isSticky]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set the initial preview based on the navigation state
    if (location.state?.selectedTab) {
      setPreview(location.state.selectedTab);
    }
    fetchExamData();
  }, [id, location.state?.selectedTab]); // Add location.state.selectedTab to dependencies

  const fetchExamData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${Base_URL}/get-with-joins/exam_master_description`,
        {
          joins: [
            {
              joinTable: "exam_master",
              joinCondition:
                "exam_master.id=exam_master_description.exam_master_id",
            },
          ],
          where: [
            // Changed to array format for multiple conditions
            {
              column: "exam_master_id",
              value: id,
              operator: "=", // Explicitly specify the operator
            },
            {
              column: "exam_master.id", // Also check the joined table's ID
              value: id,
              operator: "=",
            },
          ],
        }
      );

      console.log("Response data:", response.data);
      console.log("Requested ID:", id);
      console.log("Response length:", response.data ? response.data.length : 0);

      // Strict check for empty data or non-matching exam
      if (
        !response.data ||
        response.data.length === 0 ||
        !response.data.some((exam) => exam.exam_master_id === parseInt(id))
      ) {
        console.log("No exam found with ID:", id);
        navigate("/");
        return;
      }

      // Filter to only include the matching exam
      const matchingExam = response.data.filter(
        (exam) => exam.exam_master_id === parseInt(id)
      );

      setExamData(matchingExam);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching exam data:", err);
      setError("Failed to load exam data. Please try again later.");
      setLoading(false);
      navigate("/");
    }
  };

  const handlePreview = (view) => {
    setPreview(view);
    mainSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const renderComponent = () => {
    if (examData && examData.length > 0) {
      return <ExamData data={examData[0]} selectedTab={preview} />;
    }
    return null;
  };

  const getVisibleTabs = () => {
    if (!examData || examData.length === 0) return [];

    return Object.entries(componentMap).filter(([id, { name }]) => {
      const data = examData[0]; // Get the first exam data object
      switch (parseInt(id)) {
        case 1:
          return data.overview && data.overview.trim() !== "";
        case 2:
          return data.eligibility && data.eligibility.trim() !== "";
        case 3:
          return data.application_form && data.application_form.trim() !== "";
        case 4:
          return data.exam_pattern && data.exam_pattern.trim() !== "";
        case 5:
          return data.syllabus && data.syllabus.trim() !== "";
        case 6:
          return data.how_to_prepare && data.how_to_prepare.trim() !== "";
        case 7:
          return data.prev_year_paper && data.prev_year_paper.trim() !== "";
        case 8:
          return data.admit_card && data.admit_card.trim() !== "";
        case 9:
          return data.result && data.result.trim() !== "";
        case 10:
          return (
            data.counselling_process && data.counselling_process.trim() !== ""
          );
        default:
          return false;
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const visibleTabs = getVisibleTabs();

  return (
    <>
      <Header />

      {/* Section with Linear Gradient Background */}
      <div className='mx-auto mt-20 flex flex-col'>
        <div
          className='flex flex-col px-10 py-10 '
          style={{
            background: "linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)",
            color: "#000",
          }}
        >
          <div className='flex flex-col md:flex-row justify-between items-center gap-5 px-5'>
            {/* Left Section: Exam Name and Details */}
            <div>
              <p className='text-2xl md:text-3xl font-semibold'>
                {examData.length > 0
                  ? examData[0].exam_master_name
                  : "No Exam Data Available"}
              </p>
              <p className='text-sm text-gray-600'>
                Updated on {new Date().toLocaleDateString()}
              </p>
            </div>

            {/* Right Section: Set Exam Alert Button */}
            <div>
              <button className='bg-cmsgreen text-white py-2 px-4 rounded-md flex items-center gap-2'>
                <FaBell />
                Set Exam Alert
              </button>
            </div>
          </div>
        </div>

        {/* Sub Header */}
        {visibleTabs.length > 0 && (
        
            <div
              id='subheader'
              ref={subheaderRef}
              className={` subheader ${isSticky ? 'fixed' : ''} top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header bg-white shadow-md md:justify-center`}
            >
              {visibleTabs.map(([id, { name }]) => (
                <p
                  key={id}
                  className={`college_sub_header_option ${
                    preview === parseInt(id) ? "college_sub_header_active" : ""
                  }`}
                  onClick={() => handlePreview(parseInt(id))}
                >
                  {name}
                </p>
              ))}
            </div>
      
        )}

        {/* Information Section with Gray Background */}
        <div className='bg-gray-100' ref={mainSectionRef}>
          {renderComponent()}
        </div>
      </div>
      <div
        className='flex flex-col px-10 py-10'
        style={{
          background: "linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)",
          color: "#000",
        }}
      >
        <div className='flex flex-col md:flex-row justify-evenly items-center gap-5 px-5'>
          {/* Left Section: Exam Name and Details */}
          <div>
            <p className='text-2xl md:text-xl font-medium'>
              Planning to choose Electronic Engineer as your Career
            </p>
          </div>

          {/* Right Section: Set Exam Alert Button */}
          <div>
            <button className='bg-cmsgreen text-white py-2 px-4 rounded-md flex items-center gap-2'>
              Get Expert Help
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Exam;
