import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios';
import college from '../../../assets/landing/college.png';
import { Base_URL } from '../../../apiConfig';
import { Img_URL } from '../../../apiConfig';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EnquiryForm from '../../Forms/EnquiryForm';

const Colleges = () => {
    const [colleges, setColleges] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const response = await fetch(`${Base_URL}/universities/popular/colleges`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    const processedData = data.map(item => ({
                        ...item,
                        pictures: JSON.parse(item.pictures),
                    }));
                    setColleges(processedData);
                } else {
                    setColleges([]);
                }
            } catch (error) {
                console.error("Error fetching universities:", error);
            }
        };

        fetchUniversities();
    }, []);

    const handleExploreClick = (college) => {
        const universityNameSlug = encodeURIComponent(college.university_name.trim().toLowerCase().replace(/\s+/g, '-'));
        navigate(`/college/${college.university_id}/${universityNameSlug}`, { state: { college } });
    };

    const openModal = (collegeName) => {
        setSelectedCollege(collegeName);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <div className="dot"></div>
                </a>
            );
        },
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2, centerMode: false, centerPadding: '0' } },
            { breakpoint: 768, settings: { slidesToShow: 1, centerMode: true, centerPadding: '15%' } },
        ],
        appendDots: dots => (
            <div>
                <ul className="custom-dots flex justify-center items-center space-x-2 mt-5">
                </ul>
            </div>
        ),
    };

    return (
        <div className="overflow-hidden">
            <div className="bg-[#002147] p-5 text-center">
                <p className="text-white font-medium text-base mb-8">POPULAR UNIVERSITIES</p>
                <p className="text-white font-semibold text-lg mb-24">List of Popular Universities to KICK-START your JOURNEY</p>
            </div>

            <div className="relative -translate-y-32 mt-12">
                <Slider {...settings} >
                    {colleges.length > 0 ? colleges.map((collegeItem, index) => (
                        <div className="flex justify-center items-center px-4 py-2" key={index}>
                            <div className="relative w-full h-[250px] rounded-[20px] overflow-hidden bg-gray-200">
                                <img 
                                    src={`${Img_URL}${collegeItem.pictures[0]}`} 
                                    alt={collegeItem.name} 
                                    onClick={() => handleExploreClick(collegeItem)} 
                                    className="w-full h-full object-cover cursor-pointer" 
                                    onError={(e) => { e.target.src = college; }}
                                />
                                <div className="absolute bottom-0 left-0 w-full p-3 bg-[rgb(0,33,71)] bg-opacity-75">
                                    <p className="bg-white rounded-[20px] px-3 py-1 mb-3 font-medium text-center cursor-pointer"
                                       onClick={() => handleExploreClick(collegeItem)}>
                                        {collegeItem.university_name}
                                    </p>
                                    <button
                                        className="bg-[#3ACA2E] text-white rounded-[20px] px-3 py-1 w-full"
                                        onClick={() => openModal(collegeItem.university_name)}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <div className="text-center p-5 text-gray-500">No popular universities available at the moment.</div>
                    )}
                </Slider>
                <p className="text-[#57CC02] font-dm-sans sm:text-[12px] md:text-[10px] lg:text-[16px] font-[500] leading-[20.83px] cursor-pointer text-center flex justify-center mt-4" 
                 onClick={() => navigate('/colleges')}>
                    View More
                </p>
            </div>

            {error && <div className=" fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-md">{error}</div>}

            {isModalOpen && (
                <EnquiryForm
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    selectedCollege={selectedCollege}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default Colleges;
