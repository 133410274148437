import React from 'react';

const CareerEligibility = ({ data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">Eligibility</h2>
      <p>{data?.eligibility || "No data available."}</p>
    </div>
  );
};

export default CareerEligibility;
