import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../apiConfig';

const EnquiryForm = ({ isOpen, onClose, selectedCollege }) => {
  const modalRef = useRef();
  const [programOptions, setProgramOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_no: '',
    program: '',
  });

  // Get user data and token from Redux store
  const userData = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);

  // Fetch programs when component mounts
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/program_master`);
        setProgramOptions(response.data);
      } catch (error) {
        console.error("Error fetching programs:", error);
        toast.error("Failed to load programs");
      }
    };

    fetchPrograms();
  }, []);

  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  // Pre-fill only name if user is logged in
  useEffect(() => {
    if (userData?.registration_id) {
      setFormData(prevData => ({
        ...prevData,
        name: userData.name || '',
      }));
    }
  }, [userData]);

  const resetForm = () => {
    setFormData({
      name: userData?.registration_id ? userData.name : '',
      email: '',
      contact_no: '',
      program: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(
        `${Base_URL}/create/enquiry_form`, 
        {
          ...formData,
          
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      toast.success('Enquiry submitted successfully!');
      resetForm();
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      toast.error(error.response?.data?.message || 'Failed to submit enquiry');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Enquiry Form</h2>
          {/* <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button> */}
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              required
              disabled={userData?.registration_id}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Number</label>
            <input
              type="tel"
              name="contact_no"
              value={formData.contact_no}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Program</label>
            <select
              name="program"
              value={formData.program}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              required
            >
              <option value="">Select Program</option>
              {programOptions.map((program) => (
                <option key={program.id} value={program.program_master_name}>
                  {program.program_master_name}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EnquiryForm; 