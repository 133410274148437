import React, { useState, useEffect } from "react";
import CareerTab from "./CareerTab";
import { useNavigate } from "react-router-dom"; // We need useNavigate to programmatically navigate
import { Base_URL } from '../apiConfig';
const CareersGrid = () => {
  const [streams, setStreams] = useState([]); // State to store stream data
  const [selectedStream, setSelectedStream] = useState(null); // State for selected stream
  const navigate = useNavigate(); // Hook for navigation

  // Fetch streams data from API on component mount
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await fetch(`${Base_URL}/get/streams`);
        const data = await response.json();
        setStreams(data); // Set the stream data in state
      } catch (error) {
        console.error("Error fetching streams:", error);
      }
    };

    fetchStreams();
  }, []);

  // Function to handle stream selection and navigate to the new tab
  const handleStreamSelect = (stream) => {
    setSelectedStream(stream);
    // Navigate to the CareerTab component with stream id passed as a parameter
    navigate(`/career/tab/${stream.id}/${encodeURIComponent(stream.stream_name)}`);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-[150px] bg-[#F2FCEB]">
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {streams.map((stream) => (
          <div
            key={stream.id}
            className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
          >
            <div className="flex-grow" />
            <div className="absolute bottom-0 w-full">
              <button
                onClick={() => handleStreamSelect(stream)} // Select stream and navigate
                className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
              >
                {stream.stream_name} {/* Display stream name dynamically */}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default CareersGrid;
// import React, { useState, useEffect } from "react";
// import CareerTab from "./CareerTab";
// import { useNavigate } from "react-router-dom"; // We need useNavigate to programmatically navigate
// import { Base_URL } from '../apiConfig';
// const CareersGrid = () => {
//   const [career, setCareer] = useState([]); // State to store stream data
//   const [selectedCareer, setSelectedCareer] = useState(null); // State for selected stream
//   const navigate = useNavigate(); // Hook for navigation

//   // Fetch streams data from API on component mount
//   useEffect(() => {
//     const fetchCareer = async () => {
//       try {
//         const response = await fetch(`${Base_URL}/get/career_master`);
//         const data = await response.json();
//         setCareer(data); // Set the stream data in state
//       } catch (error) {
//         console.error("Error fetching streams:", error);
//       }
//     };

//     fetchCareer();
//   }, []);

//   // Function to handle stream selection and navigate to the new tab
//   const handleCareerSelect = (career) => {
//     setSelectedCareer(career);
//     // Navigate to the CareerTab component with stream id passed as a parameter
//     navigate(`/career/tab/${career.id}/${encodeURIComponent(career.name)}`);
//   };

//   return (
//     <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-[150px] bg-[#F2FCEB]">
//       <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
//         {career.map((career) => (
//           <div
//             key={career.id}
//             className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
//           >
//             <div className="flex-grow" />
//             <div className="absolute bottom-0 w-full">
//               <button
//                 onClick={() => handleCareerSelect(career)} // Select stream and navigate
//                 className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
//               >
//                 {career.name} {/* Display stream name dynamically */}
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
  
// };

// export default CareersGrid;
