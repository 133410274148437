import React, { useState, useEffect } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import LPU1 from "../../../assets/landing/LPU1.png";
import locationIcon from "../../../assets/landing/locationIcon.png";
import RankIcon from "../../../assets/landing/RankIcon.png";
import ratingIcon from "../../../assets/landing/ratingIcon.png";
import univIcon from "../../../assets/landing/univIcon.png";
import Hat from "../../../assets/landing/Hat.png";
import filter from "../../../assets/landing/filter.png";
import SortIcon from "../../../assets/landing/SortIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import axios from "axios";
import { Img_URL } from "../../../apiConfig";
import { useLocation } from "react-router-dom";
import filterimg from "../../../assets/landing/filter.png"; // Replace with actual filter icon path
import "./LandingUniversity.css";
import nodata from "../../../assets/landing/no_data.png";

function LandingUniversity() {
  const { type, id, name, degree, filters, locationParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);

  // State declarations
  const [wishlist, setWishlist] = useState(new Set());
  const [allColleges, setAllColleges] = useState([]);
  const [collegesByProgram, setCollegesByProgram] = useState([]);
  const [collegesByLocation, setCollegesByLocation] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [subCoursesList, SetSubCoursesList] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [activeMobileFilter, setActiveMobileFilter] = useState("Degree");
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeFilterName, setActiveFilterName] = useState("");
  const [degreeSearchQuery, setDegreeSearchQuery] = useState("");
  const [specializationSearchQuery, setSpecializationSearchQuery] =
    useState("");
  const [stateSearchQuery, setStateSearchQuery] = useState("");
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_no: "",
    discipline_interested_in: "",
    program: "",
  });

  const isAllFiltersEmpty =
    selectedDegree.length === 0 &&
    selectedSpecialization.length === 0 &&
    selectedState.length === 0 &&
    selectedCity.length === 0;

  useEffect(() => {
    if (programList.length > 0 && cityList.length > 0 && stateList.length > 0) {
      if (degree) {
        const program = programList.find(
          (p) =>
            p.program_master_name.toLowerCase().replace(/\s+/g, "-") === degree
        );
        if (program) {
          setSelectedDegree([program.id]);
        }
      }

      if (locationParam) {
        const city = cityList.find(
          (c) => c.name.toLowerCase().replace(/\s+/g, "-") === locationParam
        );
        if (city) {
          setSelectedCity([city.id]);
        } else {
          const state = stateList.find(
            (s) => s.name.toLowerCase().replace(/\s+/g, "_") === locationParam
          );
          if (state) {
            setSelectedState([state.id]);
          }
        }
      }
    }
  }, [degree, locationParam, programList, cityList, stateList]);

  // Update URL when filters change
  useEffect(() => {
    if (!isAllFiltersEmpty) {
      let urlParts = [];
      let locationPart = "";

      // Handle location filters
      if (selectedCity.length > 0) {
        const city = cityList.find((c) => c.id === selectedCity[0]);
        if (city) {
          locationPart = city.name.toLowerCase().replace(/\s+/g, "-");
        }
      } else if (selectedState.length > 0) {
        const state = stateList.find((s) => s.id === selectedState[0]);
        if (state) {
          locationPart = state.name.toLowerCase().replace(/\s+/g, "_");
        }
      }

      // Handle program and specialization filters
      if (selectedDegree.length > 0) {
        const programs = selectedDegree
          .map((id) => {
            const program = programList.find((p) => p.id === id);
            return program
              ? program.program_master_name.toLowerCase().replace(/\s+/g, "-")
              : "";
          })
          .filter(Boolean);
        urlParts.push(...programs);
      }

      if (selectedSpecialization.length > 0) {
        const specs = selectedSpecialization
          .map((id) => {
            const spec = subCoursesList.find((s) => s.id === id);
            return spec
              ? spec.sub_course_name.toLowerCase().replace(/\s+/g, "-")
              : "";
          })
          .filter(Boolean);
        urlParts.push(...specs);
      }

      // Generate URL based on filters
      if (urlParts.length === 0 && locationPart) {
        // Only location selected
        navigate(`/colleges-in-${locationPart}`, { replace: true });
      } else if (urlParts.length > 0) {
        const uniqueUrlParts = [...new Set(urlParts)];
        const hasMultipleFilters =
          selectedDegree.length > 1 ||
          selectedSpecialization.length > 1 ||
          selectedCity.length > 1 ||
          selectedState.length > 1;

        if (hasMultipleFilters) {
          // Multiple filters
          if (locationPart) {
            navigate(
              `/filter/${uniqueUrlParts.join("-")}-colleges-in-${locationPart}`,
              { replace: true }
            );
          } else {
            navigate(`/filter/${uniqueUrlParts.join("-")}-colleges-in-india`, {
              replace: true,
            });
          }
        } else {
          // Single filters
          if (locationPart) {
            navigate(
              `/${uniqueUrlParts.join("-")}-colleges-in-${locationPart}`,
              { replace: true }
            );
          } else {
            navigate(`/${uniqueUrlParts.join("-")}-colleges-in-india`, {
              replace: true,
            });
          }
        }
      }
    } else {
      // No filters selected
      navigate("/colleges", { replace: true });
    }
  }, [selectedDegree, selectedSpecialization, selectedCity, selectedState]);

  useEffect(() => {
    // Check if the current route is a filter route
    const isFilterRoute = window.location.pathname.includes("/colleges/filter");

    // If all filters are empty and we're on a filter route, navigate back to /colleges
    if (isAllFiltersEmpty && isFilterRoute) {
      navigate(`/colleges`);
      setFilteredData(null);
      setActiveFilterName("");
      return;
    }

    // Only proceed with fetching and updating if filters are applied
    if (!isAllFiltersEmpty) {
      // Create SEO-friendly URL segments
      let urlParts = [];

      if (selectedDegree.length > 0) {
        const programs = selectedDegree
          .map((degreeId) => {
            const program = programList.find((p) => p.id === degreeId);
            return program
              ? program.program_master_name.toLowerCase().replace(/\s+/g, "-")
              : "";
          })
          .filter(Boolean);

        if (programs.length > 0) {
          urlParts.push(`programs-${programs.join("-")}`);
        }
      }

      if (selectedSpecialization.length > 0) {
        const specializations = selectedSpecialization
          .map((specId) => {
            const spec = subCoursesList.find((s) => s.id === specId);
            return spec
              ? spec.sub_course_name.toLowerCase().replace(/\s+/g, "-")
              : "";
          })
          .filter(Boolean);

        if (specializations.length > 0) {
          urlParts.push(`specializations-${specializations.join("-")}`);
        }
      }

      if (selectedState.length > 0) {
        const states = selectedState
          .map((stateId) => {
            const state = stateList.find((s) => s.id === stateId);
            return state ? state.name.toLowerCase().replace(/\s+/g, "-") : "";
          })
          .filter(Boolean);

        if (states.length > 0) {
          urlParts.push(`states-${states.join("-")}`);
        }
      }

      if (selectedCity.length > 0) {
        const cities = selectedCity
          .map((cityId) => {
            const city = cityList.find((c) => c.id === cityId);
            return city ? city.name.toLowerCase().replace(/\s+/g, "-") : "";
          })
          .filter(Boolean);

        if (cities.length > 0) {
          urlParts.push(`cities-${cities.join("-")}`);
        }
      }

      // Make API call with filters
      const fetchCollegesData = async () => {
        const filters = [
          { city_id: selectedCity.length > 0 ? selectedCity : [] },
          {
            sub_course_master_id:
              selectedSpecialization.length > 0 ? selectedSpecialization : [],
          },
          {
            program_master_id: selectedDegree.length > 0 ? selectedDegree : [],
          },
          { state_id: selectedState.length > 0 ? selectedState : [] },
        ];

        const filtersString = JSON.stringify(filters);
        const apiUrl = `${Base_URL}/universities/filter?filters=${filtersString}`;

        try {
          const response = await fetch(apiUrl);
          if (!response.ok) throw new Error("Network response was not ok");

          const data = await response.json();
          if (Array.isArray(data)) {
            const processedData = data.map((item) => ({
              ...item,
              pictures: JSON.parse(item.pictures),
            }));
            setFilteredData(processedData.length > 0 ? processedData : null);
          }
        } catch (error) {
          console.error("Error fetching colleges data:", error);
        }
      };

      fetchCollegesData();
    }
  }, [
    selectedDegree,
    selectedSpecialization,
    selectedState,
    selectedCity,
    programList,
    navigate,
  ]);

  useEffect(() => {
    // Fetch degrees
    const fetchDegrees = async () => {
      const response = await axios.get(`${Base_URL}/get/program_master`);
      setProgramList(response.data);
      setProgramOptions(response.data);
      console.log("degree", response.data);
    };

    // Fetch specializations
    const fetchSpecializations = async () => {
      const response = await axios.get(`${Base_URL}/get/sub_courses_master`);
      SetSubCoursesList(response.data);
      console.log("subcourse", response.data);
    };

    // Fetch cities
    const fetchCities = async () => {
      const response = await axios.get(`${Base_URL}/get/city`);
      setCityList(response.data);
      console.log("city", response.data);
    };

    // Fetch states
    const fetchStates = async () => {
      const response = await axios.get(`${Base_URL}/get/state`);
      setStateList(response.data);
      console.log("state", response.data);
    };

    fetchDegrees();
    fetchSpecializations();
    fetchCities();
    fetchStates();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAllUniversities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/get/universities`);

        console.log("All universities data", response.data);

        if (Array.isArray(response.data)) {
          const processedData = response.data.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse pictures string as JSON
          }));
          setAllColleges(processedData);
        } else {
          console.error("Unexpected data format:", response.data);
          setAllColleges([]); // Set fallback if not an array
        }
      } catch (err) {
        console.error("Failed to fetch all universities:", err);
      } finally {
        setLoading(false);
      }
    };
    const fetchCollegesByProgram = async () => {
      setLoading(true);
      console.log("id", id);

      try {
        // const response = await axios.get(`${Base_URL}/programs`);
        // const filteredColleges = response.data.filter(
        //   (college) => college.program_master_id == parseInt(id, 10)
        // );
        // console.log("program colleges",response.data)
        // setCollegesByProgram(filteredColleges);
        // console.log("Filtered colleges", collegesByProgram);
        const response = await axios.post(
          `${Base_URL}/get-with-joins/university_programs`,
          {
            joins: [
              {
                joinTable: "universities",
                joinCondition:
                  "universities.university_id=university_programs.university_id",
              },
            ],
          }
        );
        const filteredColleges = response.data.filter(
          (college) => college.program_master_id == parseInt(id, 10)
        );
        console.log("program colleges", response.data);
        setCollegesByProgram(filteredColleges);
        console.log("Filtered colleges", filteredColleges);

        if (Array.isArray(filteredColleges)) {
          const processedData = filteredColleges.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse the pictures string as JSON
          }));
          setCollegesByProgram(processedData);
        } else {
          console.error("Unexpected data format:");
          setCollegesByProgram([]); // Set fallback if not an array
        }
      } catch (err) {
        console.error("Failed to fetch");
      } finally {
        setLoading(false);
      }
    };
    // fetchCollegesByProgram();

    const fetchCollegesByLocation = async () => {
      setLoading(true);
      console.log("id", id);

      try {
        const response = await axios.post(
          `${Base_URL}/get-with-joins/city_mapping`,
          {
            joins: [
              {
                joinTable: "universities",
                joinCondition:
                  "universities.university_id=city_mapping.university_id",
              },
            ],
          }
        );
        const filteredCities = response.data.filter(
          (city) => city.city_id == parseInt(id, 10)
        );
        console.log("city colleges", response.data);
        setCollegesByLocation(filteredCities);
        console.log("Filtered cities", filteredCities);

        if (Array.isArray(filteredCities)) {
          const processedData = filteredCities.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse the pictures string as JSON
          }));
          setCollegesByLocation(processedData);
        } else {
          console.error("Unexpected data format:");
          setCollegesByLocation([]); // Set fallback if not an array
        }
      } catch (err) {
        console.log("Failed to fetch");
      } finally {
        setLoading(false);
      }
    };
    if(!hasFilteredData){
    fetchAllUniversities()
    }
    // fetchCollegesByLocation();
  
  }, []);

  useEffect(() => {
    // Check if we have a program ID passed through navigation state
    if (location.state?.selectedProgram) {
      console.log(location.state?.selectedProgram);
      setSelectedDegree([location.state.selectedProgram]);
      setActiveFilterName(location.state.programName);

      // Create SEO-friendly program name for URL
      const programSlug = location.state.programName
        .toLowerCase()
        .replace(/\s+/g, "-");
      navigate(`/${programSlug}-colleges-in-india`, {
        replace: true,
      });
    }
  }, [location.state]); // Run when location.state changes

  // Add this useEffect to handle program parameter
  useEffect(() => {
    const fetchInitialData = async () => {
      const path = location.pathname;

      // Handle direct program URL (e.g., /btech-colleges-in-india)
      if (path.endsWith("-colleges-in-india") && !path.startsWith("/filter/")) {
        const programName = path
          .replace(/-colleges-in-india$/, "")
          .replace("/", "");
        const program = programList.find(
          (p) =>
            p.program_master_name.toLowerCase().replace(/\s+/g, "-") ===
            programName
        );

        if (program) {
          setSelectedDegree([program.id]);
          setActiveFilterName(program.program_master_name);
        }
      }

      // Handle filter URL (e.g., /filter/btech-mtech-colleges-in-india)
      else if (path.startsWith("/filter/")) {
        const filterString = path
          .replace("/filter/", "")
          .replace("-colleges-in-india", "");

        const filters = filterString.split("-");

        filters.forEach((filter) => {
          const program = programList.find(
            (p) =>
              p.program_master_name.toLowerCase().replace(/\s+/g, "-") ===
              filter
          );
          if (program) {
            setSelectedDegree((prev) => [...prev, program.id]);
            if (!activeFilterName) {
              setActiveFilterName(program.program_master_name);
            }
          }
        });
      }
    };

    if (programList.length > 0) {
      fetchInitialData();
    }
  }, [programList, location.pathname]);

  const handleCheckboxChange = (filterType, id) => {
    console.log("Checkbox Changed:", { filterType, id });

    switch (filterType) {
      case "Degree":
        setSelectedDegree((prev) => {
          const newSelection = prev.includes(id)
            ? prev.filter((item) => item !== id) // Remove if already selected
            : [...prev, id]; // Add to existing selection
          console.log("New Degree Selection:", newSelection);

          // Update active filter name
          if (newSelection.length === 0) {
            setActiveFilterName("");
          } else {
            const program = programList.find((p) => p.id === id);
            if (program) {
              setActiveFilterName(program.program_master_name);
            }
          }

          return newSelection;
        });
        break;
      case "Specialization":
        setSelectedSpecialization((prev) => {
          const newSelection = prev.includes(id)
            ? prev.filter((item) => item !== id)
            : [...prev, id];
          console.log("New Specialization Selection:", newSelection);
          return newSelection;
        });
        break;
      case "State":
        setSelectedState((prev) => {
          const newSelection = prev.includes(id)
            ? prev.filter((item) => item !== id)
            : [...prev, id];
          console.log("New State Selection:", newSelection);
          return newSelection;
        });
        break;
      case "City":
        setSelectedCity((prev) => {
          const newSelection = prev.includes(id)
            ? [] // Clear the selection when unchecking
            : [id];

          // If clearing city selection, navigate to /colleges
          if (prev.includes(id)) {
            navigate("/colleges", {
              replace: true,
              state: {},
            });
          }

          return newSelection;
        });
        break;
    }
  };

  const updateRoute = () => {
    if (selectedDegree.length > 0) {
      const programs = selectedDegree
        .map((degreeId) => {
          const program = programList.find((p) => p.id === degreeId);
          return program
            ? program.program_master_name.toLowerCase().replace(/\s+/g, "-")
            : "";
        })
        .filter(Boolean);

      if (programs.length === 1) {
        navigate(`/${programs[0]}-colleges-in-india`);
      } else if (programs.length > 1) {
        navigate(`/filter/${programs.join("-")}-colleges-in-india`);
      }
    }
  };

  const handleClearAllFilters = (filter) => {
    switch (filter) {
      case "Degree":
        setSelectedDegree([]);
        break;
      case "Specialization":
        setSelectedSpecialization([]);
        break;
      case "State":
        setSelectedState([]);
        break;
      case "City":
        setSelectedCity([]);
        break;
      default:
        break;
    }
  };

  // Sample array of universities
  const universities = [
    {
      id: 1,
      name: "LPU",
      description: "Lovely Professional University",
      image: LPU1,
    },
    {
      id: 2,
      name: "CU",
      description: "Chandigarh University, Punjab",
      image: LPU1,
    },
    {
      id: 3,
      name: "MIET",
      description: "Meerut Institute of Engineering and Technology",
      image: LPU1,
    },
    {
      id: 4,
      name: "LPU",
      description: "Lovely Professional University",
      image: LPU1,
    },
    { id: 5, name: "CU", description: "Chandigarh University", image: LPU1 },
    {
      id: 6,
      name: "MIET",
      description: "Meerut Institute of Engineering and Technology",
      image: LPU1,
    },
  ];

  // Dummy data for university info
  const infoRows = [
    { id: 1, img: RankIcon, text: "NIRF Rank #46" },
    { id: 2, img: univIcon, text: "Private University" },
    { id: 3, img: ratingIcon, text: "View Reviews" },
    { id: 4, img: locationIcon, text: "Punjab, India." },
  ];

  const handleWishlistClick = (id) => {
    const newWishlist = new Set(wishlist);
    if (newWishlist.has(id)) {
      newWishlist.delete(id);
    } else {
      newWishlist.add(id);
    }
    setWishlist(newWishlist);
  };

  const handleFilterClick = () => {
    navigate("/filter");
  };

  const getFilterData = (filterType) => {
    switch (filterType) {
      case "Degree":
        return programList.filter((item) =>
          item.program_master_name
            .toLowerCase()
            .includes(degreeSearchQuery.toLowerCase())
        );
      case "Specialization":
        return subCoursesList.filter((item) =>
          item.sub_course_name
            .toLowerCase()
            .includes(specializationSearchQuery.toLowerCase())
        );
      case "State":
        return stateList.filter((item) =>
          item.name.toLowerCase().includes(stateSearchQuery.toLowerCase())
        );
      case "City":
        return cityList.filter((item) =>
          item.name.toLowerCase().includes(citySearchQuery.toLowerCase())
        );
      default:
        return [];
    }
  };

  const mobileFilters = [
    "Filter 1",
    "Filter 2",
    "Filter 3",
    "Filter 4",
    "Filter 5",
    "Filter 6",
  ];

  const handleMobileFilterClick = () => {
    setIsMobileFilterOpen(true);
  };

  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const handleExploreClick = (college) => {
    const universityNameSlug = encodeURIComponent(
      college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/college/${college.university_id}/${universityNameSlug}`, {
      state: { college },
    });
  };
  // const displayData = filteredData || (type === 'program' && collegesByProgram.length > 0)
  // ? collegesByProgram
  // : (type === 'city' && collegesByLocation.length > 0)
  //   ? collegesByLocation
  //   : allColleges; // Fallback to all colleges if no filters applied

  // const displayData = filteredData || (collegesByProgram?.length ? collegesByProgram : collegesByLocation);
  // Your existing state definitions and useEffect for fetching data...

  // Define flags for checking if data is available
  const hasFilteredData = filteredData?.length > 0;
  const hasCollegesByProgram = collegesByProgram?.length > 0;
  const hasCollegesByLocation = collegesByLocation?.length > 0;

  // Determine if the URL is for landing universities
  const isLandingUniversity =
    window.location.pathname === "/colleges" 


  // Determine what data to display
  let displayData;

  if (isLandingUniversity) {
    // Show all colleges if on the landing university page
    displayData = allColleges;
  } else if (hasFilteredData) {
    displayData = filteredData;
  }  else {
    displayData = []; // Set to an empty array xto handle "No colleges found" case
  }

  // Add this function to clear all filters
  const clearAllFilters = () => {
    setSelectedDegree([]);
    setSelectedSpecialization([]);
    setSelectedState([]);
    setSelectedCity([]);
    setFilteredData(null);
    setActiveFilterName("");
    navigate("/colleges");
  };

  useEffect(() => {
    const path = location.pathname;

    // Add debug logging
    console.log("Current path:", path);
    console.log("Current cityList:", cityList);

    // Handle city URL (e.g., /colleges-in-mumbai)
    if (path.startsWith("/colleges-in-") && !path.includes("india")) {
      const cityName = path.replace("/colleges-in-", "").replace("/", "");
      console.log("Extracted city name:", cityName);

      // Make sure cityList exists and has items before searching
      if (cityList && cityList.length > 0) {
        const city = cityList.find((c) => {
          // Add null check for city.name
          if (!c || !c.name) {
            console.log("Invalid city object:", c);
            return false;
          }
          return c.name.toLowerCase().replace(/\s+/g, "-") === cityName;
        });

        console.log("Found city:", city);

        if (city) {
          setSelectedCity([city.id]);
        }
      } else {
        console.log("CityList is empty or undefined");
      }
    }

    // Handle state URL (e.g., /colleges-in-maharashtra)
    else if (path.startsWith("/colleges-in-")) {
      const stateName = path
        .replace("/colleges-in-", "")
        .replace("/", "")
        .replace(/_/g, " ");

      // Make sure stateList exists and has items before searching
      if (stateList && stateList.length > 0) {
        const state = stateList.find((s) => {
          // Add null check for state.name
          if (!s || !s.name) {
            console.log("Invalid state object:", s);
            return false;
          }
          return s.name.toLowerCase() === stateName.toLowerCase();
        });

        console.log("Found state:", state);

        if (state) {
          setSelectedState([state.id]);
        }
      } else {
        console.log("StateList is empty or undefined");
      }
    }
  }, [location.pathname, cityList, stateList]);

  useEffect(() => {
    if (!isAllFiltersEmpty) {
      // For single degree selection
      if (
        selectedDegree.length === 1 &&
        !selectedSpecialization.length &&
        !selectedCity.length &&
        !selectedState.length
      ) {
        const program = programList.find((p) => p.id === selectedDegree[0]);
        if (program) {
          const programSlug = program.program_master_name
            .toLowerCase()
            .replace(/\s+/g, "-");
          navigate(`/${programSlug}-colleges-in-india`, { replace: true });
        }
      }

      // For single city selection
      else if (
        selectedCity.length === 1 &&
        !selectedDegree.length &&
        !selectedSpecialization.length &&
        !selectedState.length
      ) {
        const city = cityList.find((c) => c.id === selectedCity[0]);
        if (city) {
          const citySlug = city.name.toLowerCase().replace(/\s+/g, "-");
          navigate(`/colleges-in-${citySlug}`, { replace: true });
        }
      }

      // For single state selection
      else if (
        selectedState.length === 1 &&
        !selectedDegree.length &&
        !selectedSpecialization.length &&
        !selectedCity.length
      ) {
        const state = stateList.find((s) => s.id === selectedState[0]);
        if (state) {
          const stateSlug = state.name.toLowerCase().replace(/\s+/g, "_");
          navigate(`/colleges-in-${stateSlug}`, { replace: true });
        }
      }

      // For multiple filters
      else {
        const urlParts = [];
        let locationPart = "";

        // Add all selected programs
        if (selectedDegree.length > 0) {
          const programs = selectedDegree
            .map((id) => {
              const program = programList.find((p) => p.id === id);
              return program
                ? program.program_master_name.toLowerCase().replace(/\s+/g, "-")
                : "";
            })
            .filter(Boolean);
          urlParts.push(...programs);
        }

        // Add all selected specializations
        if (selectedSpecialization.length > 0) {
          const specs = selectedSpecialization
            .map((id) => {
              const spec = subCoursesList.find((s) => s.id === id);
              return spec
                ? spec.sub_course_name.toLowerCase().replace(/\s+/g, "-")
                : "";
            })
            .filter(Boolean);
          urlParts.push(...specs);
        }

        // Add location (prefer city over state)
        if (selectedCity.length > 0) {
          const city = cityList.find((c) => c.id === selectedCity[0]);
          if (city) {
            locationPart = city.name.toLowerCase().replace(/\s+/g, "-");
          }
        } else if (selectedState.length > 0) {
          const state = stateList.find((s) => s.id === selectedState[0]);
          if (state) {
            locationPart = state.name.toLowerCase().replace(/\s+/g, "_");
          }
        }

        // Generate URL with unique values
        if (urlParts.length > 0) {
          const uniqueUrlParts = [...new Set(urlParts)];
          if (locationPart) {
            navigate(
              `/filter/${uniqueUrlParts.join("-")}-colleges-in-${locationPart}`,
              { replace: true }
            );
          } else {
            navigate(`/filter/${uniqueUrlParts.join("-")}-colleges-in-india`, {
              replace: true,
            });
          }
        }
      }
    } else {
      navigate("/colleges", { replace: true });
    }
  }, [selectedDegree, selectedSpecialization, selectedCity, selectedState]);

  // Update the useEffect that handles filter changes
  useEffect(() => {
    const fetchFilteredData = async () => {
      if (!isAllFiltersEmpty) {
        const filters = [
          { city_id: selectedCity },
          { sub_course_master_id: selectedSpecialization },
          { program_master_id: selectedDegree },
          { state_id: selectedState },
        ];

        try {
          const response = await fetch(
            `${Base_URL}/universities/filter?filters=${JSON.stringify(filters)}`
          );
          if (!response.ok) throw new Error("Network response was not ok");

          const data = await response.json();
          console.log("filter-->",data)
          if (Array.isArray(data)) {
            const processedData = data.map((item) => ({
              ...item,
              pictures: JSON.parse(item.pictures),
            }));
            console.log("process",processedData.length)
            setFilteredData(processedData.length > 0 ? processedData : null);
          }
        } catch (error) {
          console.error("Error fetching filtered data:", error);
          setFilteredData(null);
        }
      } else {
        setFilteredData(null);
      }
    };

    fetchFilteredData();
  }, [selectedDegree, selectedSpecialization, selectedCity, selectedState]);

  // Update the useEffect that handles initial load
  useEffect(() => {
    // Handle navigation from header
    if (location.state?.fromHeader && location.state.fromProgram) {
      const { selectedProgram, programName } = location.state;
      console.log("program-->", selectedProgram);
      if (selectedProgram) {
        setSelectedDegree([selectedProgram]);
        setActiveFilterName(programName);

        // Update URL without triggering another navigation
        const programSlug = programName.toLowerCase().replace(/\s+/g, "-");
        window.history.replaceState(
          null,
          "",
          `/${programSlug}-colleges-in-india`
        );
      }

      // Clear the state to prevent infinite loops
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Also update the initial load useEffect to handle direct URL access
//   useEffect(() => {
//     const path = location.pathname;

//     // Handle direct program URL (e.g., /btech-colleges-in-india)
//     if (path.endsWith("-colleges-in-india")) {
//       const programName = path
//         .replace("-colleges-in-india", "")
//         .replace("/", "");

//       const program = programList.find(
//         (p) =>
//           p.program_master_name.toLowerCase().replace(/\s+/g, "-") ===
//           programName
//       );
// console.log('programing',program)
//       if (program) {
//         setSelectedDegree([program.id]);
//         setActiveFilterName(program.program_master_name);
//       }
//     }
//   }, [location.pathname, programList]);



  // Add this useEffect to handle header navigation
  useEffect(() => {
    // Check if we're coming from the header with a selection
    if (location.state?.fromHeader && location.state?.shouldCheckFilter) {
      if (location.state.selectedProgram) {
        // Handle program selection
        console.log("selected progarm-->",location.state.selectedProgram)
        setSelectedDegree([location.state.selectedProgram]);

        // Create SEO-friendly program name for URL
        const programSlug = location.state.programName
          .toLowerCase()
          .replace(/\s+/g, "-");
        navigate(`/${programSlug}-colleges-in-india`, {
          replace: true,
          state: {}, // Clear the state to prevent infinite loops
        });
      } else if (location.state.selectedCity) {
        // Handle city selection
        console.log("city-->",location.state.selectedCity)
        setSelectedCity([location.state.selectedCity]);

        // Create SEO-friendly city name for URL
        const citySlug = location.state.cityName
          .toLowerCase()
          .replace(/\s+/g, "-");
        navigate(`/colleges-in-${citySlug}`, {
          replace: true,
          state: {}, // Clear the state to prevent infinite loops
        });
      }
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await axios.post(`${Base_URL}/create/enquiry_form`, formData);
      setFormData({
        name: "",
        email: "",
        contact_no: "",
        discipline_interested_in: "",
        program: "",
      });
      setIsModalOpen(false);
      toast.success("Form submitted successfully!");
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const openModal = (collegeName) => {
    setSelectedCollege(collegeName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Header />
      <div className='container mx-auto px-4 py-16 mt-20 flex flex-col lg:flex-row gap-6'>
        {/* Left Section - Filter Boxes */}
        <div className='w-full hidden md:block lg:w-1/4 font-dm-sans space-y-6'>
          {["Degree", "Specialization", "State", "City"].map((filter, idx) => (
            <div
              key={idx}
              className='p-3 border rounded-lg shadow-lg bg-[#FCFCFC]'
              style={{ height: "300px", overflow: "hidden" }}
            >
              <div className='flex justify-between items-center mb-4'>
                <h3 className='font-dm-sans text-sm font-bold'>{filter}</h3>
                <span
                  className='font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer'
                  onClick={() => handleClearAllFilters(filter)}
                >
                  Clear All
                </span>
              </div>
              <input
                type='text'
                className='w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-2'
                placeholder={`Search ${filter}`}
                value={
                  filter === "Degree"
                    ? degreeSearchQuery
                    : filter === "Specialization"
                    ? specializationSearchQuery
                    : filter === "State"
                    ? stateSearchQuery
                    : citySearchQuery
                }
                onChange={(e) => {
                  filter === "Degree"
                    ? setDegreeSearchQuery(e.target.value)
                    : filter === "Specialization"
                    ? setSpecializationSearchQuery(e.target.value)
                    : filter === "State"
                    ? setStateSearchQuery(e.target.value)
                    : setCitySearchQuery(e.target.value);
                }}
              />
              <div
                className='overflow-y-auto'
                style={{ maxHeight: "200px", paddingBottom: "16px" }}
              >
                <ul className='space-y-2'>
                  {getFilterData(filter)?.map((item) => (
                    <li
                      key={item.id}
                      className='flex items-center p-[2px] text-[14px] font-dm-sans font-[700]'
                    >
                      <input
                        type='checkbox'
                        className='mr-2 ml-1'
                        checked={
                          filter === "Degree"
                            ? selectedDegree.includes(item.id)
                            : filter === "Specialization"
                            ? selectedSpecialization.includes(item.id)
                            : filter === "State"
                            ? selectedState.includes(item.id)
                            : selectedCity.includes(item.id)
                        }
                        onChange={() => handleCheckboxChange(filter, item.id)}
                      />
                      <span>
                        {filter === "Degree" && item.program_master_name}
                        {filter === "Specialization" && item.sub_course_name}
                        {filter === "State" && item.name}
                        {filter === "City" && item.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Right Section - University Cards */}
        <div className='w-full lg:w-3/4 flex flex-col gap-8 lg:p-2'>
          {/* Heading Section */}
          <div className='flex justify-center mb-8'>
            <h2 className='font-dm-sans text-2xl lg:text-3xl text-center'>
              {selectedDegree.length > 0 ? (
                // Show only the first selected program name
                <>
                  {(() => {
                    const firstProgram = programList.find(
                      (p) => p.id === selectedDegree[0]
                    );
                    return firstProgram
                      ? `${firstProgram.program_master_name} Colleges in India`
                      : "Colleges in India";
                  })()}
                </>
              ) : selectedCity.length > 0 ? (
                // If cities are selected, show first city
                <>
                  {`Colleges in ${
                    cityList.find((city) => city.id === selectedCity[0])
                      ?.name || ""
                  }`}
                </>
              ) : selectedState.length > 0 ? (
                // If states are selected, show first state
                <>
                  {`Colleges in ${
                    stateList.find((state) => state.id === selectedState[0])
                      ?.name || ""
                  }`}
                </>
              ) : displayData === collegesByProgram ? (
                // For program-specific pages
                `${name} Colleges in India`
              ) : displayData === collegesByLocation ? (
                // For location-specific pages
                `Colleges in ${name}`
              ) : (
                // Default heading
                "All Colleges in India"
              )}
            </h2>
          </div>

          {/* Cards Section */}
          <div className='flex flex-col items-center gap-8'>
            <div className='flex flex-col gap-8 w-full lg:max-w-6xl'>
              {displayData?.length === 0 ? (
                <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full'>
                  <img src={nodata} alt='No data' className='w-40 h-40 mb-4' />
                  <p className='text-lg font-semibold text-gray-600'>
                    No colleges found.
                  </p>
                  <div
                    className='pt-2 text-[#468EFF] cursor-pointer'
                    onClick={clearAllFilters}
                  >
                    View all
                  </div>
                </div>
              ) : (
                displayData?.map((university) => (
                  <div
                    key={university.id}
                    className='relative border w-full rounded-lg shadow-lg p-5 flex flex-col lg:flex-row md:items-center items-start'
                  >
                    {/* University Image Section */}
                    <div className='w-full lg:w-2/5 mb-4 lg:mb-0 flex justify-center'>
                      <img
                        src={`${Img_URL}/${university.pictures[0]}`}
                        alt={university.university_name}
                        className='w-full max-w-[300px] md:h-[200px] cursor-pointer object-cover rounded-lg'
                        onClick={() => handleExploreClick(university)}
                      />
                    </div>

                    {/* University Info Section */}
                    <div className='w-full lg:w-3/5 flex flex-col justify-between p-4 relative'>
                      <h3
                        className='font-dm-sans text-lg font-bold mb-2 cursor-pointer'
                        onClick={() => handleExploreClick(university)}
                      >
                        {university.university_name}
                      </h3>
                      <div className='grid grid-cols-2 gap-4 w-full'>
                        {/* University Info Section */}
                        <div className='space-y-2'>
                          {infoRows.map((row, index) => (
                            <div key={row.id} className='flex items-center'>
                              <img
                                src={row.img}
                                alt='icon'
                                className='mr-2'
                                style={{ width: "15px", height: "15px" }}
                              />
                              <span className='text-[14px] font-dm-sans font-[500] text-gray-600'>
                                {university.keywords[index]?.details}
                              </span>
                            </div>
                          ))}
                        </div>

                        {/* Additional Info Section */}
                        <div className='  border border-[#57CC0266] rounded-[20px] flex flex-col items-center justify-center'>
                          <div className='w-[30px] md:w-[90px] h-[40px] md:h-[60px] mb-2'>
                            <img
                              src={Hat}
                              alt='Hat'
                              className='w-full h-full object-cover'
                            />
                          </div>
                          <div className='text-center'>
                            <span className='font-dm-sans text-[19px] font-bold'>
                              35+ Courses
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Buttons Section */}
                      <div className='mt-6 flex flex-row lg:flex-row gap-12'>
                        <button
                          className='w-[130px] lg:w-[180px] h-[45px] bg-[#3ACA2E] text-white font-dm-sans md:text-[14px] text-[10px] font-bold rounded-[20px] flex items-center justify-center'
                          onClick={() => openModal(university.university_name)}
                        >
                          Apply Now
                        </button>
                        <button className='w-[130px] lg:w-[180px] h-[45px] bg-white border border-[#3ACA2E] text-black md:text-[14px] text-[10px] font-dm-sans font-bold rounded-[20px] flex items-center justify-center'>
                          Get Free Counselling
                        </button>
                      </div>
                    </div>

                    {/* Wishlist Heart Icon */}
                    <div className='absolute top-2 right-2'>
                      <button
                        onClick={() => handleWishlistClick(university.id)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className={`h-6 w-6 ${
                            wishlist.has(university.id)
                              ? "text-red-500"
                              : "text-gray-400"
                          }`}
                          fill={wishlist.has(university.id) ? "red" : "none"}
                          viewBox='0 0 24 24'
                          stroke={
                            wishlist.has(university.id) ? "red" : "currentColor"
                          }
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {isModalOpen && (
            <div
              className='fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50'
              onClick={closeModal}
            >
              <div
                className='bg-white rounded-lg p-6 w-[95%] max-w-[600px] relative'
                onClick={(e) => e.stopPropagation()}
              >
                <h2 className='text-xl font-bold mb-4 text-gray-900'>
                  Apply to {selectedCollege}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 mb-2 text-sm font-medium'
                      htmlFor='name'
                    >
                      Name
                    </label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      value={formData.name}
                      onChange={handleInputChange}
                      className='w-full px-4 py-2 border border-gray-300 rounded-md text-sm'
                      required
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 mb-2 text-sm font-medium'
                      htmlFor='email'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                      className='w-full px-4 py-2 border border-gray-300 rounded-md text-sm'
                      required
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 mb-2 text-sm font-medium'
                      htmlFor='contact_no'
                    >
                      Contact Number
                    </label>
                    <input
                      type='text'
                      id='contact_no'
                      name='contact_no'
                      value={formData.contact_no}
                      onChange={handleInputChange}
                      className='w-full px-4 py-2 border border-gray-300 rounded-md text-sm'
                      required
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 mb-2 text-sm font-medium'
                      htmlFor='discipline_interested_in'
                    >
                      Discipline Interested In
                    </label>
                    <input
                      type='text'
                      id='discipline_interested_in'
                      name='discipline_interested_in'
                      value={formData.discipline_interested_in}
                      onChange={handleInputChange}
                      className='w-full px-4 py-2 border border-gray-300 rounded-md text-sm'
                      required
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 mb-2 text-sm font-medium'
                      htmlFor='program'
                    >
                      Program
                    </label>
                    <select
                      id='program'
                      name='program'
                      value={formData.program}
                      onChange={handleInputChange}
                      className='w-full px-4 py-2 border border-gray-300 rounded-md text-sm'
                      required
                    >
                      <option value=''>Select Program</option>
                      {programOptions.map((program) => (
                        <option key={program.id} value={program.id}>
                          {program.program_master_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type='submit'
                    className='bg-[#3ACA2E] text-white px-4 py-2 rounded-md w-full'
                  >
                    Submit
                  </button>
                </form>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Sticky Footer Division for Mobile */}
      <div className='fixed bottom-0 left-0 w-full bg-[#ffffff] border-t border-[#DADADA] lg:hidden'>
        <div className='flex items-center h-16'>
          {/* Column 1 */}
          {/* <div className='flex items-center justify-center w-1/2'>
            <img src={SortIcon} alt='Sort' className='w-4 h-4 mr-2' />
            <p className='text-sm font-dm-sans text-[#929292]'>Sort By</p>
          </div> */}

          {/* Vertical Line Separator */}
          {/* <div className='h-full border-l border-[#929292]'></div> */}

          {/* Column 2 */}
          <div
            className='flex items-center justify-center w-full'
            onClick={handleMobileFilterClick}
          >
            <img src={filter} alt='Filter' className='w-6 h-6 mr-2' />
            <p className='text-sm font-dm-sans text-[#929292]'>Filter</p>
          </div>
        </div>
      </div>

      {/* Mobile Filter Modal */}
      {isMobileFilterOpen && (
        <div className='fixed inset-0 z-50 bg-white flex flex-col lg:hidden mt-10'>
          <div className='flex items-center justify-between p-4 border-b border-[#DADADA]'>
            {/* <button onClick={handleCloseMobileFilter} className="text-[#929292]">
              &#10005;
            </button> */}
          </div>

          <div className='flex flex-grow'>
            <div className='w-1/3 bg-white border-r border-b border-t border-[#929292] overflow-hidden'>
              <ul className='space-y-2 p-0 mt-5  text-[16px] font-[700] font-dm-sans text-[#000000]'>
                {["Degree", "Specialization", "State", "City"].map(
                  (filter, index) => (
                    <li
                      key={index}
                      onClick={() => setActiveMobileFilter(filter)}
                      className={`p-2 cursor-pointer w-full ${
                        activeMobileFilter === index
                          ? "bg-[#F2F2F2] border-t border-[#00000040]"
                          : "bg-white"
                      }`}
                      style={{ height: "38px" }}
                    >
                      {filter}
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className='w-2/3 bg-white overflow-hidden p-4 ml-4'>
              <ul className='space-y-2 mt-5'>
                <ul className='space-y-2'>
                  {getFilterData(activeMobileFilter)?.map((item, idx) => (
                    <li
                      key={idx}
                      className='flex items-center p-2 text-[14px] font-dm-sans font-[700]'
                    >
                      <input
                        type='checkbox'
                        className='mr-2'
                        checked={
                          activeMobileFilter === "Degree"
                            ? selectedDegree.includes(item.id)
                            : activeMobileFilter === "Specialization"
                            ? selectedSpecialization.includes(item.id)
                            : activeMobileFilter === "State"
                            ? selectedState.includes(item.id)
                            : activeMobileFilter === "City"
                            ? selectedCity.includes(item.id)
                            : false
                        }
                        onChange={() =>
                          handleCheckboxChange(activeMobileFilter, item.id)
                        }
                      />
                      <span>
                        {activeMobileFilter === "Degree" &&
                          item.program_master_name}
                        {activeMobileFilter === "Specialization" &&
                          item.sub_course_name}
                        {activeMobileFilter === "State" && item.name}
                        {activeMobileFilter === "City" && item.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>

          <div className='fixed bottom-0 left-0 w-full bg-[#ffffff] border border-[#929292] lg:hidden'>
            <div className='flex items-center h-16'>
              <div
                className='flex items-center justify-center w-1/2'
                onClick={clearAllFilters}
              >
                <p className='text-[14px] font-dm-sans font-[500] text-[#929292]'>
                  Clear All
                </p>
              </div>

              <div className='h-full border-l border-[#929292]'></div>

              <div
                className='flex items-center justify-center w-1/2'
                onClick={handleCloseMobileFilter}
              >
                <p className='text-[14px] font-dm-sans font-[500] text-[#929292]'>
                  Apply Filters
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default LandingUniversity;
