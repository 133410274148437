import React, { useState, useEffect } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // To fetch login_id

const AllDropDownNews = () => {
  const [dropDownNewsList, setDropDownNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id || 1; // Get login_id from Redux or default to 1

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = dropDownNewsList.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(dropDownNewsList.length / entriesPerPage);

  useEffect(() => {
    const fetchDropDownNews = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/news_announcements`);
        const formattedNews = response.data.map(news => ({
          ...news,
          announcement_date: news.announcement_date 
            ? new Date(news.announcement_date).toISOString().split('T')[0]
            : new Date().toISOString().split('T')[0]
        }));
        setDropDownNewsList(formattedNews.reverse());
      } catch (error) {
        console.error('Error fetching dropdown news:', error);
        toast.error('Error fetching news. Please try again.');
      }
    };

    fetchDropDownNews();
  }, []);

  const handleEditClick = (news) => {
    try {
      const newsToEdit = {
        ...news,
        announcement_date: news.announcement_date 
          ? new Date(news.announcement_date).toISOString().split('T')[0] 
          : new Date().toISOString().split('T')[0]
      };
      
      navigate(`/admin/news/editDropDownNews/${news.announcement_id}`, { 
        state: { 
          news: newsToEdit
        } 
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      toast.error('Error processing date. Please try again.');
    }
  };

  const handleDeleteClick = async (id) => {
    console.log('Deleting dropdown news with ID:', id);
    try {
      const url = `${Base_URL}/dropdown-news/${id}`;
      console.log('DELETE request URL:', url);
      await axios.delete(url);
      setDropDownNewsList(prevNewsList => prevNewsList.filter(news => news.announcement_id !== id));
      toast.success('Dropdown news deleted successfully.');
    } catch (error) {
      console.error('Error deleting dropdown news:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete dropdown news.');
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredEntries = currentEntries.filter((news) =>
    news.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid Date';
      return date.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All DropDown News" breadcrumb={['News', 'All DropDown News']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            All DropDown News
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              onClick={() => navigate('/admin/news/addDropDownNews')}
            >
              + Add New
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {['Title', 'Content', 'Trending News', 'Date', 'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((news) => (
                  <tr key={news.announcement_id} className="hover:bg-white hover:shadow-md" style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)', verticalAlign: 'top' }}>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px', }}>
                      {news.title}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px', }}>
                      {news.content}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px', }}>
                      {news.trending_news === 1 ? 'Yes' : 'No'}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px', }}>
                      {formatDate(news.announcement_date)}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px', }}>
                      <div className="flex space-x-2">
                        <button 
                          onClick={() => handleEditClick(news)} 
                          className="p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(news.announcement_id)}
                          className="p-1 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">No dropdown news found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllDropDownNews; 