import React,{useEffect,useState} from 'react';
import CourseCard from './CourseCard/CourseCard';
import { Base_URL } from '../../../apiConfig';
import { useNavigate } from 'react-router-dom';
const Courses = ({ collegeDetails}) => {
  const [coursesData, setCoursesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  // Fetch courses data using university_id from props
  useEffect(() => {
    const fetchCoursesData = async () => {
      try {
        const response = await fetch(`${Base_URL}/programs/university/${collegeDetails?.university_id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCoursesData(data); 
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (collegeDetails?.university_id) {
      fetchCoursesData();
    }
  }, [collegeDetails?.university_id]);

  const handleViewMore = (id, university_id) => {
    // Navigate to the course details page with both IDs in the URL
    navigate(`/coursedetail/${university_id}/${id}`);
  };
  
  if (loading) {
    return <p>Loading courses...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="bg-[#DCFFC4] px-5">
    {/* Course Section */}
    <div className="py-8 flex flex-col items-center">
      <p className="text-center text-lg text-black font-semibold mb-8">{collegeDetails?.university_name} Courses, Fees and Eligibility Criteria 2024</p>
      
  
      {/* Centered Grid Container */}
      <div className="flex-grow flex items-center justify-center w-full">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center w-full">
    {coursesData.map((course, index) => (
      <CourseCard 
        key={index}
        program_master_name={course.program_master_name}
        program_fees={course.program_fees}
        program_duration={course.program_duration}
        study_mode={course.study_mode}
        eligibility={course.eligibility}
        exams_accepted={course.exams_accepted}
        id={course.id}
        onViewMore={() => handleViewMore(course.id, collegeDetails.university_id)}
      />
    ))}
  </div>
</div>


    </div>
  
   
    {/* <div className="py-8 flex flex-col items-center">
      <p className="text-center text-lg text-black font-semibold mb-5">Search Courses</p>
  
    
      <div className="flex flex-col sm:flex-row gap-5 mb-5 max-w-4xl w-full mx-auto">
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="degree" className="text-black text-xs font-semibold">Degree</label>
          <select id="degree" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Degree--</option>
            <option value="B.Tech">B.Tech</option>
            <option value="MBA">MBA</option>
            <option value="B.Sc.">B.Sc.</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="discipline" className="text-black text-xs font-semibold">Discipline</label>
          <select id="discipline" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Discipline--</option>
            <option value="Engineering">Engineering</option>
            <option value="Business">Business</option>
            <option value="Science">Science</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="department" className="text-black text-xs font-semibold">Department</label>
          <select id="department" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Department--</option>
            <option value="Computer Science">Computer Science</option>
            <option value="Mechanical Engineering">Mechanical Engineering</option>
            <option value="Finance">Finance</option>
          </select>
        </div>
        <div className="flex flex-col gap-1 flex-grow">
          <label htmlFor="specialization" className="text-black text-xs font-semibold">Specialization</label>
          <select id="specialization" name="option" className="rounded-md border border-gray-300 p-2 w-full">
            <option value="">--Select Specialization--</option>
            <option value="Data Science">Data Science</option>
            <option value="Marketing">Marketing</option>
            <option value="Cybersecurity">Cybersecurity</option>
          </select>
        </div>
      </div>
  
     
      <div className="py-3 flex justify-center w-full">
        <button className="py-2 px-5 text-white bg-[#3ACA2E] text-sm rounded-md">SEARCH</button>
      </div>
    </div> */}
  </div>
  
  );
};

export default Courses;
