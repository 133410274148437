import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import home_top from "../assets/landing/home_top.png";
import Hat from "../assets/landing/Hat.png";
import Process from "../components/Landing/Process/Process";
import TopColleges from "../components/Landing/TopColleges/TopColleges";
import CollegeBy from "../components/Landing/CollegeBy/CollegeBy";
import LandingVideo from "../components/Landing/LandingVideo/LandingVideo";
import Reviews from "../components/Landing/Reviews/Reviews";
import LandingNewsEvents from "../components/Landing/LandingNewsEvents/LandingNewsEvents";
import Newsletter from "../components/Landing/Newsletter/Newsletter";
import Colleges from "../components/Landing/Colleges/Colleges";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import Authentication from "../components/Authentication/Authentication";


// Make sure to set the app element for accessibility
Modal.setAppElement('#root');

const Landing = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [authMode, setAuthMode] = useState('login'); // 'login' or 'signup'
  const [placeholder, setPlaceholder] = useState(' College');
  const placeholders = [' College', ' Exam', ' Course'];
  const [universityName, setUniversityName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const { registration_id, token,name } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // To toggle modal opening
  const openModal = (mode) => {
    setAuthMode(mode);  // Set the authMode based on what user clicked (signup or login)
    setModalIsOpen(true); // Open the modal
  };

  const closeModal = () => setModalIsOpen(false); // Close modal handler

  // Auto close modal when user has registration_id
  useEffect(() => {
    console.log("token",name)
    if (registration_id) {
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [registration_id]);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % placeholders.length;
      setPlaceholder(placeholders[index]);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const fetchUniversities = async (university_name) => {
    if (university_name.trim()) {
      try {
        const response = await axios.get(`https://api.collegedwarka.com/api/search/${university_name}`);
        if (response.data && Array.isArray(response.data)) {
          setSearchResults(response.data);
        } else {
          setSearchResults([]); // Handle empty response data
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchResults([]); // Clear results if an error occurs
      }
    } else {
      setSearchResults([]); // Clear results if the input is empty
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setUniversityName(value);
    // Reset selectedUniversity when user types
    setSelectedUniversity(null);
    
    if (value.trim()) {
      setDropdownVisible(true);
      fetchUniversities(value);
    } else {
      setDropdownVisible(false);
      setSearchResults([]);
    }
  };

  const handleSearchSelect = (college) => {
    setUniversityName(college.university_name);
    setSelectedUniversity(college);
    setDropdownVisible(false);
  };

  const handleSearchSubmit = async (e) => {
    if (e.key === 'Enter') {
      try {
        // If a college is selected from dropdown, use that
        if (selectedUniversity) {
          const universityId = selectedUniversity.university_id;
          const universityNameSlug = encodeURIComponent(
            selectedUniversity.university_name.trim().toLowerCase().replace(/\s+/g, '-')
          );
          navigate(`/college/${universityId}/${universityNameSlug}`, {
            state: { college: selectedUniversity }
          });
        } 
        // If user typed a name but didn't select from dropdown
        else if (universityName.trim()) {
          // First try to find an exact match in current search results
          let matchedCollege = searchResults.find(
            college => college.university_name.toLowerCase() === universityName.toLowerCase()
          );

          // If no match in current results, make a new API call
          if (!matchedCollege) {
            const response = await axios.get(`https://api.collegedwarka.com/api/search/${universityName}`);
            if (response.data && Array.isArray(response.data)) {
              matchedCollege = response.data.find(
                college => college.university_name.toLowerCase() === universityName.toLowerCase()
              );
            }
          }

          if (matchedCollege) {
            const universityId = matchedCollege.university_id;
            const universityNameSlug = encodeURIComponent(
              matchedCollege.university_name.trim().toLowerCase().replace(/\s+/g, '-')
            );
            
            // Fetch complete college details before navigation
            const detailsResponse = await axios.get(`https://api.collegedwarka.com/api/universities/${universityId}`);
            const completeCollegeDetails = detailsResponse.data;
            
            navigate(`/college/${universityId}/${universityNameSlug}`, {
              state: { college: completeCollegeDetails }
            });
          } else {
            console.log("Please select a college from the dropdown list");
          }
        }
      } catch (error) {
        console.error('Error during search:', error);
        
      }
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      border: 'none',
      borderRadius: '20px', // Increased border radius
      overflow: 'hidden',
      width: '80%',
      maxWidth: '1000px', // Increased max-width
    height:'auto' // Added max-height
     
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000,
    },
  };

  return (
    <div>
      <Header />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Authentication Modal"
      >
        <Authentication initialMode={authMode} onClose={closeModal} />
      </Modal>

      {/* Top Section */}
      <div className="relative px-4 pb-10 pt-[150px] md:px-8 md:pt-[200px]">
        <div className="absolute inset-0 z-[-1]">
          <img src={home_top} alt="cover-image" className="h-full w-full object-cover" />
        </div>

        <div className="relative flex flex-col items-center text-center">
          <p className="text-2xl md:text-4xl text-white font-semibold mb-8 md:mb-12">
            Your Journey to the Perfect College Starts Here – Explore, Compare and Succeed!
          </p>
          <div className="relative flex justify-center items-center bg-white rounded-3xl px-4 py-2 md:px-6 md:py-3 mb-8 md:mb-12">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input
              type="text"
              placeholder={`Find your  '${placeholder}'`}
              className="search-input w-[240px] md:w-[300px] h-[25px] border-0 pl-12 pr-4 rounded-full"
              style={{ border: '1px solid #fff', outline: 'none' }}
              value={universityName}
              onChange={handleSearchChange}
              onKeyDown={handleSearchSubmit}
            />
            <img src={Hat} alt="hat" className="w-16 h-16 absolute -right-4 -top-4 md:w-20 md:h-20 md:-right-6 md:-top-6 z-10" />
            {dropdownVisible && searchResults.length > 0 && (
              <div className="absolute top-full bg-white shadow-lg rounded-lg w-[240px] md:w-[300px] max-h-60 overflow-y-auto z-50">
                <ul>
                  {searchResults.map((college) => (
                    <li
                      key={college.university_id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSearchSelect(college)}
                    >
                      <p className="text-sm font-medium text-black">{college.university_name}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <p className="text-white text-lg md:text-xl font-medium mb-8 md:mb-10">
            Navigate Your Path to Success: Discover Top Colleges and Make Informed Choices!
          </p>
          {!registration_id && (
            <div className="flex gap-6 flex-wrap">
              <button
                className="py-2 px-6 text-white font-semibold text-base border-2 border-white rounded-[20px] bg-[#3ACA2E]"
                onClick={() => openModal('signup')}
              >
                SIGN UP
              </button>
              <button
                className="py-2 px-6 text-base font-semibold border-2 border-[#3ACA2E] rounded-[20px] bg-white"
                onClick={() => openModal('login')}
              >
                LOGIN
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Other sections */}
      <Process />
      <TopColleges />
      <CollegeBy />
      <LandingVideo />
      <Reviews />
      <Colleges />
      <LandingNewsEvents />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Landing;