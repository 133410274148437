import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";


const EditAdminFaq = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const faq = location.state; // Get FAQ data passed from AllAdminFaq
  const [formData, setFormData] = useState({
    question: faq?.question || '',
    answer: faq?.answer || ''
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const { registration_id, token,name } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      await axios.put(`${Base_URL}/faq/${faq.id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        withCredentials: true // This allows cookies to be sent with the request
      });
      toast.success('FAQ updated successfully!');
      navigate('/admin/faqs/all'); // Navigate back to the list after success
    } catch (error) {
      toast.error('Failed to update FAQ.');
      console.error('Failed to update FAQ:', error.response?.data || error.message);
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle="FAQs" subTitle="Edit FAQ" />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="question" className="mb-2 font-medium text-sm">Question</label>
                <input
                  type="text"
                  id="question"
                  name="question"
                  value={formData.question}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="answer" className="mb-2 font-medium text-sm">Answer</label>
                <textarea
                  id="answer"
                  name="answer"
                  value={formData.answer}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md">
              {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Update FAQ'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAdminFaq;
