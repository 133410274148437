import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'; // To fetch login_id from Redux store
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEvent = () => {
  // Fetch login_id from Redux or localStorage (as a fallback)
  const loginData = useSelector((state) => state.collegeAuth.data);
  const login_id = loginData?.login_id || localStorage.getItem('login_id');

  const [formData, setFormData] = useState({
    event_name: '',
    event_date: '',
    event_time: '',
    event_location: '',
    event_details: '',
    event_organizer: login_id, // Set login_id as the event organizer
  });

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [loading, setLoading] = useState(false); // Track loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImageName(file ? file.name : 'No file chosen');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting

    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('event_picture', image); // Using 'event_picture' as the JSON key
      }

      const response = await axios.post(`${Base_URL}/event`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const id = response.data.id; // Assuming the ID is returned in the response
      console.log('Event created with ID:', id);
      toast.success('Event created successfully!');

      // Clear form data
      setFormData({
        event_name: '',
        event_date: '',
        event_time: '',
        event_location: '',
        event_details: '',
        event_organizer: login_id, // Reset with login_id
      });
      setImage(null);
      setImageName('No file chosen');
      setLoading(false); // Stop loading after success
    } catch (error) {
      console.error('Failed to create event:', error.response?.data || error.message);
      toast.error('Failed to add event. Please try again.');
      setLoading(false); // Stop loading after failure
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md font-poppins h-screen">
      <HeaderSection title="Add Event" breadcrumb={['Event', 'Add Event']} />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Event Details</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 font-poppins">
            <div className="flex flex-col">
              <label
                htmlFor="event_name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]"
              >
                Event Name
              </label>
              <input
                type="text"
                id="event_name"
                name="event_name"
                value={formData.event_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="event_date"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]"
              >
                Event Date
              </label>
              <input
                type="date"
                id="event_date"
                name="event_date"
                value={formData.event_date}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="event_time"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]"
              >
                Event Time
              </label>
              <input
                type="time"
                id="event_time"
                name="event_time"
                value={formData.event_time}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="event_location"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]"
              >
                Event Location
              </label>
              <input
                type="text"
                id="event_location"
                name="event_location"
                value={formData.event_location}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label
                htmlFor="event_details"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]"
              >
                Event Details
              </label>
              <textarea
                id="event_details"
                name="event_details"
                value={formData.event_details}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="event_picture"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]"
              >
                Upload Event Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className="text-[12.25px]">Choose File</span>
                  <input
                    type="file"
                    id="event_picture"
                    name="event_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName}
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen hover:bg-[#5BDE50] text-white rounded-md font-poppins flex items-center justify-center"
            disabled={loading} // Disable the button while loading
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddEvent;