import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from "react-redux";

const CareerForm = () => {
  const [streams, setStreams] = useState([]);
  const [courseMasterData, setCourseMasterData] = useState([]);
  const [selectedStream, setSelectedStream] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const { token } = useSelector((state) => state.auth);

  // Fetch streams
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/streams`);
        setStreams(response.data);
      } catch (error) {
        console.error('Error fetching streams:', error);
        toast.error('Failed to load streams');
      }
    };

    fetchStreams();
  }, []);

  // Fetch courses based on selected stream
  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedStream) {
        try {
          const response = await axios.get(`${Base_URL}/get/program_master`);
          // Filter courses based on selected stream
          const filteredCourses = response.data.filter(
            course => course.stream_id === parseInt(selectedStream)
          );
          setCourseMasterData(filteredCourses);
        } catch (error) {
          console.error('Error fetching course data:', error);
          toast.error('Failed to load course data');
        }
      } else {
        setCourseMasterData([]);
      }
    };

    fetchCourses();
  }, [selectedStream]);

  const handleStreamChange = (e) => {
    const streamId = e.target.value;
    setSelectedStream(streamId);
    setSelectedCourse(''); // Reset course selection when stream changes
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match('image.*')) {
        toast.error('Please select an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size should not exceed 5MB');
        return;
      }
      setImage(file);
      setImageName(file.name);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedStream) {
      toast.error("Please select a stream!");
      return;
    }

    if (!selectedCourse) {
      toast.error("Please select a course!");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('name', selectedStream); // Using stream name as career name
      formData.append('course_master_id', selectedCourse);
      formData.append('created_by', 1);
      
      if (image) {
        formData.append('picture', image);
      }

      const response = await axios.post(`${Base_URL}/create-upload/career_master`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `Bearer ${token}`
        }
      });

      toast.success('Career successfully created!');
      
      // Reset form
      setSelectedStream('');
      setSelectedCourse('');
      setImage(null);
      setImageName('No file chosen');
    } catch (error) {
      console.error('Error submitting career data:', error);
      toast.error(error.response?.data?.message || 'Failed to create career');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Create Career" breadcrumb={['Career', 'Create Career']} />
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            Create Career
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Stream Selection */}
            <div className="flex flex-col">
              <label htmlFor="stream" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Select Stream
              </label>
              <select
                id="stream"
                name="stream"
                value={selectedStream}
                onChange={handleStreamChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              >
                <option value="">-- Select Stream --</option>
                {streams.map((stream) => (
                  <option key={stream.id} value={stream.stream_name}>
                    {stream.stream_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Course Selection */}
            <div className="flex flex-col">
              <label htmlFor="course" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Select Course
              </label>
              <select
                id="course"
                name="course"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
                disabled={!selectedStream}
              >
                <option value="">-- Select Course --</option>
                {courseMasterData.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.program_master_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Picture Upload */}
            <div className="flex flex-col">
              <label htmlFor="picture" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Upload Picture</label>
              <div className="flex border border-gray-300 rounded-md mt-2 overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]">
                  <span>Choose File</span>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    onChange={handleImageChange}
                    accept="image/*"
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]">
                  {imageName}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button with spinner */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Creating...
              </div>
            ) : (
              'Create Career'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default CareerForm;
