import React from 'react';

const CareerWorkDescription = ({ data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">Work Description</h2>
      <p>{data?.work_description || "No data available."}</p>
    </div>
  );
};

export default CareerWorkDescription;
