import React, { useState } from 'react';
import axios from 'axios';
import HeaderSection from '../headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';  // Importing useSelector for accessing login_id
import 'react-toastify/dist/ReactToastify.css'; 

const AddFAQ = () => {
  // Assuming login_id is stored in Redux state or any global state management
  const loginData = useSelector((state) => state.auth?.data); // Adjust according to your Redux store structure
  const login_id = loginData?.login_id || 2; // Fallback to a default value

  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    university_id: login_id,  // Set dynamically based on user login     // Set dynamically based on user login
    
    registration_typeid: 3,   // Fixed value as specified
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure registration_typeid is always 3
      const postData = { ...formData, registration_typeid: 3 };

      const response = await axios.post(`${Base_URL}/faq/`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      toast.success('FAQ added successfully!');
      setFormData({
        question: '',
        answer: '',
        university_id: login_id,
        user_id: login_id,
        registration_typeid: 3, // Reset to default value
      });
      setError(null);
    } catch (error) {
      console.error('Failed to add FAQ:', error.response?.data || error.message);
      toast.error('Failed to add FAQ. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Add FAQ"
        breadcrumb={['FAQ', 'Add FAQ']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">FAQ Details</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="question"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Question
              </label>
              <input
                type="text"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="answer"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Answer
              </label>
              <input
                type="text"
                id="answer"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-customblue text-white rounded-md font-poppins hover:bg-hoverblue">
            Submit
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddFAQ;