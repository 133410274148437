import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer"; // Assuming you have a Footer component

const NewsDetail = () => {
  const [news, setNews] = useState([]);

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://api.collegedwarka.com/api/get/news_announcements"
        );
        
        // Sort news by created_at date in descending order (latest first)
        const sortedNews = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setNews(sortedNews); // Set the sorted news
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, []);

  // Utility function to split text into paragraphs and fetch the required ones
  const getParagraphs = (content) => {
    const paragraphs = content.split(". "); // Split by sentences (adjust as needed)
    const firstTwo = paragraphs.slice(0, 2).join(". ") + ".";
    const lastTwo = paragraphs.slice(-2).join(". ") + ".";
    return { firstTwo, lastTwo };
  };

  // Utility to safely parse and format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "Invalid Date"
      : date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
  };

  if (!news.length) return <div>Loading...</div>;

  return (
    <div className="w-full">
      {/* Header */}
      <Header />

      {/* Top Division with gradient background and centered ALL NEWS */}
      <div
        className="w-full py-8"
        style={{
          background: "linear-gradient(90deg, #57CC02 67%, #FFFFFF 100%)",
          marginTop: "100px",
          border: "1px solid #00000040",
        }}
      >
        <h1 className="text-center text-white text-4xl font-bold">ALL NEWS</h1>
      </div>

      {/* News Content */}
      <div className="w-full max-w-6xl mx-auto p-6 mt-12">
        {news.map((item) => {
          const { lastTwo } = getParagraphs(item.content);

          return (
            <div
              key={item.id}
              className="bg-[#FAFAFA] p-8 mb-6 rounded-lg border border-[#00000040] shadow-md"
            >
              {/* Title */}
              <h1 className="text-2xl font-bold">{item.title}</h1>

              {/* Date */}
              <p className="text-gray-500 text-sm mt-2">
                Created on: {formatDate(item.created_at)}
              </p>

              {/* Image */}
              {item.picture ? (
                <div className="flex justify-center my-6">
                  <img
                    src={`https://api.collegedwarka.com/api/files${item.picture}`}
                    alt={item.title}
                    className="w-full max-w-[800px] h-[350px] object-cover rounded-md"
                  />
                </div>
              ) : (
                <div className="flex justify-center my-6">
                  <div className="w-full max-w-[800px] h-[350px] bg-gray-300 object-cover rounded-md"></div>
                </div>
              )}

              {/* Last two paragraphs below the image */}
              <div className="mt-4 text-gray-700 font-[600] leading-relaxed">
                <p>{lastTwo}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Division with gradient and button */}
      {/* <div
        className="w-full py-8 flex flex-col md:flex-row items-center justify-center"
        style={{
          background: "linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)",
          marginBottom: "0px",
        }}
      >
        <p className="text-black text-xl font-semibold text-center mb-4 md:mb-0">
          Planning to choose Electronic Engineer as your Career?
        </p>
        <button className="bg-[#3ACA2E] text-white px-6 py-3 rounded-lg font-bold mx-4">
          Get Expert Help
        </button>
      </div> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default NewsDetail;
