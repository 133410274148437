import React from 'react';

const CareerSalary = ({ data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">Salary</h2>
      <p>{data?.salary || "No data available."}</p>
    </div>
  );
};

export default CareerSalary;
