import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './slice/authSlice';
import collegeAuthReducer from './slice/collegeAuth'; // Import the new collegeAuthSlice

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Combine both auth and collegeAuth reducers
const rootReducer = combineReducers({
  auth: authReducer,
  collegeAuth: collegeAuthReducer,
});

// Create a persisted reducer with the combined reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor to be used in the application
export const persistor = persistStore(store);
export default store;
