import React, { useRef } from 'react';
import landing_video from '../../../assets/landing/landing_video.png';
import Newsletter from '../Newsletter/Newsletter';

const LandingVideo = () => {
    // Create a ref for the Newsletter component
    const newsletterRef = useRef(null);

    // Scroll to the Newsletter section
    const scrollToNewsletter = () => {
        newsletterRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <div className="relative h-[500px] lg:h-[500px] md:h-[400px]">
                <img src={landing_video} alt="" className="h-full w-full object-cover" />

                <p className="absolute top-0 w-full text-center py-10 text-[26px] font-sans md:text-2xl lg:text-3xl font-bold text-white mb-10 md:mb-16">
                    Find Your Future: Watch to Discover Your Path
                </p>

                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <button 
                        className="bg-white h-[60px] w-[60px] md:h-[70px] md:w-[70px] lg:h-[80px] lg:w-[80px] rounded-full flex items-center justify-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#282828" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#282828" className="h-[40px] md:h-[50px] lg:h-[60px] ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Blue background section */}
            <div className="py-6 lg:py-8 px-4 lg:px-5 bg-[#002147] flex flex-col sm:flex-row items-center gap-6 lg:gap-24 h-[470px] lg:h-[331px] font-sans justify-center md:h-auto">
                <div className="text-white text-center sm:text-left flex flex-col gap-2 lg:gap-3 px-4 lg:px-5">
                    <p className="text-[24px] sm:text-[24px] md:text-[30px] lg:text-[44px] font-dm-sans font-[500]">
                        Want to get the best for you?
                    </p>
                    <p className="text-[20px] sm:text-[20px] lg:text-[32px] md:text-[20px] font-dm-sans font-[500]">
                        Find your perfect fit with College Dwarka!
                    </p>
                    <p className="text-[16px] sm:text-[16px] md:text-[14px] lg:text-[20px] font-dm-sans font-[500]">
                        Our unique system matches your skills, personality, and interests to the perfect career path.
                    </p>
                </div>
                <div className="flex flex-col items-center md:items-end mt-4 sm:mt-0">
                    <button 
                        onClick={scrollToNewsletter} // Trigger scroll to Newsletter
                        className="text-white lg:w-[360px] lg:h-[60px] lg:rounded-[20px] md:w-[250px] md:h-[60px] md:rounded-[20px] bg-[#57CC02] py-2 px-4 lg:py-2 lg:px-5 rounded-full md:text-[14px] lg:text-[20px]"
                    >
                        GET CAREER RECOMMENDATIONS
                    </button>
                </div>
            </div>

            {/* Newsletter component */}
            <div ref={newsletterRef}>
                <Newsletter />
            </div>
        </div>
    );
};

export default LandingVideo;
