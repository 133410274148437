import React ,{useEffect} from 'react'
import Header from '../Header/Header'
import Footer from './Footer'

const AboutUs = () => {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header/>
         {/* Banner Section */}
      <section className="flex py-8 mt-20 border-b-2 border-gray-200 items-center justify-center   text-2xl font-bold"
       style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}>
        About College Dwarka
      </section>

      {/* Founders Section */}
      {/* <section className="text-center py-10">
        <h2 className="text-2xl font-medium mb-6">OUR FOUNDERS</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden p-4">
              <img src="/path/to/image.jpg" alt="Founder" className="w-full h-48 object-cover" />
              <h3 className="text-lg font-semibold mt-4">Name</h3>
              <p className="text-gray-600">Founder / Co-Founder</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* Our Story Section */}
      <section className="bg-[#DBFDC3] p-8  mt-20">
        <h2 className="text-center text-2xl font-medium mb-4">OUR STORY</h2>
        <p className="text-center text-gray-700 max-w-2xl mx-auto">
        Welcome to College Dwarka – Your Guide from College Admissions to Career Success!

College Dwarka is an all-in-one platform designed to empower students in finding the right college, gaining essential skills, and securing meaningful careers. Our mission is to make higher education accessible to students across India, from remote locations to urban centers, offering everything needed to navigate the path from admission to professional success.

        </p>
      </section>

      {/* Team Section */}
      {/* <section className="text-center py-10">
        <h2 className="text-2xl font-medium mb-6">Meet Our Team</h2>
        <div className="flex overflow-x-scroll space-x-4 p-4">
          {[1, 2, 3, 4].map((_, index) => (
            <div key={index} className="min-w-[200px] bg-white shadow-lg rounded-lg overflow-hidden p-4">
              <img src="/path/to/image.jpg" alt="Team Member" className="w-full h-48 object-cover" />
              <h3 className="text-lg font-semibold mt-4">Name</h3>
              <p className="text-gray-600">Designation</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* Offerings Section */}
      <section className="bg-[#DBFDC3] p-8  mt-20">
        <h2 className="text-center text-2xl font-medium mb-6">Our Offerings</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
          <div className="">
            <h3 className="text-lg font-semibold mb-2">For Students</h3>
            <p className="text-gray-700">
  <ul className="list-disc list-inside space-y-2">
    <li>
      <strong>Find Your Ideal College</strong> <br />
      College Dwarka helps you discover the best colleges that match your academic goals and career aspirations, with in-depth information on programs, campus life, and scholarships.
    </li>
    <li>
      <strong>Internships and Skill-Building Training</strong> <br />
      Gain real-world experience through internships and continuous training programs designed to equip you with the skills needed in today’s job market.
    </li>
    <li>
      <strong>Personalized Webinars and Continuous Support</strong> <br />
      Stay connected through expert-led webinars and receive ongoing support from our team to ensure you’re on track during your college journey.
    </li>
    <li>
      <strong>Job Placement Assistance</strong> <br />
      We support you even after graduation, with resume-building, interview preparation, and job placement services to help you land your dream job.
    </li>
  </ul>
</p>

          </div>
          <div className="">
            <h3 className="text-lg font-medium mb-2">For Colleges</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li>
          <strong>Advanced Admin Dashboard</strong> <br />
          College Dwarka provides an advanced admin dashboard to manage and showcase their offerings effectively.
        </li>
        <li>
          <strong>Enhanced Visibility</strong> <br />
          The platform enhances visibility, streamlines information management, and introduces new ways to attract and engage with prospective students.
        </li>
        <li>
          <strong>Showcase Unique Offerings</strong> <br />
          Institutions can effortlessly highlight their unique programs, campus life, and success stories, ensuring they stand out in the competitive educational landscape.
        </li>
      </ul>
          </div>
        </div>
      </section>


      

      {/* Life at CollegeDwarka Section */}
      {/* <section className="text-center py-10 font-medium">
        <h2 className="text-2xl font-bold mb-6">Life at CollegeDwarka</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
        </div>
      </section> */}

        {/* Our Vision Section */}
        <section className="bg-[#DBFDC3] p-8 mt-20">
        <h2 className="text-center text-2xl font-medium mb-4">Our Vision: Shaping Tomorrow’s Leaders</h2>
        <p className=" text-gray-700  text-left">
        At College Dwarka, we’re committed to empowering
         students not only as job seekers but as future leaders and entrepreneurs.
          Over the next two years, our vision is to provide in-depth technology and 
          entrepreneurship training to prepare students for a future where they can master technology and contribute to India’s growth in the tech industry.
        </p>
        
       <h3 className="mt-5"> Why Choose College Dwarka?</h3>
       <p className="text-gray-700 text-left">
  <ul className="list-disc list-inside space-y-2">
    <li>
      <strong>End-to-End Support:</strong> We guide students from choosing a college to building a career.
    </li>
    <li>
      <strong>Scholarship Opportunities:</strong> Helping economically disadvantaged students overcome financial barriers.
    </li>
    <li>
      <strong>Industry Connections:</strong> Connecting you with internships and employers for career success.
    </li>
    <li>
      <strong>Advanced Tech Features:</strong> Including virtual tours, AI chat support, and interactive dashboards.
    </li>
  </ul>
  <p className="mt-4">
    College Dwarka is more than a platform—it’s a journey. Join us and discover a future filled with possibilities!
  </p>
</p>

      </section>

      {/* Contact Us Section */}
      <section className=" p-8 gap-8">
        <div className="">
          <h3 className="text-2xl font-semibold text-center mb-6">Contact Us</h3>
          <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 px-4">
        {/* Contact Information Box */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex items-center mb-4">
            {/* Address Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
</svg>

            <span className="ml-4 text-lg">Trikuta Nagar,Jammu,J&K</span>
          </div>
          <div className="flex items-center mb-4">
            {/* Mail Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>

            <span className="ml-4 text-lg">info@krishradinnovations.com</span>
          </div>
          <div className="flex items-center">
            {/* Phone Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

            <span className="ml-4 text-lg">+91 6005130753</span>
          </div>
        </div>
        
        {/* Map Placeholder */}
        <div className="bg-gray-200 rounded-lg flex items-center justify-center text-gray-600 font-semibold">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.1390719611395!2d74.88283587516715!3d32.68234718881902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e9ca552080edb%3A0x75f68baba72b76ce!2sTrikuta%20Nagar%2C%20Jammu%2C%20180012!5e0!3m2!1sen!2sin!4v1730028476895!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
        </div>
       
      </section>

      {/* Call to Action Section */}
      <section className="flex justify-center items-center p-8"
       style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}>
        <p className="text-center text-lg font-medium">Get in touch with our Experts.</p>
        <button className="ml-4 text-white bg-[#3ACA2E] font-bold  rounded-lg py-2 px-4 rounded">Get Expert Help</button>
      </section>
      <Footer/>

    </div>
  )
}

export default AboutUs