import React from 'react';

const CareerJobRoles = ({ data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold">Job Roles</h2>
      <p>{data?.job_roles || "No data available."}</p>
    </div>
  );
};

export default CareerJobRoles;
