import React, { useEffect, useState,useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Tula from "../assets/landing/Tula.jpg";
import "./Scrollbar.css";
import axios from "axios";
import '../styles/college.css'
import CareerAbout from "./CareerAbout";
import CareerEligibility from "./CareerEligibility";
import CareerJobRoles from "./CareerJobRoles";
import CareerWorkDescription from "./CareerWorkDescription";
import CareerSalary from "./CareerSalary";
import CareerPrepBook from "./CareerPrepBook";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const CareerLayout = () => {
  const { career, item } = useParams();
  const location = useLocation();
  const [streamData, setStreamData] = useState(null); // For Streams API data
  const [subCareerData, setSubCareerData] = useState(null); // For SubCareerMapping API data
  const [loading, setLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState("About");
  const streams = location.state?.streams || [];
  const [isSticky, setIsSticky] = useState(false);
  const [originalTop, setOriginalTop] = useState(0); // Track original top position
  const subheaderRef = useRef(null);

  useEffect(() => {
    // Delay setting the original position until the component fully loads
    setTimeout(() => {
      if (subheaderRef.current) {
        setOriginalTop(subheaderRef.current.getBoundingClientRect().top + window.scrollY);
      }
    }, 100); // Adjust the delay as needed, here it’s 100ms
  
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // Toggle sticky state only when crossing original position threshold
      if (currentScrollY >= originalTop - 90 && !isSticky) {
        setIsSticky(true);
      } else if (currentScrollY < originalTop - 90 && isSticky) {
        setIsSticky(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalTop, isSticky]);


  // Fetch data when component mounts
  useEffect(() => {
    if (streams.length > 0) {
      const selectedStreamId = parseInt(item);
      const matchedStream = streams.find((stream) => stream.id === selectedStreamId);
      if (matchedStream) {
        setStreamData(matchedStream);
      }
    }
  }, [item, streams]);

  useEffect(() => {
    const fetchSubCareerData = async () => {
      try {
        const response = await axios.get(
          "https://api.collegedwarka.com/api/get/SubCareerMapping"
        );
        const selectedSubCareerId = parseInt(item);
        const matchedSubCareer = response.data.find(
          (subCareer) => subCareer.sub_career_master_id === selectedSubCareerId
        );
        setSubCareerData(matchedSubCareer);
      } catch (error) {
        console.error("Error fetching sub-career data:", error);
      } finally {
        setLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchSubCareerData();
  }, [item]);

  // Render the section based on the selected tab (About, Eligibility, etc.)
  const renderSection = () => {
    if (streamData) {
      // Render based on streamData (clicked from header)
      switch (selectedSection) {
        case "About":
          return <CareerAbout data={streamData} />;
        case "Eligibility":
          return <CareerEligibility data={streamData} />;
        case "Job Roles":
          return <CareerJobRoles data={streamData} />;
        case "Work Description":
          return <CareerWorkDescription data={streamData} />;
        case "Salary":
          return <CareerSalary data={streamData} />;
        case "Prep Books":
          return <CareerPrepBook data={streamData} />;
        default:
          return null;
      }
    } else if (subCareerData) {
      // Render based on subCareerData (fetched from SubCareerMapping API)
      switch (selectedSection) {
        case "About":
          return <CareerAbout data={subCareerData} />;
        case "Eligibility":
          return <CareerEligibility data={subCareerData} />;
        case "Job Roles":
          return <CareerJobRoles data={subCareerData} />;
        case "Work Description":
          return <CareerWorkDescription data={subCareerData} />;
        case "Salary":
          return <CareerSalary data={subCareerData} />;
        case "Prep Books":
          return <CareerPrepBook data={subCareerData} />;
        default:
          return null;
      }
    } else {
      return <p>No data available for this career.</p>;
    }
  };

  const sections = [
    "About",
    "Eligibility",
    "Job Roles",
    "Work Description",
    "Salary",
    "Prep Books",
  ];

  return (
    <div>
     <Header />
      <div className=" py-2 mt-20 relative w-full overflow-hidden">
        <img
          src={Tula}
          alt="Banner"
          className="w-full h-auto max-w-[90%] sm:max-w-[80%] md:max-w-[75%] lg:max-w-screen-lg object-cover mx-auto mt-10 rounded-[20px]"
          style={{
            maxHeight: "450px",
            boxShadow: "1px 2px 11px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
      </div>
      <h1 className="text-center text-2xl md:text-4xl font-bold mt-6 mb-12">
        {career}
      </h1>
      <div
        className="sticky top-5  z-10 h-[100px] overflow-x-auto scrollbar-hide"
 
      >
        <div className="flex flex-nowrap  space-x-4 items-center mx-auto max-w-full">
        <div
              id='subheader'
              ref={subheaderRef}
              className={` subheader ${isSticky ? 'fixed' : ''} top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header bg-white shadow-md md:justify-center`}
            >
            {sections.map((section) => (
              <div
                key={section}
                className={`relative py-2 px-2 sm:px-4 cursor-pointer font-[500] ${
                  selectedSection === section ? "text-green-500" : "text-black"
                } hover:text-green-500 text-xs sm:text-sm md:text-base lg:text-lg whitespace-nowrap`}
                onClick={() => setSelectedSection(section)}
              >
                {section}
                {selectedSection === section && (
                  <div className="absolute left-0 bottom-0 w-full h-1 bg-green-500"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-6">
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            renderSection()
          )}
        </div>
      </div>
      <div
        className="flex flex-col md:flex-row h-[168px] items-center justify-center mt-8 mb-16 space-y-4 md:space-y-0 md:space-x-4"
        style={{
          background: "linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)",
          border: "1px solid #00000040",
        }}
      >
        <p className="text-center text-sm md:text-2xl font-[500] font-dm-sans  md:mb-0">
          Planning to choose {career} as your Career
        </p>
        <button className="w-[240px] h-[49px] bg-[#75E36B] text-white font-bold rounded-[20px] flex items-center justify-center">
          Get Expert Help
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default CareerLayout;
