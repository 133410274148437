import React,{useState,useEffect} from 'react';
import axios from 'axios';
import './footer.css';
import { Link } from 'react-router-dom';
import { Base_URL } from '../../apiConfig';

const ListItem = ({ text,link }) => (
  <div className="flex items-center mb-2 cursor-pointer">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="icon">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
    {link ? (
      typeof link === "string" ? (
        <Link to={link} className="item-text ml-2">
          {text}
        </Link>
      ) : (
        <Link to={link.pathname} state={link.state} className="item-text ml-2">
          {text}
        </Link>
      )
    ) : (
      <p className="item-text ml-2">{text}</p>
    )}
  </div>
);

const FooterSection = ({ title, items }) => (
  <div className="flex flex-col p-4 min-w-[200px] flex-1">
    <div className="text-lg font-semibold mb-4">{title}</div>
    {items.map((item, index) => (
     <ListItem key={index} text={item.text} link={item.link} />

    ))}
  </div>
);

const Footer = () => {

  const [collegeItems, setCollegeItems] = useState([]);
  const [courseItems, setCourseItems] = useState([]);
  const [examItems, setExamItems] = useState([]);
  const [loading, setLoading] = useState(false);

  // const collegeItems = [
  //   { text: 'Campus Life', link: null },
  //   { text: 'Admissions', link: null },
  //   { text: 'Testimonials', link: null },
  //   { text: 'Alumni', link: null }
  // ];

  // const examItems = [
  //   { text: 'Entrance', link: null },
  //   { text: 'Schedules', link: null },
  //   { text: 'Results', link: null },
  //   { text: 'Prep Tips', link: null }
  // ];

  // const courseItems = [
  //   { text: 'UG Programs', link: null },
  //   { text: 'PG Programs', link: null },
  //   { text: 'Diploma Courses', link: null },
  //   { text: 'Online Courses', link: null }
  // ];

  const updatesItems = [
    { text: 'News', link:'/newsDetail' },
    { text: 'Events', link: null },
    { text: 'Workshop', link: null },
    { text: 'Campus', link: null }
  ];

  const moreItems = [
    // { text: 'FAQs', link: null },
    { text: 'About Us', link: '/aboutUs' },
    { text: 'Contact Us', link: '/contactUs' },
    { text: 'Privacy Policy', link: '/privacyPolicy' },
    { text: 'Terms of Service', link: '/termsAndConditions' }
  ];

  const socialLinks = [
    {
      svgPath: 'M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z',
    },
    {
      svgPath: 'M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3c94 0 111.3 61.9 111.3 142.3V448z',
    },
    {
      svgPath: 'M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z',
    },
    {
      svgPath: 'M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9S287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7s74.7 33.5 74.7 74.7s-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8c-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8s26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9s-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0c-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9c1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2s34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.6 102.7-9 132.1z',
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const fetchAllUniversities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/universities`);
    
        if (Array.isArray(response.data)) {

          const processedData = response.data.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse pictures string as JSON
          }));
          // Select the first four colleges and set the link with state
          const topColleges = processedData.slice(0, 4).map((college) => {
            const universityNameSlug = encodeURIComponent(
              college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
            );
    
            return {
              text: college.university_name,
              link: {
                pathname: `/college/${college.university_id}/${universityNameSlug}`, // Link to each college page
                state: { college }, // Pass the entire college object as state
              },
            };
          });
          setCollegeItems(topColleges);
        } else {
          console.error("Unexpected data format:", response.data);
          setCollegeItems([]);
        }
      } catch (err) {
        console.error("Failed to fetch all universities:", err);
      } finally {
        setLoading(false);
      }
    };
    
    const fetchCoursesMaster = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/course-master`);
        const data = response.data;

        // Select the first four courses and set them in courseItems
        const topCourses = data.slice(0, 4).map((course) => ({
          text: course.course_master_name, // Assuming 'name' is the course title in API response
          link: {
            pathname: `/courses/details/${course.id}/${course.course_master_name}`, // Link to each course page
            state: { courseName: course.course_master_name, courseId: course.id } // Passing id and name as state
          }, // Link to each course page
        }));
        setCourseItems(topCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchExamMaster = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/get/exam_master`);
        const data = response.data;

        // Select the first four exams and set them in examItems
        const topExams = data.slice(0, 4).map((exam) => ({
          text: exam.exam_master_name, // Assuming 'name' is the exam title in API response
          link: `/exam/${exam.id}`, // Link to each exam page
        }));
        setExamItems(topExams);
      } catch (error) {
        console.error("Error fetching exams:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExamMaster();

    fetchCoursesMaster();

    fetchAllUniversities();
  }, []);


  return (
    <footer className="w-full bg-[#002147] text-white py-8 px-4 font-dm-sans">
      <div className="footer-container  font-poppins">
        <FooterSection title="Top Colleges" items={collegeItems} />
        <FooterSection title="Popular Exams" items={examItems} />
        <FooterSection title="Popular Courses" items={courseItems} />
        <FooterSection title="Updates" items={updatesItems} />
        <FooterSection title="More" items={moreItems} />
      </div>

      <div className="footer-social">
        {socialLinks.map((link, index) => (
          <svg key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="social-icon">
            <path d={link.svgPath} />
          </svg>
        ))}
         <button className="scroll-top-btn" onClick={scrollToTop}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="scroll-top-icon absolute md:left-[450px]"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
          </svg>
          <div className='absolute md:left-[455px] top-[20px] md:top-[20px]'>
          <p className="scroll-top-text">Top</p>
          </div>
        </button>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} College Dwarka. All right reserved.</p>
       
      </div>
    </footer>
  );
};

export default Footer;
