import React, { useState, useMemo } from 'react';
import { FaSort } from 'react-icons/fa';
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';

const AllLeads = () => {
  const [leads, setLeads] = useState([
    { id: 1, name: 'John Doe', location: 'New York', degreeType: 'UG', mode: 'call', mobile: '123-456-7890', email: 'john.doe@example.com', status: 'Success' },
    { id: 2, name: 'Jane Smith', location: 'Los Angeles', degreeType: 'PG', mode: 'email', mobile: '987-654-3210', email: 'jane.smith@example.com', status: 'In Process' },
    // Add more dummy data as needed
  ]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const navigate = useNavigate();

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <IoArrowUpOutline /> : <IoArrowDownOutline />;
    }
    return <FaSort color='lightgrey' />;
  };

  const sortedLeads = useMemo(() => {
    let sortableLeads = [...leads];
    if (sortConfig.key) {
      sortableLeads.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableLeads;
  }, [leads, sortConfig]);

  const filteredLeads = useMemo(
    () => sortedLeads.filter((lead) => lead.name.toLowerCase().includes(searchTerm.toLowerCase())),
    [sortedLeads, searchTerm]
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredLeads.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredLeads.length / entriesPerPage);

  const tableCellStyle = {
    color: 'rgb(115, 123, 139)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13.125px',
    fontWeight: 400,
    lineHeight: '19.6px',
    padding: '9.1px 13.125px',
    borderBottomColor: 'rgb(230, 230, 230)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Success':
        return { backgroundColor: '#E7F5EA', color: '#27AE60', padding: '4px 8px', borderRadius: '5px' };
      case 'In Process':
        return { backgroundColor: '#FEF5E7', color: '#E67E22', padding: '4px 8px', borderRadius: '5px' };
      default:
        return {};
    }
  };

  const handleDetailsClick = (lead) => navigate(`/dashboard/leads/details/${lead.id}`, { state: { lead } });

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Leads" breadcrumb={['Lead Management', 'All Leads']} />
      <div className="bg-white p-6 rounded-lg shadow mb-4">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="text-gray-800 font-poppins text-lg font-semibold">Lead List</div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/dashboard/leads/add')}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {['name', 'location', 'degreeType', 'mode', 'mobile', 'email', 'status'].map((key, index) => (
                  <th
                    key={key}
                    className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer"
                    onClick={() => handleSort(key)}
                  >
                    <div className="flex flex-row items-center">
                      <div>{['Name', 'Location', 'Degree Type', 'Mode', 'Mobile', 'Email', 'Status'][index]}</div>
                      <div className="ml-1">{getSortIcon(key)}</div>
                    </div>
                  </th>
                ))}
                <th className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((lead) => (
                  <tr key={lead.id} className="hover:bg-gray-50 border-b">
                    <td style={tableCellStyle}>{lead.name}</td>
                    <td style={tableCellStyle}>{lead.location}</td>
                    <td style={tableCellStyle}>{lead.degreeType}</td>
                    <td style={tableCellStyle}>{lead.mode}</td>
                    <td style={tableCellStyle}>{lead.mobile}</td>
                    <td style={tableCellStyle}>{lead.email}</td>
                    <td style={{ ...tableCellStyle }}>
                      <span style={{ ...getStatusStyle(lead.status) }}>{lead.status}</span>
                    </td>
                    <td style={tableCellStyle}>
                      <div className="underline text-cmsgreen hover:text-hovergreen cursor-pointer" onClick={() => handleDetailsClick(lead)}>
                        View Details
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center py-4">
                    No leads found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllLeads;

