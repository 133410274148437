import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

export const Img_URL = 'https://api.collegedwarka.com/api/files';

const AddGallery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    mainTitle = "Add Gallery",
    subTitle = "Upload images to the gallery",
  } = location.state || {};

  const [images, setImages] = useState([]); // Array to store uploaded images
  const [selectedImages, setSelectedImages] = useState(new Set()); // Set to track selected images
  const [universities, setUniversities] = useState([]); // Array to store university options
  const [selectedUniversity, setSelectedUniversity] = useState(null); // Selected university
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch universities for the dropdown
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error("Failed to fetch universities:", error);
        toast.error("Failed to load university options.");
      }
    };

    fetchUniversities();
  }, []);

  // Fetch gallery images for the selected university
  const fetchGalleryImages = async (universityId) => {
    try {
      const response = await fetch(`${Base_URL}/gallery/university/${universityId}`);
      const data = await response.json();
      
      // Extract the gallery_file_name and append it to the Img_URL
      const images = data.map(item => ({
        id: item.id,
        src: `${Img_URL}${item.gallery_file_name.replace("/var/www/zanko/uploads/", "/")}`
      }));
      console.log(images);
      setImages(images);
    } catch (error) {
      console.error('Error fetching gallery images:', error);
    }
  };

  // Handle university selection
  const handleUniversityChange = (selectedOption) => {
    setSelectedUniversity(selectedOption);
    if (selectedOption) {
      fetchGalleryImages(selectedOption.value);
    } else {
      setImages([]); // Clear images if no university is selected
    }
  };

  // Handle image selection and upload
  const handleImageSelect = async (e) => {
    const files = Array.from(e.target.files);
    const selectedImages = files.map((file) => ({
      id: Date.now() + Math.random(), // Unique ID for each image
      src: URL.createObjectURL(file),
      file,
    }));
    setImages((prevImages) => [...prevImages, ...selectedImages]);
    e.target.value = ""; // Reset the file input

    if (!selectedUniversity) {
      toast.error("Please select a university before uploading images.");
      return;
    }

    try {
      const dataToSubmit = new FormData();
      dataToSubmit.append("university_id", selectedUniversity.value);
      files.forEach((file) => {
        dataToSubmit.append("picture", file);
      });

      await axios.post(`${Base_URL}/gallery`, dataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success("Images uploaded successfully!");
    } catch (error) {
      toast.error("Failed to upload images. Please try again.");
    }
  };

  // Toggle image selection
  const toggleImageSelect = (id) => {
    setSelectedImages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  // Delete selected images
  // const deleteSelectedImages = () => {
  //   setImages((prevImages) => prevImages.filter((image) => !selectedImages.has(image.id)));
  //   setSelectedImages(new Set()); // Clear selected images
  //   toast.success("Selected images deleted.");
  // };

  // Delete selected images
const deleteSelectedImages = async () => {
  // Extract IDs of selected images
  const idsToDelete = Array.from(selectedImages);

  if (idsToDelete.length === 0) {
    toast.error("No images selected for deletion.");
    return;
  }

  try {
    // Make a Put request to soft delete selected images
    await axios.put(`${Base_URL}/gallery/delete`, { id: idsToDelete },{
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token}` // Add token to authorization header
      },
      withCredentials: true // Enable withCredentials
    });

    // Filter out deleted images from the state
    setImages((prevImages) => prevImages.filter((image) => !selectedImages.has(image.id)));

    // Clear selected images
    setSelectedImages(new Set());
    toast.success("Selected images deleted successfully.");
  } catch (error) {
    console.error("Failed to delete selected images:", error);
    toast.error("Failed to delete selected images. Please try again.");
  }
};


  return (
    <div className='bg-gray-100 p-6 min-h-screen'>
      <ToastContainer />

      {/* Main Title and Subtitle Section */}
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

      {/* University Selection */}
      <div className='mb-4'>
        <Select
          options={universities}
          value={selectedUniversity}
          onChange={handleUniversityChange}
          placeholder='Select University'
          className='basic-single'
          classNamePrefix='select'
        />
      </div>

      {/* Header Section for "Add New" and "Delete" buttons */}
      <div className='flex justify-between items-center mb-4 bg-white p-4 rounded-md shadow-md'>
        <label className='bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer'>
          Add New
          <input
            type='file'
            multiple
            onChange={handleImageSelect}
            className='hidden'
          />
        </label>

        {/* Show Delete Button only if an image is selected */}
        {selectedImages.size > 0 && (
          <button
            onClick={deleteSelectedImages}
            className='bg-red-500 text-white px-4 py-2 rounded-md'
          >
            Delete
          </button>
        )}
      </div>

      {/* Gallery Display */}
      <div className='bg-white p-4 rounded-md shadow-md'>
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4'>
          {images.map((image) => (
            <div
              key={image.id}
              onClick={() => toggleImageSelect(image.id)}
              className={`relative overflow-hidden rounded-lg cursor-pointer border-2 ${
                selectedImages.has(image.id) ? "border-blue-500" : "border-gray-200"
              }`}
            >
              <img
                src={image.src}
                alt='Uploaded Preview'
                className='object-cover w-full h-full'
              />
              {selectedImages.has(image.id) && (
                <div className='absolute inset-0 bg-blue-500 bg-opacity-30 flex items-center justify-center'>
                  <p className='text-white font-semibold'>Selected</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddGallery;
