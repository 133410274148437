// import React, { useState } from 'react';
// import college_logo from '../../../assets/college_logo.png';
// import './qa.css';
// import QACard from './QACard/QACard';

// const QA = ({collegeDetails,logoPath}) => {
//     const [toggleTopic, setToggleTopic] = useState(1);

//     // Dummy questions for different topics
//     const questions = {
//         topic1: [
//             { question: "What is the process for admission to LPU?", answer: "Admission to LPU can be done online through the LPU website. Students need to register, fill out the application form, and upload the necessary documents. Entrance exams and interviews may be required for certain programs." },
//             { question: "What are the hostel facilities available at LPU?", answer: "LPU offers separate hostels for boys and girls with options for air-conditioned and non-air-conditioned rooms. Facilities include Wi-Fi, laundry, mess, and a common room." },
//             { question: "How is the placement record of LPU?", answer: "LPU has a strong placement record with top companies like Google, Amazon, and Microsoft recruiting students. The highest package offered last year was 3 crores per annum." },
//             { question: "Is there a scholarship program at LPU?", answer: "Yes, LPU offers various scholarships based on academic performance, entrance exam scores, sports achievements, and financial need." },
//             { question: "What are the popular courses at LPU?", answer: "Popular courses at LPU include B.Tech, MBA, BBA, and M.Sc. in various specializations." },
//         ],
//         topic2: [
//             { question: "What is LPUNEST and how do I apply for it?", answer: "LPUNEST is an entrance exam conducted by LPU for admission to various programs. You can apply for it online through the LPU website by filling out the application form and paying the required fee." },
//             { question: "How can I get a scholarship through LPUNEST?", answer: "Scholarships are awarded based on LPUNEST scores. Higher scores can lead to more significant scholarship amounts, potentially covering up to 100% of tuition fees." },
//             { question: "What is the syllabus for LPUNEST?", answer: "The syllabus for LPUNEST varies by program but generally includes subjects like Physics, Chemistry, Mathematics, and English for undergraduate courses. The exact syllabus can be found on the LPU website." },
//             { question: "What is the eligibility criteria for LPUNEST?", answer: "Eligibility criteria for LPUNEST vary by course. For most undergraduate programs, candidates must have completed their 10+2 with a minimum percentage as specified by the university." },
//             { question: "How can I prepare for LPUNEST?", answer: "Preparation for LPUNEST involves studying the relevant subjects, practicing past papers, and taking mock tests. LPU also provides study material and sample papers on their website." },
//         ],
//         topic3: [
//             { question: "What extracurricular activities are available at LPU?", answer: "LPU offers a wide range of extracurricular activities, including sports, cultural events, clubs, and societies. Students can participate in events like the Youth Vibe fest and other national-level competitions." },
//             { question: "Are there international exchange programs at LPU?", answer: "Yes, LPU has collaborations with several international universities for exchange programs, allowing students to study abroad for a semester or a year." },
//             { question: "How is the campus life at LPU?", answer: "Campus life at LPU is vibrant and diverse, with students from all over India and different parts of the world. The campus has modern facilities, including a shopping mall, gym, and numerous cafes and eateries." },
//             { question: "What kind of support services are available for students?", answer: "LPU provides various support services, including counseling, academic advising, career services, and health and wellness facilities." },
//             { question: "How does LPU promote innovation and entrepreneurship?", answer: "LPU promotes innovation and entrepreneurship through its incubation center, where students can develop and launch their startups. The university also organizes hackathons, startup competitions, and workshops on entrepreneurship." },
//         ]
//     };

//     return (
//         <div className="px-5 py-8 bg-[#DCFFC4]">
//             <div className="text-black text-base font-medium flex flex-col gap-5 mb-10">
//                 <div className="flex items-center justify-center gap-3 mb-5">
//                     <div className="md:h-[80px] md:w-[80px] h-[40px] w-[40px]"><img src={logoPath} alt="college-logo" className="h-full w-full object-cover" /></div>
//                     <p className="md:text-3xl text-black md:font-semibold text-[20px] font-[700]"> {collegeDetails.university_name}</p>

//                 </div>
//                 <div className="flex items-center justify-center gap-3 mb-5">
//                 <p className='font-bold text-xl'>Questions Asked On {collegeDetails.university_name}.</p>
//                 </div>
//             </div>

//             <div className="py-8">
//                 <div className='py-3 flex flex-wrap justify-center gap-8'>
//                     <button className={`${toggleTopic === 1 ? 'qa_topic_bttn_active' : ''} qa_topic_bttn`} onClick={() => setToggleTopic(1)}>General Information</button>
//                     <button className={`${toggleTopic === 2 ? 'qa_topic_bttn_active' : ''} qa_topic_bttn`} onClick={() => setToggleTopic(2)}>LPUNEST</button>
//                     <button className={`${toggleTopic === 3 ? 'qa_topic_bttn_active' : ''} qa_topic_bttn`} onClick={() => setToggleTopic(3)}>Campus Life</button>
//                 </div>

//                 <div className="flex justify-center py-5">
//                     <div className="px-10 bg-white w-[700px] rounded-xl pt-10 pb-5">
//                         {toggleTopic === 1 && questions.topic1.map((q, index) => <QACard key={index} question={q.question} answer={q.answer} />)}
//                         {toggleTopic === 2 && questions.topic2.map((q, index) => <QACard key={index} question={q.question} answer={q.answer} />)}
//                         {toggleTopic === 3 && questions.topic3.map((q, index) => <QACard key={index} question={q.question} answer={q.answer} />)}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default QA;
import React, { useState, useEffect } from 'react';
import './qa.css';
import QACard from './QACard/QACard';
import axios from 'axios';

const QA = ({ collegeDetails, logoPath }) => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        // Fetch all FAQs
        axios.get(`https://api.collegedwarka.com/api/faq`)
            .then(response => {
                // Filter FAQs based on the current university_id
                const filteredQuestions = response.data.filter(
                    faq => faq.university_id === collegeDetails.university_id
                );
                setQuestions(filteredQuestions);
            })
            .catch(error => {
                console.error('Error fetching FAQ data:', error);
            });
    }, [collegeDetails.university_id]);

    return (
        <div className="px-5 py-8 bg-[#DCFFC4]">
            <div className="text-black text-base font-medium flex flex-col gap-5 mb-10">
                <div className="flex items-center justify-center gap-3 mb-5">
                    <div className="md:h-[80px] md:w-[80px] h-[40px] w-[40px]">
                        <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="md:text-3xl text-black md:font-semibold text-[20px] font-[700]">{collegeDetails.university_name}</p>
                </div>
                <div className="flex items-center justify-center gap-3 mb-5">
                    <p className='font-bold text-xl'>Questions Asked On {collegeDetails.university_name}.</p>
                </div>
            </div>

            <div className="flex justify-center py-5">
                <div className="px-10 bg-white w-[700px] rounded-xl pt-10 pb-5">
                    {questions.length > 0 ? (
                        questions.map((q, index) => (
                            <QACard
                                key={index}
                                number={index + 1}
                                question={q.question}
                                answer={q.answer}
                            />
                        ))
                    ) : (
                        <p className="text-center text-gray-600">No questions available for this university.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QA;
