import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux";

const AddCourses = () => {
  const [formData, setFormData] = useState({
    course_fees: "",
    course_duration: "",
    course_exams_accepted: [],
    eligibility: "",
    available_seats: "",
    course_details: "",
  });

  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-values-array/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university[0],
          label: university[1],
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error("Failed to load universities");
      }
    };
    fetchUniversities();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-values-array/course_master`);
        const programOptions = response.data.map((program) => ({
          value: program[0],
          label: program[1],
        }));
        setPrograms(programOptions);
      } catch (error) {
        toast.error("Failed to load programs");
      }
    };
    fetchCourses();
  }, [selectedUniversity]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !formData.course_fees ||
      !formData.course_duration ||
      !selectedUniversity ||
      !selectedProgram ||
      !formData.eligibility ||
      !formData.available_seats
    ) {
      toast.error("Please fill all the required fields");
      setLoading(false);
      return;
    }

    const dataToSubmit = {
      ...formData,
      university_id: selectedUniversity.value,
      course_master_id: selectedProgram.value,
      course_exams_accepted: formData.course_exams_accepted
        .split(",")
        .map((exam) => exam.trim()),
    };

    try {
      await axios.post(`${Base_URL}/courses`, dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`  // Add token authorization
      },
      withCredentials: true  // Enable withCredentials for the request
      });

      toast.success("Course created successfully!");
      setFormData({
        course_fees: "",
        course_duration: "",
        eligibility: "",
        available_seats: "",
        course_details: "",
      });
      setSelectedUniversity(null);
      setSelectedProgram(null);
    } catch (error) {
      toast.error("Failed to create course. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Course</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">
                Select University
              </label>
              <Select
                options={universities}
                value={selectedUniversity}
                onChange={(selectedOption) => setSelectedUniversity(selectedOption)}
                placeholder="Select a university"
                className="basic-single"
                classNamePrefix="select"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="program_id" className="mb-2 font-medium text-sm">
                Select Course
              </label>
              <Select
                options={programs}
                value={selectedProgram}
                onChange={(selectedOption) => setSelectedProgram(selectedOption)}
                placeholder="Select a Course"
                className="basic-single"
                classNamePrefix="select"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="course_fees" className="mb-2 font-medium text-sm">
                Course Fees
              </label>
              <input
                type="text"
                id="course_fees"
                name="course_fees"
                value={formData.course_fees}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Course Fees"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="course_duration" className="mb-2 font-medium text-sm">
                Course Duration
              </label>
              <input
                type="text"
                id="course_duration"
                name="course_duration"
                value={formData.course_duration}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Course Duration"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">
                Eligibility
              </label>
              <input
                type="text"
                id="eligibility"
                name="eligibility"
                value={formData.eligibility}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Eligibility"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="exams_accepted" className="mb-2 font-medium text-sm">
                Exams Accepted
              </label>
              <textarea
                id="exams_accepted"
                name="course_exams_accepted"
                value={formData.course_exams_accepted}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="available_seats" className="mb-2 font-medium text-sm">
                Available Seats
              </label>
              <input
                type="text"
                id="available_seats"
                name="available_seats"
                value={formData.available_seats}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Available Seats"
                required
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="course_details" className="mb-2 font-medium text-sm">
                Course Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.course_details}
                onChange={(event, editor) =>
                  setFormData((prev) => ({
                    ...prev,
                    course_details: editor.getData(),
                  }))
                }
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              "Add University Course"
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddCourses;
