import React, { useState } from "react";
import axios from "axios";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";


const DropDownNews = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    sub_content: "",
    trending_news: "", // New trending news field
  });
  const [picture, setPicture] = useState(null);
  const [news, setNews] = useState([]);
  const [imageName, setImageName] = useState("No file chosen");
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const { registration_id, token,name } = useSelector((state) => state.auth);
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTrendingChange = (e) => {
    setFormData({ ...formData, trending_news: e.target.value });
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    } else {
      setImageName("No file chosen");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append("picture", image);
      }

      const response = await axios.post(
        `${Base_URL}/create-upload/news_announcements`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
             'authorization': `Bearer ${token}`
          },
          withCredentials: true // This allows cookies to be sent with the request
        }
      );

      console.log("News added successfully:", response.data);
      toast.success("News added successfully!");
      setFormData({
        title: "",
        content: "",
        sub_content: "",
        trending_news: "", // Clear trending news after submission
      });
      setImage(null);
      setImageName("No file chosen");
      setError(null);
    } catch (error) {
      console.error(
        "Failed to add news:",
        error.response?.data || error.message
      );
      toast.error("Failed to add news. Please try again.");
    }
  };

  return (
    <div className='bg-gray-100 p-6 rounded-lg  min-h-screen shadow-md'>
      <HeaderTitle mainTitle='News And Announcements' subTitle='Add News' />
      <div className='border border-gray-300 bg-white p-4 rounded-md'>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='title'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Title
              </label>
              <input
                type='text'
                id='title'
                name='title'
                value={formData.title}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='content'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Content
              </label>
              <textarea
                id='content'
                name='content'
                value={formData.content}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='sub_content'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Sub Content
              </label>
              <textarea
                id='sub_content'
                name='sub_content'
                value={formData.sub_content}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>

            {/* Trending News Dropdown */}
            <div className='flex flex-col'>
              <label
                htmlFor='trending_news'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Trending News
              </label>
              <select
                id='trending_news'
                name='trending_news'
                value={formData.trending_news}
                onChange={handleTrendingChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              >
                <option value=''>Select an option</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='picture'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Upload Picture
              </label>
              <div className='flex border border-gray-300 rounded-md mt-2 overflow-hidden'>
                <label className='flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]'>
                  <span>Choose File</span>
                  <input
                    type='file'
                    id='picture'
                    name='picture'
                    onChange={handlePictureChange}
                    className='hidden'
                  />
                </label>
                <div className='flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]'>
                  {imageName || "No file chosen"}
                </div>
              </div>
            </div>
          </div>
          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-customblue text-white rounded-md hover:bg-hoverblue'
          >
            Submit
          </button>
          {error && <div className='text-red-600 mt-2'>{error}</div>}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default DropDownNews;
