import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import axios from "axios";
import Modal from "react-modal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/landing/Logo_Green.svg";
import Authentication from "../Authentication/Authentication";
import "../Authentication/Authentication.css";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../src/apiConfig";
import LatestUpdates from "../../pages/LatestUpdates";
import { useSelector } from "react-redux";
import { AiOutlineUser } from 'react-icons/ai'; // Profile icon
import { MdDashboard, MdLogout } from 'react-icons/md'; // Dashboard and Logout icons

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isWhiteBackground, setIsWhiteBackground] = useState(false);
  const [streams, setStreams] = useState([]);
  const navRef = useRef();
  const toggleRef = useRef();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const timeoutRef = useRef(null);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const toggleNav = () => setIsNavOpen((prev) => !prev);
  const closeNav = () => setIsNavOpen(false);
  const [collegesDropdownOpen, setCollegesDropdownOpen] = useState(false);
  const [streamsList, setStreamsList] = useState([]);
  const [selectedStreamId, setSelectedStreamId] = useState(null);
  const [selectedStreamName, setSelectedStreamName] = useState("");
  const [programList, setProgramList] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [allPrograms, setAllPrograms] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [popularColleges, setPopularColleges] = useState([]);
  const [featuredColleges, setFeaturedColleges] = useState([]);
  const [careerDropdownOpen, setCareerDropdownOpen] = useState(false);
  const [careerOptions, setCareerOptions] = useState([]);
  const [examDropdownOpen, setExamDropdownOpen] = useState(false);
  const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
  const [updatesDropdownOpen, setUpdatesDropdownOpen] = useState(false);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [selectedCourseStreamId, setSelectedCourseStreamId] = useState(null);
  const [selectedCourseStreamName, setSelectedCourseStreamName] = useState("");
  const [examList, setExamList] = useState([]);
  const [selectedExamStreamId, setSelectedExamStreamId] = useState(null);
  const [selectedExamStreamName, setSelectedExamStreamName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // Add dropdown state
  const profileDropdownRef = useRef(null); // Reference for the dropdown

  const { registration_id ,reg_type,name } = useSelector((state) => state.auth)

  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCollegesDropdownOpen(false);
      }
    };
    // const fetchStreams = async () => {
    //   setLoading(true);
    //   try {
    //     // const response = await axios.get(`${Base_URL}/get/placement_admin`);
    //     const response = await axios.get(`${Base_URL}/get/streams`);
    //     setStreamsList(response.data);
    //     console.log("streams",response.data)
    //   } catch (err) {
    //     console.log('Failed to fetch streams');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchStreams()

    // const fetchPrograms =async()=>{
    //   try {

    //     // Make an API call to fetch programs (replace with your API endpoint)
    //     const response = await axios.get(`${Base_URL}/get/program_master`);
    //     const data = await response.data;

    //     // Filter programs based on the selected streamId
    //     const filteredPrograms = data.filter(program => program.stream_id === selectedStreamId);

    //     // Update the state with filtered programs
    //     setAllPrograms(filteredPrograms);
    // } catch (error) {
    //     console.error("Error fetching programs:", error);
    // }
    // }

    // fetchPrograms()
    const fetchStreams = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/get/streams`);
        const data = response.data;
        setStreamsList(data);

        // Set the initial selected stream ID and fetch programs based on it
        if (data.length > 0) {
          const initialStreamId = data[0].id;
          setSelectedStreamId(initialStreamId);
          fetchPrograms(initialStreamId);
          fetchCoursesMaster(initialStreamId);
          fetchExamMaster(initialStreamId);
        }
      } catch (err) {
        console.log("Failed to fetch streams");
      } finally {
        setLoading(false);
      }
    };

    const fetchPrograms = async (streamId) => {
      try {
        const response = await axios.get(`${Base_URL}/get/program_master`);
        const data = response.data;

        // Filter programs based on the selected streamId
        const filteredPrograms = data.filter(
          (program) => program.stream_id === streamId
        );
        console.log("programs", data);
        // Update the state with filtered programs
        setAllPrograms(filteredPrograms);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    const fetchExamMaster = async (streamId) => {
      try {
        const response = await axios.post(
          `${Base_URL}/get-with-joins/stream_exam_master_mapping`,
          {
            joins: [
              {
                joinTable: "exam_master",
                joinCondition:
                  "stream_exam_master_mapping.exam_master_id=exam_master.id",
              },
            ],
          }
        );
        console.log("exams", response.data);
        const data = await response.data;

        // Filter courses based on the selected streamId
        const filteredExams = data.filter(
          (exams) => exams.stream_id === streamId
        );
        console.log("clickid", streamId);
        console.log("clickiiii", filteredExams);
        // Update the state with filtered programs
        setExamList(filteredExams);
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };

    fetchStreams();

    const fetchCities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/city`);
        console.log("Raw cities API response:", response.data);

        if (Array.isArray(response.data)) {
          // Add strict validation when setting cities
          const validCities = response.data.filter(
            (city) =>
              city &&
              typeof city === "object" &&
              city.id &&
              city.name &&
              typeof city.name === "string" &&
              city.name.trim() !== ""
          );

          console.log("Validated cities:", validCities);
          setCitiesList(validCities);
        } else {
          console.error("Invalid cities data format:", response.data);
          setCitiesList([]);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCitiesList([]);
      }
    };
    fetchCities();

    const fetchCoursesMaster = async (streamId) => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/course-master`);
        console.log("courses data", response.data);
        const data = await response.data;

        // Filter courses based on the selected streamId
        const filteredCourses = data.filter(
          (course) => course.stream_id === streamId
        );
        console.log("clickid", streamId);
        console.log("clickiiii", filteredCourses);
        // Update the state with filtered programs
        setCoursesList(filteredCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    const fetchPopularUniversities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_URL}/universities/popular/colleges`
        );

        console.log("popular universities data", response.data);

        if (Array.isArray(response.data)) {
          const processedData = response.data.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse pictures string as JSON
          }));
          setPopularColleges(processedData);
        } else {
          console.error("Unexpected data format:", response.data);
          setPopularColleges([]); // Set fallback if not an array
        }
      } catch (err) {
        console.error("Failed to fetch all universities:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchPopularUniversities();

    const fetchFeaturedUniversities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_URL}/universities/featured/colleges`
        );

        console.log("featured universities data", response.data);

        if (Array.isArray(response.data)) {
          const processedData = response.data.map((item) => ({
            ...item,
            pictures: JSON.parse(item.pictures), // Parse pictures string as JSON
          }));
          setFeaturedColleges(processedData);
        } else {
          console.error("Unexpected data format:", response.data);
          setFeaturedColleges([]); // Set fallback if not an array
        }
      } catch (err) {
        console.error("Failed to fetch all universities:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchFeaturedUniversities();
    //   const fetchPrograms = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axios.get(`${Base_URL}/get/program_master`);
    //         setAllPrograms(response.data);
    //     } catch (err) {
    //         toast.error('Failed to fetch programs');
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // fetchPrograms();

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //   useEffect(() => {
  //     if (selectedStreamId) {
  //         // Filter programs based on the selectedStreamId
  //         const filteredPrograms = allPrograms.filter(
  //             (program) => program.stream_id === selectedStreamId
  //         );
  //         setAllPrograms(filteredPrograms);
  //     } else {
  //         setAllPrograms([]);
  //     }
  // }, [selectedStreamId, allPrograms]);

  const handleStreamClick = async (streamId, streamName) => {
    try {
      setSelectedStreamId(streamId);
      setSelectedStreamName(streamName);
      console.log(streamName);

      // Make an API call to fetch programs (replace with your API endpoint)
      const response = await axios.get(`${Base_URL}/get/program_master`);
      const data = await response.data;

      // Filter programs based on the selected streamId
      const filteredPrograms = data.filter(
        (program) => program.stream_id === streamId
      );
      console.log("clickid", streamId);
      console.log("clickiiii", filteredPrograms);
      // Update the state with filtered programs
      setAllPrograms(filteredPrograms);
    } catch (error) {
      console.error("Error fetching programs:", error);
    }
  };

  const handleCourseStreamClick = async (streamId, streamName) => {
    try {
      setSelectedCourseStreamId(streamId);
      setSelectedCourseStreamName(streamName);
      console.log(streamName);

      const response = await axios.get(`${Base_URL}/course-master`);
      console.log("courses data", response.data);
      const data = await response.data;

      // Filter courses based on the selected streamId
      const filteredCourses = data.filter(
        (course) => course.stream_id === streamId
      );
      console.log("clickid", streamId);
      console.log("clickiiii", filteredCourses);
      // Update the state with filtered programs
      setCoursesList(filteredCourses);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handleExamStreamClick = async (streamId, streamName) => {
    try {
      setSelectedExamStreamId(streamId);
      setSelectedExamStreamName(streamName);
      console.log(streamName);

      const response = await axios.post(
        `${Base_URL}/get-with-joins/stream_exam_master_mapping`,
        {
          joins: [
            {
              joinTable: "exam_master",
              joinCondition:
                "stream_exam_master_mapping.exam_master_id=exam_master.id",
            },
          ],
        }
      );
      console.log("exams", response.data);
      const data = await response.data;

      // Filter courses based on the selected streamId
      const filteredExams = data.filter(
        (exams) => exams.stream_id === streamId
      );
      console.log("clickid", streamId);
      console.log("clickiiii", filteredExams);
      // Update the state with filtered programs
      setExamList(filteredExams);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };
  const handleProgramClick = (programId, programName) => {
    // Create SEO-friendly program name for URL
    const programSlug = programName.toLowerCase().replace(/\s+/g, "-");

    // Navigate to the program URL with state
    navigate(`/${programSlug}-colleges-in-india`, {
      state: {
        selectedProgram: programId,
        programName: programName,
        fromHeader: true,
        fromProgram:true
      },
    });
  };

  const handleCityClick = (cityId, type, cityName) => {
    // Add null checks and default values
    if (!cityId || !cityName) {
      console.error("Missing required parameters:", { cityId, type, cityName });
      return;
    }

    try {
      // Create SEO-friendly city name for URL
      const citySlug = cityName.toLowerCase().replace(/\s+/g, "-");

      // Navigate to the city URL with state
      navigate(`/colleges-in-${citySlug}`, {
        state: {
          selectedCity: cityId,
          cityName: cityName,
          fromHeader: true,
          shouldCheckFilter: true,
        },
      });
    } catch (error) {
      console.error("Error in handleCityClick:", error);
    }
  };

  const handleExploreClick = (college) => {
    const universityNameSlug = encodeURIComponent(
      college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/college/${college.university_id}/${universityNameSlug}`, {
      state: { college },
    });
  };

  const dispatch = useDispatch(); // Initialize dispatch

  const handleLogout = () => {
    setDropdownOpen(false); // Close the dropdown
    dispatch(logout()); // Dispatch the logout action
    console.log("handle logout");
  };

  const toggleBackgroundColor = () => {
    setIsWhiteBackground(!isWhiteBackground);
  };
  useEffect(() => {
    axios
      .get(`${Base_URL}/get/streams`)
      .then((response) => {
        setStreams(response.data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  const handleCourseClick = (id, streamName) => {
    // Navigate to the CareerLayout component with career and item as URL params
    navigate(`/career/${streamName}/${id}`, { state: { streams } });
  };

  const handleMouseEnter = (item) => {
    clearTimeout(timeoutRef.current);
    setCollegesDropdownOpen(item === "Colleges");
    setCareerDropdownOpen(item === "Careers");
    setExamDropdownOpen(item === "Exam");
    setCoursesDropdownOpen(item === "Courses");
    setUpdatesDropdownOpen(item === "Latest Updates");
    setMoreDropdownOpen(item === "More");
    // setDropdownOpen(true)
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setCollegesDropdownOpen(false);
      setCareerDropdownOpen(false);
      setExamDropdownOpen(false);
      setCoursesDropdownOpen(false);
      setUpdatesDropdownOpen(false);
      setMoreDropdownOpen(false);
      // setDropdownOpen(false)
    }, 1000); // 1 second delay before closing
  };

  // Add this mapping for exam tabs
  const examTabMapping = {
    Overview: 1,
    Eligibility: 2,
    "Application Form": 3,
    "Exam Pattern": 4,
    Syllabus: 5,
    "How To Prepare": 6,
    "Previous Year Papers": 7,
    "Admit Card": 8,
    Result: 9,
    "Counselling Process": 10,
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Close dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStreamHover = async (streamId, streamName) => {
    try {
      setSelectedStreamId(streamId);
      setSelectedStreamName(streamName);

      // Fetch data for the specific stream
      await Promise.all([
        fetchPrograms(streamId), // Fetch programs for the stream
        fetchPopularUniversities(streamId), // Fetch popular universities for the stream
        fetchFeaturedUniversities(streamId), // Fetch featured universities for the stream
      ]);
    } catch (error) {
      console.error("Error fetching data for stream:", error);
    }
  };

  const fetchPrograms = async (streamId) => {
    try {
      const response = await axios.get(`${Base_URL}/get/program_master`);
      const data = response.data;
      const filteredPrograms = data.filter(
        (program) => program.stream_id === streamId
      );
      setAllPrograms(filteredPrograms); // Update programs state with the filtered list
    } catch (error) {
      console.error("Error fetching programs:", error);
    }
  };

  const fetchPopularUniversities = async (streamId) => {
    try {
      const response = await axios.get(
        `${Base_URL}/universities/popular/colleges`,
        {
          params: { stream_id: streamId },
        }
      );
      const processedData = response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures),
      }));
      setPopularColleges(processedData);
    } catch (error) {
      console.error("Error fetching popular universities:", error);
    }
  };

  const fetchFeaturedUniversities = async (streamId) => {
    try {
      const response = await axios.get(
        `${Base_URL}/universities/featured/colleges`,
        {
          params: { stream_id: streamId },
        }
      );
      const processedData = response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures),
      }));
      setFeaturedColleges(processedData);
    } catch (error) {
      console.error("Error fetching featured universities:", error);
    }
  };

  return (
    <header
      className={`header_main ${isScrolled ? "scrolled" : ""}`}
      style={{
        backgroundColor: isScrolled ? "white" : "transparent",
        color: isScrolled ? "#000" : "#fff",
        boxShadow: isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <div className='container mx-auto flex items-center justify-between p-4'>
        {/* Logo */}
        <div>
          <Link to="/">
            <Logo className='w-24 h-24 md:w-32 md:h-32 lg:w-36 lg:h-36' />
          </Link>
        </div>

        {/* Toggle Button for Mobile Navigation */}
        <button
          ref={toggleRef}
          className={`md:hidden p-2 rounded-md focus:outline-none ${
            isScrolled
              ? "text-customgreen bg-white"
              : "text-white bg-customgreen"
          }`}
          onClick={toggleNav}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M4 6h16M4 12h16m-7 6h7'
            />
          </svg>
        </button>

        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel='Authentication Modal'
          className='modal'
          overlayClassName='overlay'
        >
          <Authentication />
        </Modal>

        {/* Desktop Navigation and Profile */}
        <div className='hidden md:flex items-center flex-wrap gap-6 md:gap-8'>
          {/* <div className="flex flex-wrap gap-4 md:gap-8 items-center">
            {['Colleges', 'Exam', 'Courses', 'Careers', 'Latest Updates', 'More'].map((item, index) => (
              <div
                key={index}
                className={`flex items-center gap-1 cursor-pointer text-base header_link ${isScrolled ? 'text-black' : 'text-white'}`}
              >
                <p>{item}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke={isScrolled ? '#4caf50' : 'currentColor'}
                  className="w-4 h-4 font-bold"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
            ))}
          </div> */}
          <div className='flex flex-wrap gap-4 md:gap-8 items-center'>
            {[
              "Colleges",
              "Exam",
              "Courses",
              "Careers",
              "Latest Updates",
              "More",
            ].map((item) => (
              <div
                key={item}
                className='relative group flex items-center gap-1 cursor-pointer text-base header_link'
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={handleMouseLeave}
              >
                <p
                  className={
                    isHomePage && !isScrolled ? "text-white" : "text-black"
                  }
                >
                  {item}
                </p>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke={
                    isHomePage && !isScrolled ? "currentColor" : "#4caf50"
                  }
                  className='w-4 h-4 font-bold'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m19.5 8.25-7.5 7.5-7.5-7.5'
                  />
                </svg>

                {/* Dropdown Menu */}
                {/* <div className="fixed hidden group-hover:flex flex-col
         md:w-[987px] md:h-[753px]  
         scrollbar-hide top-[70px] left-1/2 transform -translate-x-1/2 bg-white border-[1px] 
         border-transparent border-t-[#FFFFFF40] rounded-2xl shadow-lg 
         group-hover:opacity-100 transition-opacity duration-300 ease-in-out z-50"> */}

                {item === "Colleges" && collegesDropdownOpen && (
                  <div
                    className={`fixed ${
                      collegesDropdownOpen ? "flex" : "hidden"
                    } flex-col md:w-[800px] lg:w-[1087px] 
                  max-h-[calc(100vh-10%)] overflow-y-auto top-[70px] left-1/2 transform -translate-x-1/2 bg-white 
                  rounded-2xl shadow-lg  border-4 border-gray-200
                  transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
                    // style={{ border: '10px solid rgba(255, 255, 255, 0)' }}
                  >
                    <div className='w-full h-full rounded-2xl custom-gradient  flex'>
                      <div className='flex flex-col p-2  md:w-1/4 lg:w-1/5 xl:w-1/6 font-medium md:text-xs lg:text-sm overflow-y-auto max-h-[calc(100vh-10%)] scrollbar-hide text-black pt-2.5 pb-2.5 pr-1.25 pl-1.25 gap-2.5 bg-[#F2FCEB] rounded-2xl flex-shrink-0'>
                        {streamsList.map((stream) => (
                          <div
                            key={stream.id}
                            onMouseEnter={() => {
                              handleStreamHover(stream.id, stream.stream_name); // Update data on hover
                            }}
                            className={`pt-2.5 px-2 mx-2.5 cursor-pointer uppercase ${
                              selectedStreamId === stream.id
                                ? "bg-[#AEFF75] rounded-[10px] p-2 "
                                : ""
                            }`}
                          >
                            {stream.stream_name}
                          </div>
                        ))}
                      </div>
                      <div className='flex-1 p-3 overflow-y-auto [calc(100vh-10%)] scrollbar-hide'>
                        <div className='ml-[60px]'>
                          <div className='grid  grid-cols-3 gap-4 auto-rows-auto'>
                            {/* Top Colleges Section First */}
                            <div className='text-black font-normal text-sm gap-2.5 w-full mt-[18px] '>
                              <div className='pt-2.5 ml-2.5 text-base font-medium'>
                                Top Colleges
                              </div>
                              {featuredColleges?.slice(0, 5).map((clg) => (
                                <div
                                  key={clg.university_id}
                                  onClick={() => handleExploreClick(clg)}
                                  className='pt-4 ml-2.5 cursor-pointer text-lightTextColor'
                                >
                                  {clg.university_name}
                                </div>
                              ))}
                              <div
                                className='pt-5 ml-2.5 text-[#468EFF]'
                                onClick={() => navigate("/colleges")}
                              >
                                View all
                              </div>
                            </div>

                            {/* Popular Colleges Section Next */}
                            <div className='text-black font-normal text-sm gap-2.5 w-full  mt-[18px] '>
                              <div className='pt-2.5 ml-2.5 text-base font-medium'>
                                Popular Colleges
                              </div>
                              {popularColleges?.slice(0, 5).map((clg) => (
                                <div
                                  key={clg.university_id}
                                  onClick={() => handleExploreClick(clg)}
                                  className='pt-4 ml-2.5 cursor-pointer text-lightTextColor'
                                >
                                  {clg.university_name}
                                </div>
                              ))}
                              <div
                                className='pt-5 ml-2.5 text-[#468EFF]'
                                onClick={() => navigate("/colleges")}
                              >
                                View all
                              </div>
                            </div>

                            {/* Colleges by Degree Section */}
                            <div className='font-normal text-sm gap-2.5 w-full mt-[18px] '>
                              <div className='text-black pt-2.5 ml-2.5 text-base font-medium'>
                                Colleges by Degree
                              </div>
                              {allPrograms?.slice(0, 5).map((degree) => (
                                <div
                                  key={degree.id}
                                  onClick={() =>
                                    handleProgramClick(
                                      degree.id,
                                      degree.program_master_name
                                    )
                                  }
                                  className='pt-4 ml-2.5 cursor-pointer text-lightTextColor text-sm'
                                >
                                  {`${degree.program_master_name} Colleges in India`}
                                </div>
                              ))}
                              <div
                                className='pt-4 ml-2.5 text-[#468EFF]'
                                onClick={() => navigate("/colleges")}
                              >
                                View all
                              </div>
                            </div>

                            {/* Colleges by Location Section Last */}
                            <div className='text-black font-normal text-sm gap-2.5 w-full  '>
                              <div className='pt-8 ml-2.5 text-base font-medium'>
                                Colleges by Location
                              </div>
                              {Array.isArray(citiesList) &&
                              citiesList.length > 0 ? (
                                citiesList.slice(0, 5).map((city) => (
                                  <div
                                    key={city.id}
                                    onClick={() => {
                                      handleCityClick(
                                        city.id,
                                        "city",
                                        city.name
                                      );
                                    }}
                                    className='pt-4 ml-2.5 cursor-pointer text-lightTextColor'
                                  >
                                    {`Colleges in ${city.name}`}
                                  </div>
                                ))
                              ) : (
                                <div className='pt-4 ml-2.5'>
                                  Loading cities...
                                </div>
                              )}
                              <div
                                className='pt-5 ml-2.5 text-[#468EFF]'
                                onClick={() => navigate("/colleges")}
                              >
                                View all
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {item === "Careers" && careerDropdownOpen && (
                  <div
                    className={`fixed ${
                      careerDropdownOpen ? "flex" : "hidden"
                    } flex-col md:w-[1087px] 
    max-h-screen overflow-y-auto top-[70px] left-1/2 transform -translate-x-1/2 
    bg-white rounded-2xl shadow-lg  border-4 border-gray-200 transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
                    // style={{ marginTop: "0", border: "10px solid rgba(255, 255, 255, 0.25)" }}
                    onMouseLeave={() => setCareerDropdownOpen(false)} // Close on mouse leave
                  >
                    {/* Main Content */}
                    <div
                      className='w-full h-full rounded-2xl flex p-5 overflow-y-auto max-h-[calc(100vh-70px)] scrollbar-hide'
                      style={{
                        background:
                          "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
                      }}
                    >
                      <div className='flex-1'>
                        {/* "Popular Careers" title */}
                        <div
                          className='text-black font-medium text-[14px] leading-[18.23px] text-left mb-4'
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          Popular Careers
                        </div>

                        {/* Grid for Career Options */}
                        <div
                          className='grid grid-cols-5 gap-5' // Adjusted to 5 columns with dynamic rows
                          style={{
                            gridTemplateRows: `repeat(${Math.ceil(
                              streams.length / 5
                            )}, auto)`, // 5 items per column
                          }}
                        >
                          {/* Mapping through streams and displaying 5 items per column */}
                          {streams.map((stream) => (
                            <div
                              key={stream.id}
                              className='text-lightTextColor font-normal text-sm leading-[15.62px] text-left w-full mt-1 mb-1 cursor-pointer'
                              style={{
                                fontWeight: "400",
                              }}
                              onClick={() =>
                                handleCourseClick(stream.id, stream.stream_name)
                              }
                            >
                              {stream.stream_name}
                            </div>
                          ))}

                          {/* "View All" Link at the bottom of the dropdown */}
                          <div className='col-span-5 w-full mt-4'>
                            <Link
                              to='/career'
                              className='text-[#468EFF]  py-3 rounded-lg font-[500]  inline-block transition'
                              style={{
                                fontSize: "12px",

                                lineHeight: "15.62px",
                                color: "#468EFF",
                              }}
                            >
                              View All Careers
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {item === "Exam" && examDropdownOpen && (
                  <div
                    className={`fixed ${
                      examDropdownOpen ? "flex" : "hidden"
                    } flex-col md:w-[800px] lg:w-[1087px] 
                  max-h-[calc(100vh-10%)] overflow-y-auto top-[70px] left-1/2 transform -translate-x-1/2 bg-white 
                  rounded-2xl shadow-lg border-4 border-gray-200
                  transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
                  >
                    <div className='w-full h-full rounded-2xl custom-gradient flex'>
                      {/* Streams List Section */}
                      <div className='flex flex-col p-2 md:w-1/4 lg:w-1/5 xl:w-1/6 font-medium md:text-xs lg:text-sm overflow-y-auto max-h-[calc(100vh-10%)] scrollbar-hide text-black pt-2.5 pb-2.5 pr-1.25 pl-1.25 gap-2.5 bg-[#F2FCEB] rounded-2xl flex-shrink-0'>
                        {streamsList.map((stream, index) => (
                          <div
                            key={stream.id}
                            onMouseEnter={() =>
                              handleExamStreamClick(
                                stream.id,
                                stream.stream_name
                              )
                            }
                            className={`cursor-pointer uppercase py-2 px-3 rounded-lg ${
                              selectedExamStreamId === stream.id
                                ? "bg-[#AEFF75]"
                                : ""
                            } ${
                              !selectedExamStreamId && index === 0
                                ? "bg-[#AEFF75]"
                                : ""
                            }`}
                          >
                            {stream.stream_name}
                          </div>
                        ))}
                      </div>

                      {/* Exam List Section */}
                      <div className='flex-1 p-3 ml-[60px] overflow-y-auto max-h-[calc(100vh-10%)] scrollbar-hide'>
                        {/* Display the first 5 items in two rows */}
                        <div className='grid grid-cols-3 gap-6 pl-3'>
                          {examList?.slice(0, 3).map((exam) => (
                            <div
                              key={exam.id}
                              className='text-black font-normal cursor-pointer flex flex-col'
                            >
                              <div
                                className='pt-2.5 font-medium text-base'
                                onClick={() =>
                                  navigate(`/exams/${exam.id}`, {
                                    state: { selectedTab: 1 }, // Default to Overview tab
                                  })
                                }
                              >
                                {exam.exam_master_name}
                              </div>
                              <div className='flex flex-col mt-3 gap-2.5'>
                                {[
                                  "Overview",
                                  "Syllabus",
                                  "Exam Pattern",
                                  "Eligibility",
                                  "How to Prepare",
                                ].map((tab) => (
                                  <div
                                    key={tab}
                                    className='text-sm cursor-pointer'
                                    onClick={() =>
                                      navigate(`/exams/${exam.id}`, {
                                        state: {
                                          selectedTab: examTabMapping[tab],
                                        },
                                      })
                                    }
                                  >
                                    {tab}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Second row with the remaining 2 items */}
                        <div className='grid grid-cols-2 gap-6 mt-6 pl-3'>
                          {examList?.slice(3, 5).map((exam) => (
                            <div
                              key={exam.id}
                              className='text-black font-normal cursor-pointer flex flex-col'
                            >
                              <div
                                className='pt-2.5 font-medium text-base'
                                onClick={() =>
                                  navigate(`/exams/${exam.id}`, {
                                    state: { selectedTab: 1 }, // Default to Overview tab
                                  })
                                }
                              >
                                {exam.exam_master_name}
                              </div>
                              <div className='flex flex-col gap-2.5'>
                                {[
                                  "Overview",
                                  "Syllabus",
                                  "Exam Pattern",
                                  "Eligibility",
                                  "How to Prepare",
                                ].map((tab) => (
                                  <div
                                    key={tab}
                                    className='text-sm cursor-pointer'
                                    onClick={() =>
                                      navigate(`/exams/${exam.id}`, {
                                        state: {
                                          selectedTab: examTabMapping[tab],
                                        },
                                      })
                                    }
                                  >
                                    {tab}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* "View All" Link */}
                        <div className='col-span-5 w-full mt-4 pl-3'>
                          <Link
                            to='/exams/all'
                            className='text-[#468EFF] py-3 rounded-lg font-[500] inline-block transition'
                            style={{
                              fontSize: "12px",
                              lineHeight: "15.62px",
                              color: "#468EFF",
                            }}
                          >
                            View All Exams
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {item === "Courses" && coursesDropdownOpen && (
                  <div
                    className={`fixed ${
                      coursesDropdownOpen ? "flex" : "hidden"
                    } flex-col md:w-[800px] lg:w-[1087px] 
                  max-h-[calc(100vh-10%)] overflow-y-auto top-[70px] left-1/2 transform -translate-x-1/2 bg-white 
                  rounded-2xl shadow-lg  border-4 border-gray-200
                  transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
                  >
                    <div className='w-full h-full rounded-2xl custom-gradient  flex'>
                      <div className='flex flex-col p-2  md:w-1/4 lg:w-1/5 xl:w-1/6 font-medium md:text-xs lg:text-sm overflow-y-auto max-h-[calc(100vh-10%)] scrollbar-hide text-black pt-2.5 pb-2.5 pr-1.25 pl-1.25 gap-2.5 bg-[#F2FCEB] rounded-2xl flex-shrink-0'>
                        {streamsList.map((stream, index) => (
                          <div
                            key={stream.id}
                            onMouseEnter={() =>
                              handleCourseStreamClick(
                                stream.id,
                                stream.stream_name
                              )
                            }
                            className={`cursor-pointer uppercase py-2 px-3 rounded-lg ${
                              selectedCourseStreamId === stream.id
                                ? "bg-[#AEFF75]"
                                : ""
                            } ${
                              !selectedCourseStreamId && index === 0
                                ? "bg-[#AEFF75]"
                                : ""
                            }`}
                          >
                            {stream.stream_name}
                          </div>
                        ))}
                      </div>

                      {/* Courses Section */}
                      <div className='flex-1 p-5'>
                        <div className='text-black font-medium text-sm mb-4'>
                          {selectedCourseStreamName ||
                            streamsList[0]?.stream_name}{" "}
                          Courses
                        </div>

                        {/* Courses Grid */}
                        <div className='grid grid-cols-4 gap-6'>
                          {coursesList?.map((courses) => (
                            <div
                              key={courses.id}
                              className='text-lightTextColor text-sm font-normal cursor-pointer'
                              onClick={() =>
                                navigate(
                                  `/courses/details/${courses.id}/${courses.course_master_name}`,
                                  {
                                    state: {
                                      courseName: courses.course_master_name,
                                      courseId: courses.id,
                                    },
                                  }
                                )
                              }
                            >
                              {courses.course_master_name}
                            </div>
                          ))}
                        </div>

                        {/* "View All" Link */}
                        <div className='col-span-5 w-full mt-4'>
                          <Link
                            to='/courses/all'
                            className='text-[#468EFF] py-3 rounded-lg font-[500] inline-block transition'
                            style={{
                              fontSize: "12px",
                              lineHeight: "15.62px",
                              color: "#468EFF",
                            }}
                          >
                            View All Courses
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {item === "Latest Updates" && updatesDropdownOpen && (
                  <div
                    className={`fixed ${
                      updatesDropdownOpen ? "flex" : "hidden"
                    } flex-col md:w-[1087px] 
    max-h-screen overflow-y-auto top-[70px] left-1/2 transform -translate-x-1/2 
    bg-white rounded-2xl shadow-lg  border-4 border-gray-200transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
                    // style={{ marginTop: "0", border: "10px solid rgba(255, 255, 255, 0.25)" }}
                    onMouseLeave={() => setUpdatesDropdownOpen(false)} // Close on mouse leave
                  >
                    {/* Main Content */}
                    <div
                      className='w-full h-full rounded-2xl flex p-5 overflow-y-auto max-h-[calc(100vh-70px)] scrollbar-hide'
                      style={{
                        background:
                          "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
                      }}
                    >
                      {/* Render LatestUpdates Component */}
                      <LatestUpdates />
                    </div>
                  </div>
                )}

{item === "More" && moreDropdownOpen && (
  <div
    className={`fixed ${
      moreDropdownOpen ? "flex" : "hidden"
    } flex-col md:w-[1087px] max-h-screen overflow-y-auto top-[70px] left-[52%] transform -translate-x-[40%]
    bg-white rounded-2xl shadow-lg border-4 border-gray-200 transition-opacity duration-300 ease-in-out z-50 scrollbar-hide`}
    onMouseLeave={() => setMoreDropdownOpen(false)} // Close on mouse leave
  >
    {/* Main Content */}
    <div
      className='w-full h-full rounded-2xl flex p-5 overflow-y-auto max-h-[calc(100vh-70px)] scrollbar-hide'
      style={{
        background:
          "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
      }}
    >
      <div className='flex-1'>
        {/* Static Links Section */}
        <div className='mb-4'>
          <h3 className='text-black font-medium text-[14px] leading-[18.23px] text-left mb-4' style={{ fontWeight: "700" }}>
            Quick Links
          </h3>
          <div className='grid grid-cols-4 gap-5'> {/* Modified to flex row layout */}
            <Link
              to='/aboutUs'
              className='text-lightTextColor font-normal text-sm leading-[15.62px] text-left cursor-pointer'
              style={{ fontWeight: "400" }}
            >
              About Us
            </Link>
            <Link
              to='/contactUs'
              className='text-lightTextColor font-normal text-sm leading-[15.62px] text-left cursor-pointer'
              style={{ fontWeight: "400" }}
            >
              Contact Us
            </Link>
            <Link
              to='/privacyPolicy'
              className='text-lightTextColor font-normal text-sm leading-[15.62px] text-left cursor-pointer'
              style={{ fontWeight: "400" }}
            >
              Privacy Policy
            </Link>
            <Link
              to='/termsAndConditions'
              className='text-lightTextColor font-normal text-sm leading-[15.62px] text-left cursor-pointer'
              style={{ fontWeight: "400" }}
            >
              Terms and Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)}

              </div>
            ))}
          </div>

          {/* Profile Icon */}
          {/* <div
            className="profile-icon flex items-center justify-center shadow-lg cursor-pointer rounded-full"
            style={{
              width: "48px",
              height: "48px",
              backgroundColor: isScrolled ? "white" : "rgb(58, 202, 46)",
            }}
            // onClick={openModal}
            onClick={handleLogout}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1'
              stroke={isScrolled ? "black" : "white"}
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z'
              />
            </svg>
          </div> */}

          {/* Profile Icon Container */}
          <div
            className='relative flex items-center justify-center'
            style={{
              width: "48px",
              height: "48px",
            }}
          >
            {/* Profile Icon */}
            {registration_id && (
 <div
 className='profile-icon flex items-center justify-center shadow-lg cursor-pointer rounded-full'
 style={{
   width: "48px",
   height: "48px",
   backgroundColor: isScrolled ? "white" : "rgb(58, 202, 46)",
 }}
 onClick={toggleDropdown} // Toggle dropdown on click
>
 <svg
   xmlns='http://www.w3.org/2000/svg'
   fill='none'
   viewBox='0 0 24 24'
   strokeWidth='1'
   stroke={isScrolled ? "black" : "white"}
   className='w-6 h-6'
 >
   <path
     strokeLinecap='round'
     strokeLinejoin='round'
     d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z'
   />
 </svg>
</div>
            )}
           

            {/* Dropdown Menu */}
            {dropdownOpen && (
           <div
           ref={profileDropdownRef}
           className="absolute top-full left-1/2 mt-2 w-48 bg-white rounded-lg shadow-lg border-b border-gray-300"
           style={{ transform: "translateX(-64%)" }} // Custom translateX value
         >
           <div className="bg-[#DCFFC4] text-black font-bold rounded-md text-center p-3">{`Hi, ${name}`}</div>
           <ul className="flex flex-col px-1 py-2 mt-2 gap-2">
             
             {/* Profile Item */}
             <li className="flex flex-col">
    <div className="flex items-center space-x-2 text-black cursor-pointer px-4 py-1 rounded-md hover:bg-[#E7FFD8]">
      <div className="p-1 rounded-full border border-gray-300">
        <AiOutlineUser className="w-4 h-4 text-[#3ACA2E]" />
      </div>
      <span>Profile</span>
    </div>
    <div className="border-b-2 border-gray-200 mt-2"></div>
  </li>
             {/* Dashboard Item */}
             <li className="flex flex-col">
    <div className="flex items-center space-x-2 text-black cursor-pointer px-4 py-1 rounded-md hover:bg-[#E7FFD8]" onClick={() => navigate("/admin")}>
      <div className="p-1 rounded-full border border-gray-300">
        <MdDashboard className="w-4 h-4 text-[#3ACA2E]" />
      </div>
      <span>Dashboard</span>
    </div>
    <div className="border-t border-gray-300 mt-2"></div>
  </li>
             
             {/* Logout Item */}
             <li className="flex flex-col">
    <div className="flex items-center space-x-2 text-black cursor-pointer px-4 py-1 rounded-md hover:bg-[#E7FFD8]" onClick={handleLogout}>
      <div className="p-1 rounded-full border border-gray-300">
        <MdLogout className="w-4 h-4 text-[#3ACA2E]" />
      </div>
      <span>Logout</span>
    </div>
  </li>
             
           </ul>
         </div>
        )}
          </div>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <div
        ref={navRef}
        className={`fixed top-0 right-0 h-screen w-64 bg-white z-50 transform ${
          isNavOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out shadow-lg`}
      >
        <div className='flex flex-col h-full p-4'>
          <button className='self-end text-gray-500 p-2' onClick={closeNav}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='2'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
          <div className='flex flex-col mt-4'>
            {[
              "Colleges",
              "Exam",
              "Courses",
              "Careers",
              "Latest Updates",
              "More",
            ].map((item, index) => (
              <div
                key={index}
                className='flex items-center gap-2 p-4 border-b border-gray-200 text-black cursor-pointer'
                onClick={closeNav}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='green'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m19.5 8.25-7.5 7.5-7.5-7.5'
                  />
                </svg>
                <p>{item}</p>
              </div>
            ))}
            <div
              className='flex items-center justify-center mt-4 shadow-lg cursor-pointer rounded-full'
              style={{
                width: "48px",
                height: "48px",
                backgroundColor: "white",
              }}
              onClick={openModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='black'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
