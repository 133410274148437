import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const LatestUpdates = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate(); // Initialize navigate hook

  // Updated truncateContent function
  const truncateContent = (content, charLimit = 100) => {
    if (!content) return '';
    if (content.length <= charLimit) return content;
    return content.substring(0, charLimit) + '...';
  };

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://api.collegedwarka.com/api/get/news_announcements"
        );
        console.log("Fetched news data:", response.data);

        // Sort by event_date and take the most recent 4
        const sortedNews = response.data.sort(
          (a, b) => new Date(b.event_date) - new Date(a.event_date)
        );
        setNews(sortedNews.slice(0, 4)); // Get only the most recent 4 news
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, []);

  // Function to handle click and navigate to the news detail page with the ID
  const handleNewsClick = (id) => {
    navigate(`/particularNews/${id}`); // Navigate to the news detail page with the ID in the URL
  };

  return (
    <div className="py-4 px-2">
      <div className="max-w-6xl mx-auto">
        {/* Title Section */}
        <div className="text-left mb-4">
          <h2
            className="font-bold text-[20px] inline-block px-4 py-2 rounded-md"
            style={{
              color: "#468EFF",
            }}
          >
            Get All Latest Updates
          </h2>
        </div>

        {/* News Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {news.map((item) => (
            <div
              key={item.id}
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300"
              onClick={() => handleNewsClick(item.id)}
            >
              {/* Image Container */}
              <div className="h-24 w-full mb-2 overflow-hidden rounded-md">
                {item.picture ? (
                  <img
                    src={`https://api.collegedwarka.com/api/files${item.picture}`}
                    alt={item.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="h-full w-full bg-gray-300 rounded-md"></div>
                )}
              </div>

              {/* Content Container */}
              <div className="flex flex-col h-[80px] overflow-hidden">
                {/* Title */}
                {item.title && (
                  <h3 className="text-[12px] text-gray-800 font-bold mb-1 line-clamp-2">
                    {item.title}
                  </h3>
                )}

                {/* Content */}
                <div className="flex-1 overflow-hidden">
                  <p className="text-gray-600 text-sm line-clamp-2">
                    {truncateContent(item.content)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* View All News Button */}
        <div className="mt-6 text-left">
          <button
            onClick={() => navigate("/newsDetail")}
            className="font-bold hover:text-[#2563eb] transition-colors duration-200"
            style={{
              color: "#468EFF",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "14px",
              padding: "6px 12px",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            View All News &rarr;
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestUpdates;
