import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const CareerMaster = () => {
  const { courseId } = useParams(); // Get courseId from the URL
  const [careers, setCareers] = useState([]); // State to store careers
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        const response = await axios.get("https://api.collegedwarka.com/api/get/career_master");

        // Filter the careers based on course_id
        const filteredCareers = response.data.filter(
          (career) => career.course_master_id === parseInt(courseId)
        );

        setCareers(filteredCareers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching careers:", error);
        setError("Failed to fetch careers");
        setLoading(false);
      }
    };

    fetchCareers();
  }, [courseId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleCareerClick = (careerId) => {
    navigate(`/subCareerMaster/${careerId}`); // Navigate to SubCareerMaster with careerId
  };

  return (
    <>
    <div className="flex flex-col min-h-screen">
    <div style={{height: "50px", borderbottom: "2px solid #ccc", boxshadow: "0 4px 6px rgba(0, 0, 0, 0.1)"}}><Header /></div>
    <div className="flex flex-col min-h-screen mt-20 bg-[#F2FCEB]"> {/* Flexbox for full height */}
      <div className="flex-grow p-6"> {/* Background and padding for main content */}
        <div className="container mx-auto"> {/* Center the content */}
          <h2 className="text-2xl font-bold mb-6 font-dm-sans text-center mt-5">
            Careers for Course ID {courseId}
          </h2>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {careers.length > 0 ? (
              careers.map((career) => (
                <div
                  key={career.id}
                  className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
                >
                  <div className="flex-grow" />
                  <div className="absolute bottom-0 w-full">
                    <button
                      className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
                      onClick={() => handleCareerClick(career.id)} // Call the function on click
                    >
                      {career.name}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No careers found for this course ID.</p>
            )}
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div></>
  );
};

export default CareerMaster;
