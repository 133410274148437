import React, { useState ,useEffect} from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
// import { useProfessor } from '../../../context/ProfessorContext';
// import dummyProfessors from './dummyProfessors';
import { Base_URL,Img_URL} from '../../../apiConfig'; 
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const AllFaqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = faqs.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(faqs.length / entriesPerPage);

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const response = await axios.get(`${Base_URL}/faq/university/2`);
        console.log('Fetched Data:', response.data); // Debugging line
        setFaqs(response.data);
      } catch (error) {
        console.error('Error fetching faqs:', error.response ? error.response.data : error.message);
        setError('Error fetching faqs.');
      }
    };

    fetchFaq();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/faqs/edit/${id}`);
  };

  const handleDeleteClick = async (id) => {
    {
      try {
        await axios.delete(`${Base_URL}/faq/${id}`);
        setFaqs(prevFaqs => prevFaqs.filter(faq => faq.faq_id !== id));
        toast.success('FAQ deleted successfully.');
      } catch (error) {
        console.error('Error deleting faq:', error.response ? error.response.data : error.message);
        toast.error('Failed to delete faq.');
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredEntries = currentEntries.filter((faq) => {
    return faq.question && faq.question.toLowerCase().includes(searchTerm.toLowerCase());
  });

 

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All FAQs" breadcrumb={['FAQs', 'All FAQs']} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            All FAQs
          </div>
          <div className="flex items-center space-x-4">
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/dashboard/faqs/add')}
          >
            + Add New
          </button>
          </div>
        </div>
         {/* Flex container for "Show Entries" dropdown and search bar */}
         <div className="flex items-center justify-between mb-4 pb-2 ">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span
              className="text-sm text-gray-600"
              style={{
                color: 'rgb(130, 130, 130)',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
              }}
            >
              Search:
            </span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr  className="bg-white">
                {['Question', 'Answer',  'Actions'].map((heading) => (
                  <th key={heading} className="text-left border-b" style={{ color: 'rgb(49, 42, 42)', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: 600, lineHeight: '21px', padding: '14px 13px', borderBottomColor: 'rgb(230, 230, 230)' }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((faq) => (
                  console.log(faq.faq_id),
                  <tr
                    key={faq.faq_id}
                    className="hover:bg-white hover:shadow-md border-b"
                    style={{ borderTopWidth: '1px', borderTopColor: 'rgb(230, 230, 230)' ,verticalAlign: 'top'}}
                  >
                   
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px' }}>
                      {faq.question}
                    </td>
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px' }}>
                      {faq.answer}
                    </td>
                   
                   
                   

                   
                    <td className="text-left border-t border-b" style={{ color: 'rgb(115, 123, 139)', fontFamily: 'Poppins, sans-serif', fontSize: '13.125px', fontWeight: 400, lineHeight: '19.6px', padding: '9.1px 13.125px', borderBottomColor: 'rgb(230, 230, 230)',whiteSpace: 'nowrap', // Prevent line breaks
                        overflow: 'hidden', // Hide overflow
                        textOverflow: 'ellipsis', // Add ellipsis for overflow
                        maxWidth: '150px' }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(faq.faq_id)}
                          className="p-2 rounded bg-blue-500 text-white hover:bg-blue-700"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(faq.faq_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-4">No professors found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
         
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2  bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2  bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllFaqs;


//import React, { useState, useEffect } from 'react';
// import { HiPencil, HiTrash } from 'react-icons/hi';
// import { useNavigate } from 'react-router-dom';
// import HeaderSection from '../headerSection/HeaderSection';
// import { Base_URL } from '../../../apiConfig'; 
// import axios from 'axios';

// const AllFaqs = () => {
//   const [faqs, setFaqs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [entriesPerPage, setEntriesPerPage] = useState(20);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const indexOfLastEntry = currentPage * entriesPerPage;
//   const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//   const currentEntries = faqs.slice(indexOfFirstEntry, indexOfLastEntry);

//   const totalPages = Math.ceil(faqs.length / entriesPerPage);

//   useEffect(() => {
//     const fetchFaq = async () => {
//       try {
//         const response = await axios.get(`${Base_URL}/faq/university/2`);
//         console.log('Fetched Data:', response.data); // Debugging line
//         setFaqs(response.data);
//       } catch (error) {
//         console.error('Error fetching faqs:', error.response ? error.response.data : error.message);
//         setError('Error fetching faqs.');
//       }
//     };

//     fetchFaq();
//   }, []);

//   const handleEditClick = (id) => {
//     navigate(`/dashboard/professors/edit/${id}`);
//   };

//   const handleDeleteClick = async (id) => {
//     if (window.confirm('Are you sure you want to delete this faq?')) {
//       try {
//         await axios.delete(`${Base_URL}/faq/${id}`);
//         setFaqs(prevFaqs => prevFaqs.filter(faq => faq.faq_id !== id));
//         alert('FAQ deleted successfully.');
//       } catch (error) {
//         console.error('Error deleting faq:', error.response ? error.response.data : error.message);
//         setError('Failed to delete faq.');
//       }
//     }
//   };

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const filteredEntries = currentEntries.filter((faq) => {
//     return faq.question && faq.question.toLowerCase().includes(searchTerm.toLowerCase());
//   });

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <HeaderSection title="All FAQs" breadcrumb={['FAQs', 'All FAQs']} />
//       <div className="bg-white p-6 rounded-lg shadow">
//         <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
//           <div className="font-poppins" style={{ color: 'rgb(49, 42, 42)', fontSize: '18.5px', fontWeight: 600, lineHeight: '26.25px', height: '45px' }}>
//             All FAQs
//           </div>
//           <button className="px-4 py-2 bg-customblue text-white rounded hover:bg-[rgb(82,89,199)]" onClick={() => navigate('/dashboard/faqs/add')}>
//             + Add New
//           </button>
//         </div>
//         <div className="flex items-center justify-between mb-4">
//           <div className="flex items-center space-x-4">
//             <span className="text-sm text-gray-600 mr-2">Show:</span>
//             <select
//               value={entriesPerPage}
//               onChange={(e) => setEntriesPerPage(Number(e.target.value))}
//               className="border border-gray-300 rounded"
//             >
//               <option value="10">10</option>
//               <option value="20">20</option>
//               <option value="50">50</option>
//               <option value="100">100</option>
//             </select>
//           </div>
//           <div className="flex items-center space-x-4">
//             <span className="text-sm text-gray-600">Search:</span>
//             <input
//               type="text"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="px-3 py-1 border border-gray-300 rounded"
//             />
//           </div>
//         </div>
//         <div className="overflow-x-auto">
//           <table className="min-w-full bg-white">
//             <thead>
//               <tr>
//                 <th className="text-left border-b">Question</th>
//                 <th className="text-left border-b">Answer</th>
//                 <th className="text-left border-b">Status</th>
//                 <th className="text-left border-b">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredEntries.length > 0 ? (
//                 filteredEntries.map((faq) => (
//                   <tr key={faq.faq_id} className="hover:bg-white hover:shadow-md border-b">
//                     <td className="text-left">{faq.question}</td>
//                     <td className="text-left">{faq.answer}</td>
//                     <td className="text-left">
//                       <span className={`inline-block py-1 px-3 rounded-full text-sm ${faq.status === 'Active' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
//                         {faq.status}
//                       </span>
//                     </td>
//                     <td className="text-left">
//                       <div className="flex space-x-2">
//                         <button onClick={() => handleEditClick(faq.faq_id)} className="p-2 bg-blue-500 text-white rounded">
//                           <HiPencil />
//                         </button>
//                         <button onClick={() => handleDeleteClick(faq.faq_id)} className="p-2 bg-red-500 text-white rounded">
//                           <HiTrash />
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4" className="text-center py-4">No FAQs found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//         <div className="flex justify-between items-center mt-4">
//           <button onClick={handlePrevious} className="px-4 py-2 bg-customblue text-white rounded" disabled={currentPage === 1}>
//             Previous
//           </button>
//           <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>
//           <button onClick={handleNext} className="px-4 py-2 bg-customblue text-white rounded" disabled={currentPage === totalPages}>
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllFaqs;