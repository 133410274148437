import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderSection from '../headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 



const AddAmbassador = () => {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    introduction: '',
    email: '',
    password: '',
    status: 'Active',
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
 


 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData();

      for (const key in formData) {
        if (key === 'status') {
          data.append(key, formData[key] === 'Active' ? 1 : 0); // Convert status to numeric value
        } else {
          data.append(key, formData[key]);
        }
      }

      if (image) {
        data.append('profile_picture', image);
      }
      data.append('university_id', 1); // Hardcoded university_id
      data.append('registration_typeid', 3); // Hardcoded registration_typeid


      const response = await axios.post(`${Base_URL}/ambassadors`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Ambassador added successfully:', response.data);
      const imageUrl = response.data.profile_picture;
      if (imageUrl) {
        console.log('Uploaded image URL:', imageUrl);
      } else {
        console.error('Image URL not found in the response');
      }
      toast.success('Ambassador added successfully!');
      setFormData({
        name: '',
        subject: '',
        introduction: '',
        email: '',
        password: '',
        status: 'Active',
      });
      setImage(null);
      setImageName('No file chosen');
      setError(null);
    } catch (error) {
      
      console.error('Failed to add ambassador:', error.response?.data || error.message);

      toast.error('Failed to add ambassador. Please try again.');
      setError('Failed to add ambassador. Please try again.');
    }
  };
 
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <HeaderSection
        title="Add Ambassador"
        breadcrumb={['Ambassador', 'Add Ambassador']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Basic Info</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4  ">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            {/* <div className="flex flex-col">
              <label
                htmlFor="university_id"
                className="text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]">
                University
              </label>
              <select
                id="university_id"
                name="university_id"
                // value={universityId}
                onChange={(e) => setUniversityId(e.target.value)}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                
              >
                <option value="">Select University</option>
                {universities.map((university) => (
                  <option key={university.id} value={university.id}>
                    {university.name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="flex flex-col">
              <label
                htmlFor="subject"
                className=" cursor-default inline-block font-poppins text-[#312a2a]  text-[0.875rem] font-[500] mb-[7px]">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="introduction"
                className=" text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[7px]">
                Introduction
              </label>
              <textarea
                id="introduction"
                name="introduction"
                value={formData.introduction}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                rows="4"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins font-[500] mb-[7px]">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="password"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[7px]">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="status"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins font-[500] mb-[7px]">
                Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="profile_picture"
                className="text-[#312a2a]  text-[0.875rem] cursor-default inline-block font-poppins  font-[500] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
            </div>

          <div className="mt-6">
            <button
              type="submit"
              className=" text-white px-4 py-2 bg-customblue rounded text-sm hover:bg-[rgb(82,89,199)]">
              Submit
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
           
  );
};

export default AddAmbassador;
