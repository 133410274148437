import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const CareerTab = ({ career }) => {
  const { id } = useParams(); // Get the stream id from the URL
  const [programs, setPrograms] = useState([]); // State to store filtered programs

  const navigate = useNavigate();
 
  // Fetch data from the API
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://api.collegedwarka.com/api/get/program_master"
        );
        // Filter programs where stream_id matches the id from the URL
        const filteredPrograms = response.data.filter(
          (program) => program.stream_id === parseInt(id)
        );
        setPrograms(filteredPrograms);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, [id]);

  const handleButtonClick = (programId) => {
    navigate(`/program/${programId}`); // Navigate to ProgramCourses with the program ID
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div style={{ height: "50px"}}>
        <Header />
      </div>
      
      {/* Main content section */}
      <div className="flex-grow p-6 mt-20 bg-[#F2FCEB]"> {/* Background and padding for main content */}
        <div className="container mx-auto"> {/* Center the content */}
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
            {career} {/* Display the career name */}
          </h2>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {programs.length > 0 ? (
              programs.map((program) => (
                <div
                  key={program.id}
                  className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
                >
                  <div className="flex-grow" />
                  <div className="absolute bottom-0 w-full">
                    <button
                      onClick={() => handleButtonClick(program.id)}
                      className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
                    >
                      {program.program_master_name}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-4 text-center">No programs found for this stream.</p>
            )}
          </div>
        </div>
      </div>

      <Footer /> {/* Render Footer below the content */}
    </div>
  );
};

export default CareerTab;
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import Header from "../components/Header/Header";
// import Footer from "../components/Footer/Footer";

// const CareerTab = ({ career }) => {
//   const { id } = useParams(); // Get the career id from the URL
//   const [subCareer, setSubCareer] = useState([]); // State to store filtered programs

//   const navigate = useNavigate();
 
//   // Fetch data from the API
//   useEffect(() => {
//     const fetchSubCareer = async () => {
//       try {
//         const response = await axios.get(
//           "https://api.collegedwarka.com/api/get/SubCareerMaster"
//         );
//         // Filter programs where stream_id matches the id from the URL
//         const filteredSubCareer = response.data.filter(
//           (subCareer) => subCareer.career_master_id === parseInt(id)
//         );
//         setSubCareer(filteredSubCareer);
//       } catch (error) {
//         console.error("Error fetching programs:", error);
//       }
//     };

//     fetchSubCareer();
//   }, [id]);

//   const handleButtonClick = (programId) => {
//     navigate(`/program/${programId}`); // Navigate to ProgramCourses with the program ID
//   };

//   return (
//     <div className="flex flex-col min-h-screen">
//       <div style={{ height: "50px"}}>
//         <Header />
//       </div>
      
//       {/* Main content section */}
//       <div className="flex-grow p-6 mt-20 bg-[#F2FCEB]"> {/* Background and padding for main content */}
//         <div className="container mx-auto"> {/* Center the content */}
//           <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
//             {career} {/* Display the career name */}
//           </h2>

//           <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
//             {subCareer.length > 0 ? (
//              subCareer.map((subCareer) => (
//                 <div
//                   key={subCareer.id}
//                   className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
//                 >
//                   <div className="flex-grow" />
//                   <div className="absolute bottom-0 w-full">
//                     <button
//                       onClick={() => handleButtonClick(subCareer.id)}
//                       className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
//                     >
//                       {subCareer.name}
//                     </button>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p className="col-span-4 text-center">No programs found for this stream.</p>
//             )}
//           </div>
//         </div>
//       </div>

//       <Footer /> {/* Render Footer below the content */}
//     </div>
//   );
// };

// export default CareerTab;
