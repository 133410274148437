import React, { useState, useEffect } from 'react';
import college_logo from '../../../assets/college_logo.png';
import Graphic from '../../../assets/landing/Graphic.png';
import Arrow from '../../../assets/landing/Arrow.png';
import './landing-news-events.css';
import EventCard from '../../College/NewsEvents/EventCard/EventCard';
import NewsCard from '../../College/NewsEvents/NewsCard/NewsCard';
import { Base_URL } from '../../../apiConfig';
import { Img_URL } from '../../../apiConfig';

const LandingNewsEvents = () => {
  const [featuredNews, setFeaturedNews] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [recentNews, setRecentNews] = useState([]); // Added state for recent news
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {

    setLoading(false);
    setUpcomingEvents(dummyUpcomingEvents);
    // setRecentNews(recentNewsData);
    const fetchNews = async () => {
        try {
            const response = await fetch(`${Base_URL}/get/news_announcements`);
            const data = await response.json();
            
            // Check if the response is an array before setting it to the state
            if (Array.isArray(data)) {
                setRecentNews(data);
            } else {
                console.error("Unexpected API response format:", data);
                setRecentNews([]); // Fallback to an empty array if the data is not an array
            }
        } catch (error) {
            console.error("Error fetching universities:", error);
        }
    };

    fetchNews();
}, []);
  // Dummy data for featured news
  const dummyFeaturedNews = {
    title: 'Big Breakthrough in AI Technology',
    content: 'Researchers at the university have made a significant breakthrough in artificial intelligence that is set to revolutionize the industry.',
    picture: Graphic,
    announcement_date: '2024-10-01',
  };



  // Dummy data for upcoming events
  const dummyUpcomingEvents = [
    {
      event_name: 'Annual Tech Conference',
      event_details: 'Join us for a day of technology and innovation.',
      event_location: 'Main Auditorium',
      event_date: '2024-09-15',
    },
    {
      event_name: 'Career Fair',
      event_details: 'Meet potential employers and explore career opportunities.',
      event_location: 'Career Center',
      event_date: '2024-10-05',
    },
    {
      event_name: 'Workshop on AI',
      event_details: 'Learn about the latest advancements in Artificial Intelligence.',
      event_location: 'Room 101',
      event_date: '2024-11-10',
    },
  ];

  // Fetch data from the API on component mount
  // useEffect(() => {
  //   // Simulate fetching data for the example
  //   setLoading(false);
  //   setUpcomingEvents(dummyUpcomingEvents);
  //   setRecentNews(recentNewsData);
  //   // Fetch data logic can be added here
  // }, []);

  return (
    <div className="py-8 px-4 md:px-6 lg:px-8 bg-white">
      <div className="text-black text-base font-medium flex flex-col gap-5">
        <p className="text-center md:text-lg text-[16px] text-[#808080] font-[500] mb-2">
          NEWS AND EVENTS
        </p>
        <p className="md:text-xl text-[18px] font-[500] text-center mb-8">
          Hot Off the Press: Dive into the Latest News and Exciting Campus Events!
        </p>
      </div>

      {/* Featured News */}
      <div className="py-10">
        <div className="flex flex-col md:flex-row items-center relative">
          <div className="bg-gray-500 flex-shrink-0 h-72 w-full max-w-xs rounded-xl overflow-hidden">
            <img
              src={dummyFeaturedNews.picture || college_logo}
              alt="news-image"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-card-container bg-white rounded-xl p-5 border-2 relative -translate-y-10 md:-translate-x-10 mt-auto z-10 md:z-50">
            <img
              src={Arrow}
              alt="Arrow Icon"
              className="news-arrow-icon absolute right-0 top-0 mt-2 mr-2"
            />
            <div className="badge-container flex mb-2 justify-center md:justify-start">
              <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl">
                FEATURED NEWS
              </p>
            </div>
            <div>
              <p className="text-black text-lg font-semibold mt-5">
                {dummyFeaturedNews.title}
              </p>
              <p className="text-xs font-medium mt-2 mb-3">
                {new Date(dummyFeaturedNews.announcement_date).toLocaleDateString()}
              </p>
              <p className="text-base mt-2 news_display_content">
                {dummyFeaturedNews.content}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-10 flex flex-col lg:flex-row gap-8 max-w-screen-xl mx-auto">
        {/* Recent News Section */}
        <div className="flex-item mb-8 lg:mb-0">
          <p className="text-black text-base font-bold mb-6 text-center lg:text-left leading-[26px] lg:leading-normal">
            Recent News
          </p>
          <div className="flex flex-col gap-12">
          {recentNews.length > 0 ? (
    recentNews.slice(0, 2).map((news, index) => (
      <NewsCard
      key={index}
      id={news.id}
      title={news.title}
      desc={news.content}
      image={`${Img_URL}/${news.picture}` || college_logo}
      date={new Date(news.announcement_date).toLocaleDateString()}
      className="w-full h-auto"
    />
    
    ))
  ) : (
              <>
                <NewsCard
                  title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  desc={"Lorem ipsum odor amet, consectetuer adipiscing elit."}
                  date={"October 23, 2024"}
                  image={Graphic}
                  className="w-full h-auto"
                />
                <NewsCard
                  title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  desc={"Lorem ipsum odor amet, consectetuer adipiscing elit."}
                  date={"October 23, 2024"}
                  image={Graphic}
                  className="w-full h-auto"
                />
              </>
            )}
          </div>
        </div>

        {/* Upcoming Events Section */}
        <div className="flex-item mb-8 lg:mb-0">
          <p className="text-black text-base font-bold mb-6 text-center lg:text-left leading-[26px] lg:leading-normal">
            Upcoming Events
          </p>
          <div className="bg-white flex flex-col w-full py-5 rounded-[20px] border-2 border-gray-200">
            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((event, index) => (
                <div key={index}>
                  <EventCard
                    title={event.event_name}
                    subtitle={event.event_details}
                    location={event.event_location}
                    date={new Date(event.event_date).getDate()}
                    month={new Date(event.event_date).toLocaleString('default', { month: 'short' }).toUpperCase()}
                  />
                  {/* Horizontal border line between event cards */}
                  {index < upcomingEvents.length - 1 && <hr className="border-gray-300 my-2" />}
                </div>
              ))
            ) : (
              <>
                <EventCard
                  title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  location={"Lorem ipsum odor amet"}
                  date={30}
                  month={"AUG"}
                  index={0}
                />
                <hr className="border-gray-300 my-2" />
                <EventCard
                  title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  location={"Lorem ipsum odor amet"}
                  date={30}
                  month={"AUG"}
                  index={1}
                />
                <hr className="border-gray-300 my-2" />
                <EventCard
                  title={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  subtitle={"Lorem ipsum odor amet, consectetuer adipiscing elit"}
                  location={"Lorem ipsum odor amet"}
                  date={30}
                  month={"AUG"}
                  index={2}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingNewsEvents;

