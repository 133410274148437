import React from "react";
import { Parser } from "htmlparser2";

const componentMap = {
  1: { name: "Overview" },
  2: { name: "Eligibility" },
  3: { name: "Application Form" },
  4: { name: "Exam Pattern" },
  5: { name: "Syllabus" },
  6: { name: "How To Prepare" },
  7: { name: "Previous Year Papers" },
  8: { name: "Admit Card" },
  9: { name: "Result" },
  10: { name: "Counselling Process" },
};

const ExamData = ({ data, selectedTab }) => {
  console.log("ExamData received:", { data, selectedTab });

  if (!data) {
    return (
      <div className='bg-gray-50'>
        <div className='container mx-auto py-10 px-5'>
          <div className='bg-white p-6 shadow rounded-lg'>
            <p>Loading exam data...</p>
          </div>
        </div>
      </div>
    );
  }

  const getContentForTab = () => {
    console.log("Getting content for tab:", selectedTab);
    
    switch (selectedTab) {
      case 1:
        return data.overview || "";
      case 2:
        return data.eligibility || "";
      case 3:
        return data.application_form || "";
      case 4:
        return data.exam_pattern || "";
      case 5:
        return data.syllabus || "";
      case 6:
        return data.how_to_prepare || "";
      case 7:
        return data.prev_year_paper || "";
      case 8:
        return data.admit_card || "";
      case 9:
        return data.result || "";
      case 10:
        return data.counselling_process || "";
      default:
        return data.overview || "";
    }
  };

  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag(tagname) {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return item;
      }

      const Tag = item.name;

      // Special handling for void elements like 'br'
      const isVoidElement = [
        "br",
        "img",
        "hr",
        "input",
        "meta",
        "link",
      ].includes(Tag);

      const props = {
        ...item.attributes,
        key: index,
        className:
          Tag === "table"
            ? "min-w-full border-collapse border border-gray-300 my-4"
            : "",
      };

      if (Tag === "tr") {
        props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
      }

      if (Tag === "td" || Tag === "th") {
        props.className = "border border-gray-300 px-4 py-2";
      }

      // For void elements, don't pass children
      if (isVoidElement) {
        return React.createElement(Tag, props);
      }

      // For non-void elements, pass children as before
      return React.createElement(
        Tag,
        props,
        item.children && renderContent(item.children)
      );
    });
  };

  const content = getContentForTab();
  const parsedHtml = content ? parseHtml(content) : [];

  return (
    <div className='bg-gray-50 h-screen'>
      <div className='container mx-auto py-10 px-5'>
        <section className='bg-white p-6 shadow rounded-lg'>
          <h2 className='text-2xl font-bold mb-4'>
            {data.exam_master_name} {componentMap[selectedTab]?.name}
          </h2>
          {content ? (
            <div>{renderContent(parsedHtml)}</div>
          ) : (
            <p>No content available for this section.</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default ExamData;
