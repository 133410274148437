import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';

const EditCourseMasterDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const details = location.state; 
  const token = useSelector((state) => state.auth.token); // Get token from Redux state

  const [formData, setFormData] = useState({
    course_master_name: details.course_master_name || '',
    course_master_id: details.course_master_id,
    overview: details.overview || '',
    jobs: details.jobs || '',
    salary: details.salary || '',
    semester: details.semester || '',
    avg_fee: details.avg_fee || '',
    intro: details.intro || '',
    mode_master_id: details.mode_master_id || null,
    duration_master_id: details.duration_master_id || null,
  });

  const [loading, setLoading] = useState(false); // Track loading state
  const [modes, setModes] = useState([]);
  const [duration, setDuration] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const modeResponse = await axios.get(`${Base_URL}/get/mode_master`);
        setModes(modeResponse.data.map((mode) => ({
          value: mode.id,
          label: mode.mode_title,
        })));

        const durationResponse = await axios.get(`${Base_URL}/get/duration_master`);
        setDuration(durationResponse.data.map((duration) => ({
          value: duration.id,
          label: duration.duration_title,
        })));
      } catch (error) {
        console.error('Failed to fetch options:', error);
        toast.error('Failed to load options.');
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    const payload = { ...formData };
    delete payload.course_master_name;

    try {
      await axios.put(
        `${Base_URL}/update/course_master_details/${details.course_master_details_id}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Details updated successfully!');
      setTimeout(() => {
        navigate('/admin/courseMasterDetails/all');
      }, 2000);
    } catch (error) {
      toast.error('Failed to update Details.');
      console.error('Failed to update Details:', error.response?.data || error.message);
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle="Exams" subTitle="Edit Level" />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="course_master_name" className="mb-2 font-medium text-sm">
                  Course Name
                </label>
                <input
                  type="text"
                  id="course_master_name"
                  name="course_master_name"
                  value={formData.course_master_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  disabled
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-medium text-sm">Select Mode</label>
                <Select
                  options={modes}
                  value={modes.find((mode) => mode.value === formData.mode_master_id)}
                  onChange={handleSelectChange('mode_master_id')}
                  placeholder="Select Mode"
                  className="basic-single"
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-medium text-sm">Select Duration</label>
                <Select
                  options={duration}
                  value={duration.find((duration) => duration.value === formData.duration_master_id)}
                  onChange={handleSelectChange('duration_master_id')}
                  placeholder="Select Duration"
                  className="basic-single"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="intro" className="mb-2 font-medium text-sm">Brief Intro</label>
                <input
                  type="text"
                  id="intro"
                  name="intro"
                  value={formData.intro}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="semester" className="mb-2 font-medium text-sm">Total Semesters</label>
                <input
                  type="text"
                  id="semester"
                  name="semester"
                  value={formData.semester}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="avg_fee" className="mb-2 font-medium text-sm">Average Fees</label>
                <input
                  type="text"
                  id="avg_fee"
                  name="avg_fee"
                  value={formData.avg_fee}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="overview" className="mb-2 font-medium text-sm">Course Overview</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.overview}
                  onChange={(event, editor) => setFormData((prev) => ({ ...prev, overview: editor.getData() }))}
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="jobs" className="mb-2 font-medium text-sm">Course Related Jobs</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.jobs}
                  onChange={(event, editor) => setFormData((prev) => ({ ...prev, jobs: editor.getData() }))}
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="salary" className="mb-2 font-medium text-sm">Salary Range</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.salary}
                  onChange={(event, editor) => setFormData((prev) => ({ ...prev, salary: editor.getData() }))}
                />
              </div>
            </div>

            <button
              type="submit"
              className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Update Course Details"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCourseMasterDetails;
