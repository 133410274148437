// import React, { useEffect, useState } from 'react';
// import './process.css';
// import register from '../../../assets/landing/register.png';
// import select from '../../../assets/landing/select.png';
// import fill from '../../../assets/landing/fill.png';
// import upload from '../../../assets/landing/upload.png';
// import pay from '../../../assets/landing/pay.png';
// import confirm from '../../../assets/landing/confirm.png';

// const Process = () => {
//     const [activeStep, setActiveStep] = useState(0);

//     useEffect(() => {
//         // Timer to change steps
//         const timer = setInterval(() => {
//             setActiveStep((prevStep) => {
//                 if (prevStep < 6) return prevStep + 1;
//                 return 0; // Restart from step 0 after completion
//             });
//         }, 2000);

//         return () => clearInterval(timer); // Cleanup on component unmount
//     }, []);

//     return (
//         <div className="px-5 py-8">
//             <p className="text-4xl text-center font-bold mb-8">Apply to colleges quickly and easily through our streamlined platform.</p>
//             <p className="text-center text-lg font-medium mb-8">Navigate the application process effortlessly with our comprehensive guide, ensuring you meet every requirement with ease</p>
            // <div className="process-step-container flex flex-wrap items-center justify-center gap-2 mb-5">
            //     {[{ title: "Register", img: register }, { title: "Select Course", img: select }, { title: "Fill Application", img: fill }, { title: "Upload Documents", img: upload }, { title: "Make Payment", img: pay }, { title: "Admission Confirmed", img: confirm }].map((step, index) => (
            //         <React.Fragment key={index}>
            //             <div className={`flex items-center gap-2 border-2 process-step step-${index + 1} ${activeStep >= index + 1 ? 'filled' : ''}`}>
            //                 <div className="text-container">
            //                     <p className="text-base font-semibold">{step.title}</p>
            //                     <div className="w-[20px] h-[20px]">
            //                         <img src={step.img} alt={step.title} className="w-full h-full" />
            //                     </div>
            //                 </div>
            //             </div>
            //             {index < 5 && (
            //                 <div className={`arrow-container ${activeStep > index ? 'show-arrow' : ''}`}>
            //                     <div className="arrow"></div>
            //                 </div>
            //             )}
            //         </React.Fragment>
            //     ))}
            // </div>

// {/* //             <div className="flex flex-wrap gap-8 justify-center py-5">
// //                 {[...Array(6)].map((_, index) => (
// //                     <div key={index} className="px-3 py-5 bg-[#DBFDC3] rounded-xl w-[350px] process-box">
// //                         <p className="text-base font-bold">Lorem ipsum odor amet</p>
// //                         <p className="text-base font-medium">Lorem ipsum odor amet, consectetuer adipiscing elit. Senectus neque felis a tristique velit metus.</p>
// //                     </div>
// //                 ))} */}
// <div className="flex flex-wrap gap-8 justify-center py-5">
// {[{ title: "Discover Your Ideal Career", description: "Find the career path that perfectly aligns with your skills and interests." },
//   { title: "Connect with Admission Advisors", description: "Get personalized advice from experts to make informed decisions." },
//   { title: "Centralized Application Tracking", description: "Easily monitor the status of all your applications in one dashboard." },
//   { title: "Earn Exciting Rewards", description: "Earn rewards and incentives as you progress through the application process." },
//   { title: "Effortless Application Process", description: "Submit your applications quickly and effortlessly with our streamlined process." },
//   { title: "Trusted College Listings", description: "Explore a curated list of trusted and verified colleges." }].map((card, index) => (
//     <div key={index} className="px-3 py-5 bg-[#DBFDC3] rounded-xl w-[350px] process-box">
//         <p className="text-base font-bold">{card.title}</p>
//         <p className="text-base font-medium">{card.description}</p>
//     </div>
//   ))}
//         </div>
//         </div>
//     );
// }

//  export default Process;
import React, { useEffect, useState } from 'react';
import './process.css';
import register from '../../../assets/landing/register.png';
import select from '../../../assets/landing/select.png';
import fill from '../../../assets/landing/fill.png';
import upload from '../../../assets/landing/upload.png';
import pay from '../../../assets/landing/pay.png';
import confirm from '../../../assets/landing/confirm.png';

const Process = () => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        // Timer to change steps
        const timer = setInterval(() => {
            setActiveStep((prevStep) => {
                if (prevStep < 6) return prevStep + 1;
                return 0; // Restart from step 0 after completion
            });
        }, 2000);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);

    return (
        <div className="px-4 py-6 md:px-6 md:py-8 lg:px-8 lg:py-10">
            <p className="text-[28px] md:text-[36px] lg:text-[36px] text-center font-bold font-sans mb-12 md:mb-8">
                Apply to colleges quickly and easily through our streamlined platform.
            </p>
            <p className="text-base md:text-lg lg:text-xl text-center font-medium font-sans mb-12 md:mb-8">
                Navigate the application process effortlessly with our comprehensive guide, ensuring you meet every requirement with ease.
            </p>
            <div className="process-step-container flex flex-wrap items-center justify-center  font-sans gap-2 mb-5">
                {[{ title: "Register", img: register }, { title: "Select Course", img: select }, { title: "Fill Application", img: fill }, { title: "Upload Documents", img: upload }, { title: "Make Payment", img: pay }, { title: "Admission Confirmed", img: confirm }].map((step, index) => (
                    <React.Fragment key={index}>
                        <div className={`flex items-center gap-2 border-2 process-step step-${index + 1} ${activeStep >= index + 1 ? 'filled' : ''}`}>
                            <div className="text-container">
                                <p className="text-base font-semibold">{step.title}</p>
                                <div className="w-[15px] h-[15px]">
                                    <img src={step.img} alt={step.title} className="w-full h-full" />
                                </div>
                            </div>
                        </div>
                        {index < 5 && (
                            <div className={`arrow-container ${activeStep > index ? 'show-arrow' : ''}`}>
                                <div className="arrow"></div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>

            <div className="py-5">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 justify-center ">
    {[
      { title: "Discover Your Ideal Career", description: "Find the career path that perfectly aligns with your skills and interests." },
      { title: "Connect with Admission Advisors", description: "Get personalized advice from experts to make informed decisions." },
      { title: "Centralized Application Tracking", description: "Easily monitor the status of all your applications in one dashboard." },
      { title: "Earn Exciting Rewards", description: "Earn rewards and incentives as you progress through the application process." },
      { title: "Effortless Application Process", description: "Submit your applications quickly and effortlessly with our streamlined process." },
      { title: "Trusted College Listings", description: "Explore a curated list of trusted and verified colleges." }
    ].map((card, index) => (
      <div
        key={index}
        className="px-4 py-5 bg-[#DBFDC3] rounded-xl " style={{ boxShadow: '0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2)' }}
      >
        <p className=" text-center text-base font-medium sm:text-left">{card.title}</p>
        <p className=" text-center text-base font-medium sm:text-left">{card.description}</p>
      </div>
    ))}
  </div>
</div>

        </div>
    );
}

export default Process;
