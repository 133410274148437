import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSortUp, FaSortDown, FaSort,FaArrowDown ,FaArrowUp} from 'react-icons/fa'; // Import sorting icons
import { IoArrowDownOutline ,IoArrowUpOutline} from "react-icons/io5";

const AllEvents = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // Sorting state
  const navigate = useNavigate();

  // Get login_id from Redux store or localStorage
  const loginData = useSelector((state) => state.collegeAuth.data);
  const login_id = loginData?.login_id || localStorage.getItem('login_id');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (!login_id) throw new Error('login_id not found');

        const response = await axios.get(`${Base_URL}/event/organizer/${login_id}`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchEvents();
  }, [login_id, navigate]);

  const handleEditClick = (event) => navigate(`/dashboard/events/edit/${event.event_id}`, { state: { event } });

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${Base_URL}/event/${id}`);
      setEvents((prevEvents) => prevEvents.filter((event) => event.event_id !== id));
      toast.success('Event deleted successfully.');
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Failed to delete event.');
    }
  };

  const handlePrevious = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const handleNext = (totalPages) => currentPage < totalPages && setCurrentPage(currentPage + 1);

  // Sort logic for sorting the data based on selected column and direction
  const sortedEvents = useMemo(() => {
    let sortableEvents = [...events];
    if (sortConfig !== null && sortConfig.key) {
      sortableEvents.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Special handling for event_date and event_time
        if (sortConfig.key === 'event_date') {
          aValue = new Date(a[sortConfig.key]);
          bValue = new Date(b[sortConfig.key]);
        }

        if (sortConfig.key === 'event_time') {
          aValue = new Date(`1970-01-01T${a[sortConfig.key]}`);
          bValue = new Date(`1970-01-01T${b[sortConfig.key]}`);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEvents;
  }, [events, sortConfig]);

  // Handle sorting column and direction
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Filter events based on the search term
  const filteredEvents = useMemo(
    () => sortedEvents.filter((event) => event.event_name.toLowerCase().includes(searchTerm.toLowerCase())),
    [sortedEvents, searchTerm]
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredEvents.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredEvents.length / entriesPerPage);

  const tableCellStyle = {
    color: 'rgb(115, 123, 139)',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13.125px',
    fontWeight: 400,
    lineHeight: '19.6px',
    padding: '9.1px 13.125px',
    borderBottomColor: 'rgb(230, 230, 230)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <IoArrowUpOutline />
      }
      if (sortConfig.direction === 'descending') {
        return <IoArrowDownOutline/>
      }
    }
    return <FaSort color='lightgrey' />;
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Events" breadcrumb={['Events', 'All Events']} />

      <div className="bg-white p-6 rounded-lg shadow mb-4">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="text-gray-800 font-poppins text-lg font-semibold">All Events</div>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            onClick={() => navigate('/dashboard/events/add')}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {['event_name', 'event_date', 'event_time', 'event_location', 'event_details'].map((key, index) => (
                  <th
                    key={key}
                    className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer"
                    onClick={() => handleSort(key)}
                  >
                 <div className="flex flex-row items-center  ">
                 <div > {['Name', 'Date', 'Time', 'Location', 'Event Details'][index]}</div>
                 <div className='ml-1'> {getSortIcon(key)}</div>
                 </div>

                  </th>
                ))}
                <th className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((event) => (
                  <tr key={event.event_id} className="hover:bg-gray-50 border-b">
                    <td style={tableCellStyle}>{event.event_name}</td>
                    <td style={tableCellStyle}>{new Date(event.event_date).toISOString().split('T')[0]}</td>
                    <td style={tableCellStyle}>
                      {new Date(`1970-01-01T${event.event_time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                    </td>
                    <td style={tableCellStyle}>{event.event_location}</td>
                    <td style={tableCellStyle}>{event.event_details}</td>
                    <td style={tableCellStyle}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(event)}
                          className="p-2 rounded bg-[#41B923] text-white hover:bg-[#5BDE50]"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(event.event_id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center py-4">
                    No events found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default AllEvents;