import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { FaBell } from "react-icons/fa";
import SortIcon from "../../../assets/landing/SortIcon.png";
import filter from "../../../assets/landing/filter.png";
import nodata from "../../../assets/landing/no_data.png";
import { Base_URL } from "../../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";

const AllExams = () => {
  const [selectedFilters, setSelectedFilters] = useState({
    mode_id: [],
    program_id: [],
    stream_id: [],
    level_id: [],
  });

  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [activeMobileFilter, setActiveMobileFilter] = useState("Modes");
  const [examsData, setExamsData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [filterData, setFilterData] = useState({
    modes: [],
    levels: [],
    streams: [],
    degrees: [],
  });

  const navigate = useNavigate();
  const { filters } = useParams();

  // Fetch initial data
  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const [modesRes, levelsRes, streamsRes, programsRes] =
          await Promise.all([
            axios.get(`${Base_URL}/get/mode_master`),
            axios.get(`${Base_URL}/get/level_master`),
            axios.get(`${Base_URL}/get/streams`),
            axios.get(`${Base_URL}/get/program_master`),
          ]);
          
        setFilterData({
          modes: modesRes.data,
          levels: levelsRes.data,
          streams: streamsRes.data,
          degrees: programsRes.data,
        });
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    const fetchExams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/exam_master`);
        setExamsData(response.data);
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };

    fetchFilterData();
    fetchExams();
  }, []);

  // Add this function to fetch all exams
  const fetchAllExams = async () => {
    try {
      const response = await axios.get(`${Base_URL}/get/exam_master`);
      setExamsData(response.data);
      setFilteredData(null); // Reset filtered data
    } catch (error) {
      console.error("Error fetching all exams:", error);
    }
  };

  // Handle filters change
  useEffect(() => {
    const isAllFiltersEmpty = Object.values(selectedFilters).every(
      (arr) => arr.length === 0
    );

    const fetchFilteredExams = async () => {
      try {
        if (isAllFiltersEmpty) {
          // If no filters are active, fetch all exams
          const response = await axios.get(`${Base_URL}/get/exam_master`);
          setExamsData(response.data);
          setFilteredData(null); // Reset filtered data
          navigate('/exams/all', { replace: true });
          return;
        }

        // Create filter payload for active filters
        const filterPayload = [
          { mode_id: selectedFilters.mode_id },
          { program_id: selectedFilters.program_id },
          { stream_id: selectedFilters.stream_id },
          { level_id: selectedFilters.level_id }
        ].filter(filter => filter[Object.keys(filter)[0]].length > 0);

        const queryString = JSON.stringify(filterPayload);
        const response = await axios.get(
          `${Base_URL}/exam-master/filter?filter=${queryString}`
        );

        // Set filtered data and clear examsData
        setFilteredData(response.data.length > 0 ? response.data : null);
        setExamsData([]); // Clear all exams data when showing filtered results

        // Update URL with filters
        const filters = {
          mode: selectedFilters.mode_id.join(','),
          program: selectedFilters.program_id.join(','),
          stream: selectedFilters.stream_id.join(','),
          level: selectedFilters.level_id.join(',')
        };

        const urlParams = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
          if (value) urlParams.append(key, value);
        });

        navigate(`/exams/filter/${urlParams.toString()}`, { replace: true });
      } catch (error) {
        console.error("Error fetching exams:", error);
        setFilteredData(null);
      }
    };

    fetchFilteredExams();
  }, [selectedFilters, navigate]);

  // Add this useEffect to handle initial filter state from URL
  useEffect(() => {
    if (filters && filters !== "all") {
      const urlParams = new URLSearchParams(filters);
      const initialFilters = {
        mode_id: [],
        program_id: [],
        stream_id: [],
        level_id: [],
      };

      // Map URL parameters to filter state
      if (urlParams.get("mode"))
        initialFilters.mode_id = urlParams.get("mode").split(",").map(Number);
      if (urlParams.get("program"))
        initialFilters.program_id = urlParams
          .get("program")
          .split(",")
          .map(Number);
      if (urlParams.get("stream"))
        initialFilters.stream_id = urlParams
          .get("stream")
          .split(",")
          .map(Number);
      if (urlParams.get("level"))
        initialFilters.level_id = urlParams.get("level").split(",").map(Number);

      setSelectedFilters(initialFilters);
    }
  }, [filters]);

  const handleCheckboxChange = (filterType, value) => {
    const filterMapping = {
      'Modes': 'mode_id',
      'Streams': 'stream_id',
      'Levels': 'level_id',
      'Degrees': 'program_id'
    };

    const key = filterMapping[filterType];
    if (key) {
      setSelectedFilters(prev => {
        const newFilters = {
          ...prev,
          [key]: prev[key].includes(value)
            ? prev[key].filter(item => item !== value)
            : [...prev[key], value]
        };

        // If all filters are empty after this change, reset filtered data
        if (Object.values(newFilters).every(arr => arr.length === 0)) {
          setFilteredData(null);
        }

        return newFilters;
      });
    }
  };

  const handleClearAllFilters = (filter) => {
    const filterMapping = {
      Modes: "mode_id",
      Streams: "stream_id",
      Levels: "level_id",
      Degrees: "program_id",
    };

    const key = filterMapping[filter];
    if (key) {
      setSelectedFilters((prev) => {
        const newFilters = {
          ...prev,
          [key]: [],
        };

        // Check if all filters are empty after clearing
        const isAllEmpty = Object.values(newFilters).every(
          (arr) => arr.length === 0
        );

        if (isAllEmpty) {
          // If all filters are empty, fetch all exams
          fetchAllExams();
          window.history.pushState(null, "", "/exams/all");
        }

        return newFilters;
      });
    }
  };

  // Determine what data to display
  const displayData = filteredData || (Object.values(selectedFilters).every(arr => arr.length === 0) && examsData);

  return (
    <>
      <Header />
      <div className='container mx-auto px-4 py-16 mt-20 flex flex-col lg:flex-row gap-6'>
        {/* Left Filter Section */}
        <div className='w-full hidden md:block lg:w-1/4 font-dm-sans space-y-6'>
          {Object.entries(filterData).map(([category, options]) => (
            <div
              key={category}
              className='p-4 border rounded-lg shadow-lg h-[300px] flex flex-col bg-[#FCFCFC]'
            >
              <div className='flex justify-between items-center mb-4'>
                <h3 className='font-dm-sans text-sm font-bold'>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </h3>
                <span
                  className='font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer'
                  onClick={() =>
                    handleClearAllFilters(
                      category.charAt(0).toUpperCase() + category.slice(1)
                    )
                  }
                >
                  Clear All
                </span>
              </div>
              <input
                type='text'
                className='w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-4'
                placeholder={`Search ${category}`}
              />
              <div className='overflow-y-auto flex-1'>
                <ul className='space-y-2 pr-2'>
                  {options.map((option) => (
                    <li
                      key={option.id}
                      className='flex items-center text-[14px] font-dm-sans font-[700] py-1'
                    >
                      <input
                        type='checkbox'
                        className='mr-2'
                        checked={selectedFilters[
                          getCategoryKey(category)
                        ]?.includes(option.id)}
                        onChange={() =>
                          handleCheckboxChange(
                            category.charAt(0).toUpperCase() +
                              category.slice(1),
                            option.id
                          )
                        }
                      />
                      <span className='truncate'>
                        {getDisplayName(category, option)}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Main Content Section */}
        <div className='w-full lg:w-3/4 flex flex-col gap-8 lg:p-2'>
          <div className='flex justify-center mb-8'>
            <h2 className='font-dm-sans text-2xl lg:text-3xl font-bold text-center'>
              {filteredData ? "Filtered Entrance Exams" : "All Entrance Exams"}
            </h2>
          </div>

          {/* Updated condition for displaying content */}
          {!displayData ||
          (Object.values(selectedFilters).some((arr) => arr.length > 0) &&
            !filteredData) ? (
            <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full'>
              <img src={nodata} alt='No data' className='w-40 h-40 mb-4' />
              <p className='text-lg font-semibold text-gray-600'>
                No exams found.
              </p>
              <div
                className='pt-2 text-[#468EFF] cursor-pointer'
                onClick={() => {
                  navigate("/exams/all");
                  setSelectedFilters({
                    mode_id: [],
                    program_id: [],
                    stream_id: [],
                    level_id: [],
                  });
                  fetchAllExams();
                }}
              >
                View all
              </div>
            </div>
          ) : (
            <div className='flex flex-col items-center gap-8'>
              <div className='flex flex-col gap-8 w-full lg:max-w-6xl'>
                {displayData.map((exam) => (
                  <ExamCard key={exam.id} exam={exam} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Rest of your component (mobile filters, etc.) */}
    </>
  );
};

// Helper function to get display name for different types of data
const getDisplayName = (category, option) => {
  switch (category) {
    case "streams":
      return option.stream_name;
    case "modes":
      return option.mode_title;
    case "levels":
      return option.level_name;
    case "degrees":
      return option.program_master_name;
    default:
      return option.name;
  }
};

// Add this helper function near the top of the file, with other helper functions
const getCategoryKey = (category) => {
  const mapping = {
    streams: "stream_id",
    modes: "mode_id",
    levels: "level_id",
    degrees: "program_id",
  };
  return mapping[category.toLowerCase()];
};

// Add the ExamCard component
const ExamCard = ({ exam }) => {
  const navigate = useNavigate();

  const handleButtonClick = (tabName) => {
    const tabMapping = {
      Overview: 1,
      Eligibility: 2,
      "Application Form": 3,
      "Exam Pattern": 4,
      Syllabus: 5,
    };

    navigate(`/exams/${exam.id}`, {
      state: {
        selectedTab: tabMapping[tabName],
      },
    });
  };

  return (
    <div className='relative border w-full rounded-lg shadow-lg p-5 flex flex-col lg:flex-row md:items-center items-start'>
      <div className='w-full flex flex-col justify-between p-4 relative'>
        <div className='flex justify-between items-center mb-4'>
          <div>
            <h3 className='font-dm-sans text-lg font-bold cursor-pointer'>
              {exam.exam_master_name}
            </h3>
            <p className='text-gray-600 text-sm mt-1'>
              Last Updated: {new Date(exam.updated_on).toLocaleDateString()}
            </p>
          </div>
          <button className='bg-[#3ACA2E] text-white py-2 px-4 rounded-[20px] flex items-center gap-2 text-sm font-bold whitespace-nowrap'>
            <FaBell />
            Set Exam Alert
          </button>
        </div>
        <div className='mt-6 flex flex-wrap gap-4'>
          <ExamButton
            title='Overview'
            onClick={() => handleButtonClick("Overview")}
          />
          <ExamButton
            title='Eligibility'
            onClick={() => handleButtonClick("Eligibility")}
          />
          <ExamButton
            title='Application Form'
            onClick={() => handleButtonClick("Application Form")}
          />
          <ExamButton
            title='Exam Pattern'
            onClick={() => handleButtonClick("Exam Pattern")}
          />
          <ExamButton
            title='Syllabus'
            onClick={() => handleButtonClick("Syllabus")}
          />
        </div>
      </div>
    </div>
  );
};

// Add the ExamButton component
const ExamButton = ({ title, onClick }) => (
  <button
    className='bg-white border border-[#3ACA2E] text-black text-sm px-4 py-2 rounded-[20px] font-dm-sans font-bold hover:bg-[#3ACA2E] hover:text-white transition-colors duration-300'
    onClick={onClick}
  >
    {title}
  </button>
);

export default AllExams;
