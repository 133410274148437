import React, { useState, useEffect,useRef } from 'react';
import Header from '../../components/Header/Header';
import { Base_URL } from '../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Parser } from 'htmlparser2';
import Footer from '../../components/Footer/Footer';

const CourseDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { courseName, courseId } = location.state || {};
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState('overview');
  const [courses, setCourses] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0); // Track last scroll position
  const [originalTop, setOriginalTop] = useState(0); // Track original top position
  const subheaderRef = useRef(null);

  
  useEffect(() => {
  
    // Set the original position after the component mounts
    if (subheaderRef.current) {
      setOriginalTop(subheaderRef.current.getBoundingClientRect().top + window.scrollY);
    }

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // Toggle sticky state only when crossing original position threshold
      if (currentScrollY >= originalTop - 90 && !isSticky) {
        setIsSticky(true);
      } else if (currentScrollY < originalTop - 90 && isSticky) {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalTop, isSticky]);

  const fetchCourses = async () => {
    try {
      const response = await axios.post(`${Base_URL}/get-with-joins/course_master`, {
        joins: [
          {
            joinTable: 'course_master_details',
            joinCondition: 'course_master.id=course_master_details.course_master_id',
          },
        ],
      });
      const filteredCourses = response.data.filter(course => course.course_master_id === parseInt(id, 10));
      setCourses(filteredCourses[0]);
    } catch (error) {
      toast.error('Failed to fetch courses');
      console.error('Failed to fetch courses:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [id]);

  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag(tagname) {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return item;
      }
  
      const Tag = item.name;
      const isVoidElement = ['br', 'img', 'input', 'hr'].includes(Tag);
      const props = {
        ...item.attributes,
        key: index,
        className: Tag === 'table' ? 'min-w-full border-collapse border border-gray-300 my-4' : '',
      };
  
      if (Tag === 'tr') {
        props.className = index % 2 === 0 ? 'bg-gray-100' : 'bg-white';
      }
  
      if (Tag === 'td' || Tag === 'th') {
        props.className = 'border border-gray-300 px-4 py-2';
      }
  
      return isVoidElement
        ? React.createElement(Tag, props)
        : React.createElement(Tag, props, item.children && renderContent(item.children));
    });
  };
  

  const renderTabContent = () => {
    if (!courses) {
      return <p>Loading...</p>;
    }

    const content = activeTab === 'overview' ? courses.overview
                   : activeTab === 'jobs' ? courses.jobs
                   : activeTab === 'salary' ? courses.salary
                   : '';

    if (!content) {
      return <p>No content available</p>;
    }

    const parsedHtml = parseHtml(content);
    return renderContent(parsedHtml);
  };

  return (
    <>
      <Header />
      <div className="py-2 mt-10">
        {/* Title Section */}
        <section
          className="py-8 mt-8 border-b-2 border-gray-200 w-full"
          style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold">
              {courseName || courses.course_master_name}
            </h2>
          </div>
        </section>

        {/* Tabs Navigation - Made Full Width */}
        <div className="w-full border-b-2 border-gray-200 shadow-sm">
          <div className=" mx-auto">
            <div ref={subheaderRef} className={` subheader ${isSticky ? 'fixed' : ''} flex space-x-8 justify-center py-3`}>
              {['Overview', 'Jobs', 'Salary', 'View all Courses'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => {
                    if (tab === 'View all Courses') {
                      navigate('/courses/all');
                    } else {
                      setActiveTab(tab.toLowerCase());
                    }
                  }}
                  className={`py-2 px-4 ${
                    activeTab === tab.toLowerCase() ? 'text-[#57CC02] border-b-2 border-[#57CC02]' : 'text-black'
                  } font-dm-sans text-base font-bold transition-colors duration-200 hover:text-[#57CC02]`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="container mx-auto px-4 mt-6">
          <div
            className="bg-[#FAFAFA] shadow-md rounded-lg p-8 max-w-[1000px] mx-auto"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.25)',
            }}
          >
            {/* Content Styling */}
            <div className="prose max-w-none">
              {renderTabContent()}
            </div>
          </div>
        </div>

        {/* Custom styling for rendered content */}
        <style jsx>{`
          .prose h1 {
            font-size: 2em;
            margin-bottom: 1em;
            color: #333;
          }
          .prose h2 {
            font-size: 1.5em;
            margin: 1em 0;
            color: #444;
          }
          .prose p {
            margin: 1em 0;
            line-height: 1.6;
            color: #666;
          }
          .prose ul, .prose ol {
            margin: 1em 0;
            padding-left: 2em;
          }
          .prose li {
            margin: 0.5em 0;
          }
          .prose table {
            width: 100%;
            border-collapse: collapse;
            margin: 1em 0;
          }
          .prose th {
            background-color: #f3f4f6;
            font-weight: bold;
            text-align: left;
          }
          .prose td, .prose th {
            padding: 0.75em;
            border: 1px solid #e5e7eb;
          }
          .prose tr:nth-child(even) {
            background-color: #f9fafb;
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetailPage;
