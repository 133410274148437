import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './top-colleges.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import college_logo from '../../../assets/college_logo.png';
import LPU_logo from '../../../assets/landing/LPU_logo.png';
import Tula_logo from '../../../assets/landing/Tula_logo.png';
import GE_logo from '../../../assets/landing/GE_logo.png';
import CU_logo from '../../../assets/landing/CU_logo.png';
import { Base_URL } from '../../../apiConfig';
import { htmlToText } from 'html-to-text';
import { Img_URL } from '../../../apiConfig';

const logoMap = {
    1: LPU_logo,
    2: Tula_logo,
    3: GE_logo,
    4: CU_logo,
};

const TopColleges = () => {
    const [colleges, setColleges] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchColleges = async () => {
            try {
                const response = await fetch(`${Base_URL}/universities/featured/colleges`);
                const data = await response.json();
                console.log(data); 
                
                // Ensure the data is an array
                if (Array.isArray(data)) {
                    const processedData = data.map(item => ({
                        ...item,
                        pictures: JSON.parse(item.pictures) // Parse the pictures string as JSON
                    }));
                    setColleges(processedData);
                } else {
                    console.error('Unexpected data format:', data);
                    setColleges([]); // Set fallback if not an array
                }
            } catch (error) {
                console.error('Error fetching college data:', error);
                setColleges([]); // Set fallback on error
            }
        };

        fetchColleges();
    }, []);

    const getImageUrl = (universityId) => {
        return logoMap[universityId] || college_logo; 
    };

    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <div className="dot"></div>
                </a>
            );
        },
       
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div>
                <ul className="custom-dots">
                  
                </ul>
            </div>
        ),
    };

    const handleExploreClick = (college) => {
        const universityNameSlug = encodeURIComponent(college.university_name.trim().toLowerCase().replace(/\s+/g, '-')); // Convert name to URL-safe string
        navigate(`/college/${college.university_id}/${universityNameSlug}`, { state: { college } });
    };

   

    return (
        <div className="py-8 px-8">
            <p className="text-center text-[28px] md:text-[36px] font-sans font-[700] mb-5">Top Featured Colleges</p>
            <p className="text-center text-[16px] md:text-[24px] font-sans mb-5">
                Discover our selection of top colleges and universities renowned for their excellence and innovation.
            </p>
            <div className="py-8">
                {Array.isArray(colleges) && colleges.length > 0 ? (
                    <Slider {...settings}>
                        {colleges.map((college, index) => {
                            const imageUrl = getImageUrl(college.university_id); 

                            return (
                                <div key={index} className="flex justify-center items-center mt-6">
                                    <div className="flex items-center md:flex-row flex-col">
                                        <div className="w-[350px] h-[350px] flex-shrink-0 rounded-[20px] overflow-hidden hidden md:block border-4 border-gray-200">
                                            <img
                                                src={`${Img_URL}${college.pictures[0]}`}
                                                alt={college.university_name || "College Image"}
                                                className="w-full h-full object-cover"
                                                onError={(e) => {
                                                    e.target.src = college_logo;
                                                }}
                                            />
                                        </div>
                                        <div className="w-[300px] h-[300px] flex-shrink-0 rounded-[20px] overflow-hidden block md:hidden border-4 border-gray-200">
                                            <img
                                                src={`${Img_URL}${college.pictures[0]}`}
                                                alt={college.university_name || "College Image"}
                                                className="w-full h-full object-cover"
                                                onError={(e) => {
                                                    e.target.src = college_logo;
                                                }}
                                            />
                                        </div>

                                        <div className="mb-[10px] p-5 flex flex-col gap-3 bg-[#F2FCEB] rounded-2xl shadow-lg md:transform md:-translate-x-14 -mt-3.5 md:relative relative md:mt-0 md:w-auto w-[278.51px] h-[267px] shadow-custom">
                                            <p className="text-xl font-bold md:text-2xl">{college.university_name || "University Name Not Available"}</p>
                                            <p className="text-[#757575] font-medium text-md md:text-lg">
                                                {(() => {
                                                    const intro = college.sub_intro || "Introduction not available.";
                                                    const words = htmlToText(intro, { wordwrap: 130 }).split(" ");
                                                    const slicedIntro = words.slice(0, 20).join(" ");
                                                    return words.length > 20 ? `${slicedIntro}...` : slicedIntro; 
                                                })()}
                                            </p>

                                            <div>
                                                <button 
                                                    className="py-[3px] px-5 bg-[#3ACA2E] h-[30px] w-[232px] text-white md:w-[150px] md:h-[40px] font-medium rounded-[20px]" 
                                                    onClick={() => handleExploreClick(college)} 
                                                >
                                                    Explore
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                ) : (
                    <p>No colleges available at the moment.</p>
                )}
      <p className="text-[#57CC02] font-dm-sans sm:text-[12px] md:text-[10px] lg:text-[16px] font-[500] leading-[20.83px] cursor-pointer text-center flex justify-center mt-4"
        onClick={() => navigate('/colleges')}>
    View More
</p>

            </div>
           
  
            
        </div>
    );
};

export default TopColleges;
