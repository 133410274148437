import React, { useState,useEffect } from 'react'
import college_logo from '../../../assets/college_logo.png'
//import Rankings from './Rankings/Rankings'
import { htmlToText } from 'html-to-text'
import CampusFacilities from './CampusFacilities/CampusFacilities'
const Overview =({ collegeDetails,logoPath }) =>{
 
    return(
        <div>
        <div className="bg-[#DCFFC4] px-5 py-10 w-full overflow-x-hidden">
        <div className="flex flex-col items-center justify-center gap-3 px-5 py-2 md:flex-row">
          <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px] flex-shrink-0">
            <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
          </div>
          <p className="text-[24px] text-black font-semibold text-center w-full md:w-auto md:text-left font-bold md:text-3xl">
          {(collegeDetails?.university_name)}
          </p>
        </div>
        
        <div className="py-5 text-black md:text-base text-left font-medium flex flex-col gap-5 w-full">
          <p>
            {htmlToText(collegeDetails?.main_intro) }
          </p>
         
        </div>
      </div>
      

            {/* Rankings */}
            {/* <Rankings /> */}
            <CampusFacilities collegeDetails={collegeDetails} />

           {/* Detailed Description */}
{/* <div className="py-10 px-5 bg-[#DCFFC4] text-black text-base font-medium flex flex-col gap-5">
    <h1 className='font-bold text-lg sm:text-xl'>LPU Admissions 2024</h1>
    <p>
        Candidates seeking admission to LPU are selected based on eligibility for the particular course followed by the score of LPUNEST or any other national-level examination accepted by the university. LPU Admit is the admission portal of Lovely Professional University where students can check their application status. Lovely Professional University Course Admission is open for various UG, PG, Online, Distance Education and Ph.D. courses. 
    </p>
    <h1 className='font-bold text-lg sm:text-xl'>What's New At LPU?</h1>
    <p>
        LPU registration is held in online mode only. Students must visit the official website <a href='https://admission.lpu.in/'>@https://admission.lpu.in/</a> to register for admission. Once the candidate has registered successfully, he or she must log in using the application number/ registered Email ID / registered Mobile number and password sent via Email or SMS. Post that, students have to fill out the application form. The application form can be filled out online or offline.
    </p>
    <h1 className='font-bold text-lg sm:text-xl'>LPU NEST 2024</h1>
    <p>
        LPUNEST Cutoff 2024 is the minimum mark that must be scored by the applicants to secure admission at Lovely Professional University. LPU cutoff is determined through various factors such as the Number of Applicants, Number of Vacant Seats, Average Performance in the Entrance Test and Previous Years’ Cutoff.
    </p>
    <p>
        Students applying for LPUNEST scholarships must qualify for one of the three categories as mentioned below:
        <ul className='list-disc pl-5'>
            <li>LPUNEST Category I Cutoff: 95% Marks or Above</li>
            <li>LPUNEST Category II Cutoff: 90% to 94.9% Marks</li>
            <li>LPUNEST Category III Cutoff: 80% to 89.9% Marks</li>
        </ul>
    </p>
    <p>
        Lovely Professional University conducts its counselling process in online mode for each phase of LPUNEST. Seats are allocated separately for each round of counselling. Candidates who will qualify for LPUNEST 2024 and score more than the required cut-off are called for counselling and seat allocation.
    </p>
</div> */}
<div className="py-10 px-5 bg-[#DCFFC4] text-black text-base font-medium flex mt-7 flex-col gap-5">
    <p>{htmlToText(collegeDetails?.sub_intro )}</p>
   
</div>
</div>
    )
}

export default Overview;
// import React, { useState, useEffect } from 'react';
// import college_logo from '../../../assets/college_logo.png';
// import Rankings from './Rankings/Rankings';
// import { htmlToText } from 'html-to-text';

// const Overview = ({ collegeDetails }) => {
//   const [logoUrl, setLogoUrl] = useState(college_logo);

//   useEffect(() => {
//     console.log('collegeDetails:', collegeDetails); // Log collegeDetails object

//     try {
//       if (collegeDetails?.pictures) {
//         // Assuming pictures is an array and the first element contains the logo path
//         const logoPath = JSON.parse(collegeDetails.pictures)[0].replace(/^\/+/g, ''); // Remove leading slashes if any
//         const dynamicLogoUrl = `https://api.collegedwarka.com/api/files/${logoPath}`;
        
//         console.log('Logo found, using URL:', dynamicLogoUrl); // Log the constructed logo URL
//         setLogoUrl(dynamicLogoUrl);
//       } else {
//         console.log('No logo found, using default logo.'); // Log when default logo is used
//       }
//     } catch (error) {
//       console.error('Error setting the logo URL:', error); // Log any errors that occur
//     }
//   }, [collegeDetails?.pictures]);

//   return (
//     <div>
//       <div className="bg-[#DCFFC4] px-5 py-10 w-full overflow-x-hidden">
//         <div className="flex flex-col items-center justify-center gap-3 px-5 py-2 md:flex-row">
//           <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px] flex-shrink-0">
//             <img
//               src={logoUrl}
//               alt="college-logo"
//               className="h-full w-full object-cover"
//               onError={() => {
//                 console.error(`Error loading image from URL: ${logoUrl}. Using default logo`); // Log the failed URL
//                 setLogoUrl(college_logo); // Fallback to default logo
//               }}
//             />
//           </div>
//           <p className="text-[24px] text-black font-semibold text-center w-full md:w-auto md:text-left font-bold md:text-3xl">
//             {collegeDetails?.university_name || 'No University Name Provided'}
//           </p>
//         </div>

//         <div className="py-5 text-black md:text-base text-left font-medium flex flex-col gap-5 w-full">
//           <p>{htmlToText(collegeDetails?.sub_intro || 'No sub_intro available')}</p>
//           <p>{htmlToText(collegeDetails?.sub_intro || 'No sub_intro available')}</p>
//         </div>
//       </div>

//       {/* Rankings */}
//       <Rankings />

//       {/* Detailed Description */}
//       <div className="py-10 px-5 bg-[#DCFFC4] text-black text-base font-medium flex flex-col gap-5">
//         <p>{htmlToText(collegeDetails?.main_intro || 'No main_intro available')}</p>
//         <p>{htmlToText(collegeDetails?.main_intro || 'No main_intro available')}</p>
//       </div>
//     </div>
//   );
// };

// export default Overview;
