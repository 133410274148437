import React, { useEffect, useState } from 'react';
import { Parser } from 'htmlparser2';
import { useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import ApplicationFormModal from '../../../pages/ApplicationFormModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import EnquiryForm from '../../Forms/EnquiryForm';
const SubCourses = () => {
  const { university_id, id } = useParams();
  const [subCourses, setSubCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [colleges, setColleges] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [discipline, setDiscipline] = useState('');
  const [qualification, setQualification] = useState('');
  const [program, setProgram] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      contact_no: '',
      program: '',
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState('');
  

  useEffect(() => {
    const fetchSubCourseData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${Base_URL}/sub-courses/course/${id}/university/${university_id}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch sub-course data');
        }
  
        const data = await response.json();
  
        if (data.length > 0) {
          setSubCourses(data);
        } else {
          setError('No sub-courses found for the specified course.');
        }
      } catch (error) {
        console.error('Error fetching sub-course data:', error);
        setError('Error fetching sub-course data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchSubCourseData();
    }
  }, [id]);

  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag(tagname) {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return item;
      }

      const Tag = item.name;

      const isVoidElement = [
        "br",
        "img",
        "hr",
        "input",
        "meta",
        "link",
      ].includes(Tag);

      const props = {
        ...item.attributes,
        key: index,
        className: Tag === "table" 
          ? "min-w-full border-collapse border border-gray-300 my-4"
          : "",
      };

      if (Tag === "tr") {
        props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
      }

      if (Tag === "td" || Tag === "th") {
        props.className = "border border-gray-300 px-4 py-2";
      }

      if (isVoidElement) {
        return React.createElement(Tag, props);
      }

      return React.createElement(
        Tag,
        props,
        item.children && renderContent(item.children)
      );
    });
  };
  
const handleInputChange = (e) => {
  setFormData({
      ...formData,
      [e.target.name]: e.target.value,
  });
};

const openModal = (collegeName) => {
  setSelectedCollege(collegeName);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

  if (loading) return <div className="text-center text-black">Loading sub-course details...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <>
      <Header />
      <div className="bg-[#F2FCEB] p-6 min-h-screen" style={{marginTop:"120px"}}>
        {subCourses.map((subCourse) => (
          <div key={subCourse.id} className="bg-white border rounded-lg shadow-lg p-6 text-black mb-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Sub-Course - {subCourse.sub_course_name}</h3>
            </div>

            {/* Course Description */}
            {subCourse.description && (
              <div className="mb-4 prose max-w-none">
                {renderContent(parseHtml(subCourse.description))}
              </div>
            )}

            <div className="relative">
              <table className="min-w-full bg-white border-collapse border border-gray-300">
                <tbody>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Fees</td>
                    <td className="p-4 border border-gray-300">
                      {subCourse.sub_course_fees ? (
                        renderContent(parseHtml(subCourse.sub_course_fees))
                      ) : 'N/A'}
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Duration</td>
                    <td className="p-4 border border-gray-300">
                      {subCourse.sub_course_duration ? (
                        renderContent(parseHtml(subCourse.sub_course_duration))
                      ) : 'N/A'}
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Study Mode</td>
                    <td className="p-4 border border-gray-300">
                      {subCourse.sub_study_mode ? (
                        renderContent(parseHtml(subCourse.sub_study_mode))
                      ) : 'N/A'}
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Exams Accepted</td>
                    <td className="p-4 border border-gray-300">
                      {Array.isArray(subCourse.sub_exams_accepted) ? 
                        subCourse.sub_exams_accepted.join(', ') : 
                        renderContent(parseHtml(subCourse.sub_exams_accepted || ''))}
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Eligibility</td>
                    <td className="p-4 border border-gray-300">
                      {subCourse.sub_eligibility ? (
                        renderContent(parseHtml(subCourse.sub_eligibility))
                      ) : 'N/A'}
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="p-4 border border-gray-300 font-semibold">Available Seats</td>
                    <td className="p-4 border border-gray-300">
                      {subCourse.available_seats ? (
                        renderContent(parseHtml(subCourse.available_seats.toString()))
                      ) : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Apply Button - Added at bottom right */}
              <div className="flex justify-end mt-4">
                <button 
                  className="py-1 px-4 rounded-2xl bg-[#3ACA2E] text-white text-sm font-semibold"
                  onClick={() => openModal(subCourse.sub_course_name)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
                <EnquiryForm
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    selectedCollege={selectedCollege}
                />
            )}
          
            <ToastContainer />
      <Footer />
    </>
  );
};

export default SubCourses;
