import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FaFireAlt } from "react-icons/fa";

const ParticularNews = () => {
  const { id } = useParams(); 
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [trendingNews, setTrendingNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNewsById = async () => {
      try {
        const response = await axios.get(
          `https://api.collegedwarka.com/api/get/news_announcements/${id}`
        );
        setNewsItem(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news item:", error);
        setLoading(false);
      }
    };

    const fetchTrendingNews = async () => {
      try {
        const response = await axios.get(
          "https://api.collegedwarka.com/api/get/news_announcements"
        );
        // Get all trending news without limiting to 3
        const trendingItems = response.data.filter(news => news.trending_news === 1);
        setTrendingNews(trendingItems);
      } catch (error) {
        console.error("Error fetching trending news:", error);
      }
    };

    fetchNewsById();
    fetchTrendingNews();
  }, [id]);

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        // If at start, go to last possible set of 3
        return Math.max(0, Math.floor((trendingNews.length - 1) / 3) * 3);
      }
      // Move back by 3
      return Math.max(0, prevIndex - 3);
    });
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 3;
      // If next index would exceed array length, go back to start
      if (nextIndex >= trendingNews.length) {
        return 0;
      }
      return nextIndex;
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!newsItem) {
    return <div>News item not found</div>;
  }

  const formattedCreatedDate = new Date(newsItem.created_at).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const formattedCreatedTime = new Date(newsItem.created_at).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="w-full">
      <Header />

      <div
        className="w-full py-8"
        style={{
          background: "linear-gradient(90deg, #57CC02 67%, #FFFFFF 100%)",
          marginTop: "100px",
          border: "1px solid #00000040",
        }}
      >
        <h1 className="text-left text-white text-[16px] font-bold px-6">
          {newsItem.title}
        </h1>
        <p className="text-left text-white text-sm px-6 mt-2">
          Created on {formattedCreatedDate} at {formattedCreatedTime}
        </p>
      </div>

      <div className="w-full max-w-6xl mx-auto p-6 mt-12">
        <div className="bg-[#FAFAFA] p-8 mb-6 rounded-lg border border-[#00000040] shadow-md">
          <h1 className="text-2xl font-bold">{newsItem.title}</h1>

          <div className="mt-4 text-gray-700 font-[600] leading-relaxed break-words">
            <p>{newsItem.content}</p>
          </div>

          {newsItem.picture ? (
            <div className="flex justify-center my-6">
              <img
                src={`https://api.collegedwarka.com/api/files${newsItem.picture}`}
                alt={newsItem.title}
                className="w-full max-w-[800px] h-[350px] object-cover rounded-md"
              />
            </div>
          ) : (
            <div className="flex justify-center my-6">
              <div className="w-full max-w-[800px] h-[350px] bg-gray-300 object-cover rounded-md">No News</div>
            </div>
          )}

          <div className="mt-4 text-gray-700 font-[600] leading-relaxed break-words">
            <p>{newsItem.sub_content}</p>
          </div>
        </div>

        {/* Trending News Section */}
        {trendingNews.length > 0 && (
          <div className="relative w-full max-w-6xl mx-auto mt-20 p-6 border border-[#00000040] rounded-lg bg-[#FAFAFA]">
            <div className="absolute -top-10 left-6 flex items-center space-x-2">
              <FaFireAlt className="text-black text-2xl" />
              <h2 className="text-black text-2xl font-bold">Trending News</h2>
            </div>

            <div className="flex items-center justify-between">
              <button 
                onClick={handlePrevSlide} 
                className="text-black hover:bg-gray-100 p-2 rounded-full transition-colors duration-200 focus:outline-none"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>

              <div className="flex gap-6 overflow-hidden px-4">
                <div className="flex transition-all duration-500 ease-in-out">
                  {trendingNews.slice(currentIndex, currentIndex + 3).map((item) => (
                    <div
                      key={item.id}
                      className="w-[300px] flex-shrink-0 px-2"
                    >
                      <div className="bg-white rounded-lg shadow-md overflow-hidden h-full transform hover:scale-[1.02] transition-transform duration-300">
                        {/* Image Container */}
                        <div className="h-[200px] w-full overflow-hidden">
                          <img
                            src={`https://api.collegedwarka.com/api/files${item.picture}`}
                            alt={item.title}
                            className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                          />
                        </div>

                        {/* Content Container */}
                        <div className="p-4">
                          {/* Title */}
                          <h3 className="text-lg font-semibold text-gray-800 mb-2 line-clamp-2 hover:text-green-600">
                            {item.title}
                          </h3>

                          {/* Main Content */}
                          <p className="text-sm text-gray-600 mb-2 line-clamp-3">
                            {item.content}
                          </p>

                          {/* Sub Content */}
                          <p className="text-xs text-gray-500 line-clamp-2">
                            {item.sub_content}
                          </p>

                          {/* Date */}
                          <p className="text-xs text-gray-400 mt-2">
                            {new Date(item.created_at).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric"
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <button 
                onClick={handleNextSlide} 
                className="text-black hover:bg-gray-100 p-2 rounded-full transition-colors duration-200 focus:outline-none"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default ParticularNews;
