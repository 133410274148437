import React,{useEffect} from 'react';
import Header from '../Header/Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <section
        className="flex flex-col items-center justify-center py-8 mt-20 border-b-2 border-gray-200 text-2xl font-bold"
        style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}
      >
        <div className="text-center">
          <div>Privacy Policy</div>
          <div className="text-sm text-gray-500 mt-2">Effective Date: October 1, 2024</div>
        </div>
      </section>

      <div className="flex-grow py-8">
        <div className=" bg-white p-8 ">
          {/* Section - Introduction */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Introduction</h2>
            <p className="text-gray-700">
              Welcome to College Dwarka. We are committed to protecting your personal information and your right to privacy.
              This Privacy Policy explains how we collect, use, and share your personal information when you visit our platform and use our services.
            </p>
          </section>

          {/* Section - Information We Collect */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Information We Collect</h2>
            <p className="text-gray-700 mb-4">
              We collect the following types of information:
            </p>
            <ul className="list-disc list-inside pl-4 text-gray-700 space-y-2">
              <li>
                <strong>Personal Information:</strong> Name, contact details, email address, and any other personal information you provide.
              </li>
              <li>
                <strong>Usage Data:</strong> Information about how you use our platform, including the pages you visit and actions you take.
              </li>
              <li>
                <strong>Cookies:</strong> We use cookies to enhance your experience on our website.
              </li>
            </ul>
          </section>

          {/* Section - How We Use Your Information */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">How We Use Your Information</h2>
            <p className="text-gray-700 mb-4">
              We may use your information for the following purposes:
            </p>
            <ul className="list-disc list-inside pl-4 text-gray-700 space-y-2">
              <li>To provide and maintain our services</li>
              <li>To communicate with you about your account and updates</li>
              <li>To personalize your experience on our platform</li>
              <li>To analyze how our platform is being used to improve our services</li>
            </ul>
          </section>

          {/* Section - Sharing Your Information */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Sharing Your Information</h2>
            <p className="text-gray-700">
              We do not share your personal information with third parties, except in the following cases:
            </p>
            <ul className="list-disc list-inside pl-4 text-gray-700 space-y-2">
              <li>With service providers who assist in the operation of our platform</li>
              <li>To comply with legal obligations or protect our rights</li>
            </ul>
          </section>

          {/* Section - Your Rights */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Your Rights</h2>
            <p className="text-gray-700 mb-4">You have the right to:</p>
            <ul className="list-disc list-inside pl-4 text-gray-700 space-y-2">
              <li>Access, update, or delete your personal information</li>
              <li>Opt-out of receiving marketing communications</li>
              <li>Request a copy of the personal information we hold about you</li>
            </ul>
          </section>

          {/* Section - Changes to This Privacy Policy */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Changes to This Privacy Policy</h2>
            <p className="text-gray-700">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
              We encourage you to review this policy periodically for any updates.
            </p>
          </section>

          {/* Section - Contact Us */}
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p className="text-gray-700 mt-2">Email: info@collegedwarka.com</p>
          </section>

          <p className="text-center text-gray-500 mt-10">© 2024 College Dwarka. All rights reserved.</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
