import React from 'react';
import { FaSearch, FaMoon, FaBell, FaCog, FaUserCircle } from 'react-icons/fa';
import { HiArrowRight, HiMenuAlt1 } from "react-icons/hi";
import MenuIcon from '../../../../dashboard/components/navbar/MenuIcon';

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {
  return (
    <div className={`h-16 bg-white text-gray-700 flex items-center px-4 border-b border-gray-300 fixed top-0 left-0 right-0 z-10 ${isSidebarOpen ? 'ml-64' : 'ml-20'}`}>
      <div className="flex-grow flex items-center justify-between">
        {/* Sidebar Toggle Button */}
        <button onClick={toggleSidebar} className="flex items-center">
        <MenuIcon isCollapsed={!isSidebarOpen} />
        </button>

        {/* Search Bar */}
        <div className="hidden md:flex items-center flex-grow">
          <div className="relative w-full max-w-md">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FaSearch className="text-gray-500" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="pl-10 w-full bg-gray-200 rounded-full border-none focus:ring-0 outline-none text-sm font-normal"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            />
          </div>
        </div>

        {/* Right Section Icons */}
        <div className="flex items-center space-x-4 ml-4">
          <button className="hidden md:flex items-center">
            <FaMoon className="w-5 h-5 text-[#41B923]" />
          </button>
          <button className="hidden md:flex items-center">
            <FaBell className="w-5 h-5 text-[#41B923]" />
          </button>
          <button className="hidden md:flex items-center">
            <FaCog className="w-5 h-5 text-[#41B923]" />
          </button>
          <button className="hidden md:flex items-center">
            <FaUserCircle className="w-6 h-6 text-[#41B923]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
