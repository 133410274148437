import React, { useState, useEffect } from 'react';
import college_logo from '../../../assets/college_logo.png';

export const Img_URL = 'https://api.collegedwarka.com/api/files';

const Gallery = ({ collegeDetails, logoPath }) => {
    const [imageSources, setImageSources] = useState([]);

    // Fetch gallery images from API
    useEffect(() => {
        const fetchGalleryImages = async () => {
            try {
                const response = await fetch(`https://api.collegedwarka.com/api/gallery/university/${collegeDetails.university_id}`);
                const data = await response.json();
                
                // Extract the gallery_file_name and append it to the Img_URL
                const images = data.map(item => `${Img_URL}${item.gallery_file_name.replace("/var/www/zanko/uploads/", "/")}`);
                setImageSources(images);
            } catch (error) {
                console.error('Error fetching gallery images:', error);
            }
        };

        fetchGalleryImages();
    }, [collegeDetails.university_id]);

    return (
        <div className="py-8 px-5 bg-[#DCFFC4]">
            <div className="text-black text-base font-medium flex flex-col gap-5">
                <div className="flex items-center justify-center gap-3 py-2">
                    <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                        <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="md:text-3xl text-black md:font-semibold text-[20px] font-[700]">{collegeDetails.university_name}</p>
                </div>

                <p className="text-center text-lg text-black font-[500] mb-5">Gallery</p>
            </div>

            {/* Pictures section */}
            <div className="flex flex-wrap gap-5 justify-center px-5 py-10">
                {imageSources.map((src, index) => (
                    <div className="h-[200px]" key={index}>
                        <img src={src} alt={`college-image-${index + 1}`} className="w-full h-full object-cover" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
