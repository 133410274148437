import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

const AddSectionDetails = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      exam_master_id: null,
      level_id:null,
      stream_id:null,
      mode_id:null,
      // created_by: 1,
      overview:'',
      syllabus:'',
      exam_pattern:'',
      eligibility:'',
      how_to_prepare:'',
      application_form:'',
      admit_card:'',
      prev_year_paper:'',
      counselling_process:'',
    
    });
   
    const[exams,setExams]=useState([])
    const[sections,setSections]=useState([])
    const[streams,setStreams]=useState([])
    const[mode,setMode]=useState([])
    const[level,setLevel]=useState([])
    
    const location = useLocation();
    const { mainTitle, subTitle } = location.state || {};
    const [loading, setLoading] = useState(false); // Track loading state
    const { registration_id, token,name } = useSelector((state) => state.auth);
  
  

  
  
    useEffect(() => {
      // Fetch stream data from the API

      const fetchExams = async () => {
        try {
            const response = await axios.get(`${Base_URL}/get/exam_master`);
            console.log('exam',response.data)
            const examOptions = response.data.map((exam) => ({
              value: exam.id, 
              label: exam.exam_master_name, 
            }));
            setExams(examOptions);
        } catch (error) {
          console.error('Failed to fetch exams:', error);
          toast.error('Failed to load exams options.');
        }
      };
  
      fetchExams();
    
      const fetchStreams = async () => {
        try {
            const response = await axios.get(`${Base_URL}/get/streams`);
            console.log('stream',response.data)
            const streamOptions = response.data.map((stream) => ({
              value: stream.id, 
              label: stream.stream_name, 
            }));
            setStreams(streamOptions);
        } catch (error) {
          console.error('Failed to fetch streams:', error);
          toast.error('Failed to load stream options.');
        }
      };

      const fetchMode = async () => {
        try {
            const response = await axios.get(`${Base_URL}/get/mode_master`);
            console.log('mode',response.data)
            const modeOptions = response.data.map((mode) => ({
              value: mode.id, 
              label: mode.mode_title, 
            }));
            setMode(modeOptions);
        } catch (error) {
          console.error('Failed to fetch modes:', error);
          toast.error('Failed to load mode options.');
        }
      };
      const fetchLevel = async () => {
        try {
            const response = await axios.get(`${Base_URL}/get/level_master`);
            console.log('level',response.data)
            const levelOptions = response.data.map((level) => ({
              value: level.id, 
              label: level.level_name, 
            }));
            setLevel(levelOptions);
        } catch (error) {
          console.error('Failed to fetch exam levels:', error);
          toast.error('Failed to load exam  level options.');
        }
      };
      fetchLevel();
      fetchMode();
      fetchStreams();
    
      
    }, []);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  
 

    const handleExamChange = (selectedOption) => {
       
        setFormData((prevData) => ({
          ...prevData,
          exam_master_id: selectedOption.value // Set stream_id (the first value of the array)
        }));
      };

     

      const handleStreamChange = (selectedOption) => {
       
        setFormData((prevData) => ({
          ...prevData,
          stream_id: selectedOption.value 
        }));
      };

      const handleLevelChange = (selectedOption) => {
       
        setFormData((prevData) => ({
          ...prevData,
          level_id: selectedOption.value 
        }));
      };

      const handleModeChange = (selectedOption) => {
       
        setFormData((prevData) => ({
          ...prevData,
          mode_id: selectedOption.value 
        }));
      };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        const newSection = { ...formData };
        await axios.post(`${Base_URL}/create/exam_master_description`, newSection, {
          headers: {
            'Content-Type': 'application/json',
             'authorization': `Bearer ${token}`
          },
          withCredentials: true // This allows cookies to be sent with the request
        });
        toast.success('Exam Section added successfully!');
      } catch (error) {
        toast.error('Failed to add exam section');
        console.error('Failed to add exam section', error.response?.data || error.message);
        setLoading(false)
      }
      finally {
        setLoading(false); // Reset loading state after submission
      }
    }
  return (
    <div>
    <ToastContainer />
    <div className="bg-gray-100 p-6 min-h-screen">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          
            <div className="flex flex-col">
              <label htmlFor="name" className="mb-2 font-medium text-sm">Select Exam</label>
              <Select
                options={exams} 
                onChange={handleExamChange}
                placeholder={'Select Exam'} // Display placeholder text
                className="basic-single"
              />
            </div>

           

            <div className="flex flex-col">
              <label htmlFor="stream" className="mb-2 font-medium text-sm">Select Stream</label>
              <Select
                options={streams} 
                onChange={handleStreamChange}
                placeholder={'Select Stream'} // Display placeholder text
                className="basic-single"
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="mode" className="mb-2 font-medium text-sm">Select Mode</label>
              <Select
                options={mode} 
                onChange={handleModeChange}
                placeholder={'Select Mode'} // Display placeholder text
                className="basic-single"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="level" className="mb-2 font-medium text-sm">Select Level</label>
              <Select
                options={level} 
                onChange={handleLevelChange}
                placeholder={'Select Level'} // Display placeholder text
                className="basic-single"
              />
            </div>
      

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="overview" className="mb-2 font-medium text-sm">Exam Overview</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.overview}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,overview: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="syllabus" className="mb-2 font-medium text-sm">Exam Syllabus</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.syllabus}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,syllabus: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="exam_pattern" className="mb-2 font-medium text-sm">Exam Pattern</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.exam_pattern}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,exam_pattern: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility Details</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.eligibility}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,eligibility: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="how_to_prepare" className="mb-2 font-medium text-sm">How to Prepare</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.how_to_prepare}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,how_to_prepare: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="application_form" className="mb-2 font-medium text-sm">Application Form</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.application_form}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,application_form: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="admit_card" className="mb-2 font-medium text-sm">Admit Card</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.admit_card}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,admit_card: editor.getData() }))}
              />
            </div>


            <div className="flex flex-col md:col-span-2">
              <label htmlFor="prev_year_papers" className="mb-2 font-medium text-sm">Previous Year Papers</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.prev_year_paper}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,prev_year_paper: editor.getData() }))}
              />
            </div>

            <div className="flex flex-col md:col-span-2">
              <label htmlFor="counselling_process" className="mb-2 font-medium text-sm">Counselling Process</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.counselling_process}
                onChange={(event, editor) => setFormData((prev) => ({ ...prev,counselling_process: editor.getData() }))}
              />
            </div>
          </div>

          <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
          {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add Exam Details'
              )}
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}

export default AddSectionDetails