import React, { useState } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import { LuPlus } from "react-icons/lu";
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import '../../../styles/Editor.css';
import HeaderTitle from '../../dashboard/HeaderTitle';
import {Base_URL} from '../../../apiConfig'
import { useSelector } from "react-redux"; // Import useSelector


const AddUniversity = () => {
  const [formData, setFormData] = useState({
    university_name: '',
    main_intro: '',
    sub_intro: '', // New sub_intro field
    keywords: [],
    website: '',
    featuredcollege: '0', // Default value
    popularcollege: '0', // Default value
    is_active:'0',
    university_email:'',
    university_address:'',
    university_ph_no:'',
    pincode:''
  });

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [logo, setLogo] = useState(null); // Logo upload
  const [pictures, setPictures] = useState([]); // Multiple image uploads
  const [loading, setLoading] = useState(false); // Track loading state
const [video, setVideo] = useState(null);
 // Retrieve token from Redux store
 const { token } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'logo') {
      setLogo(files[0]);
    } else if (name === 'pictures') {
      setPictures([...files]);
    } else if (name === 'video') { // Add this condition for video
      setVideo(files[0]);
    }
  };

  // Handle adding a new keyword with title and detail
  const handleAddKeyword = () => {
    setFormData(prev => ({
      ...prev,
      keywords: [...prev.keywords, { title: '', details: '' }],
    }));
  };

  // Handle changing keyword title or detail
  const handleKeywordChange = (index, field, value) => {
    const newKeywords = [...formData.keywords];
    newKeywords[index][field] = value;
    setFormData(prev => ({ ...prev, keywords: newKeywords }));
  };

  // Handle removing a keyword
  const handleRemoveKeyword = (index) => {
    const newKeywords = formData.keywords.filter((_, i) => i !== index);
    setFormData(prev => ({ ...prev, keywords: newKeywords }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    // Check for required fields
    if (!formData.university_name || !formData.main_intro || !formData.website || !logo || pictures.length === 0) {
      toast.error('Please fill all the required fields');
      setLoading(false); // Reset loading state if validation fails
      return;
    }
  
    try {
      const data = new FormData();
  
      // Append text fields
      data.append('university_name', formData.university_name);
      data.append('university_email', formData.university_email);
      data.append('university_address', formData.university_address);
      data.append('pincode', formData.pincode);
      data.append('is_active', formData.is_active);
      data.append('university_ph_no', formData.university_ph_no);
      data.append('main_intro', formData.main_intro);
      data.append('sub_intro', formData.sub_intro);
      data.append('website', formData.website);
      data.append('featuredcollege', formData.featuredcollege);
      data.append('popularcollege', formData.popularcollege);
  
      // Append logo file
      if (logo) {
        data.append('logo', logo);
      }
  
      // Append multiple pictures
      pictures.forEach((picture, index) => {
        data.append(`pictures`, picture); // Appending multiple files
      });
  
      // Append video file if it exists
      if (video) {
        data.append('video', video);
      }
  
      // Serialize and append keywords as a JSON string
      if (formData.keywords && formData.keywords.length > 0) {
        data.append('keywords', JSON.stringify(formData.keywords));
      }
  
      // Make POST request
      await axios.post(`${Base_URL}/universities`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });
  
      toast.success('University created successfully!');
      
      // Reset form including video
      setFormData({
        university_name: '',
        main_intro: '',
        sub_intro:'',
        keywords: [],
        website: '',
        featuredcollege: '0',
        popularcollege: '0',
        is_active:'0',
        university_address: '',
        university_ph_no: '',
        university_email:'',
        pincode:''

      });
      setLogo(null);
      setPictures([]);
      setVideo(null); // Reset video state
  
    } catch (error) {
      console.error('Failed to create university:', error.response?.data || error.message);
      toast.error('Failed to create university. Please try again.');
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };
  

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add University</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Text Input Fields */}
            {['university_name', 'website'].map((field) => (
              <div key={field} className="flex flex-col">
                <label htmlFor={field} className="mb-2 font-medium text-sm capitalize">
                  {field.replace('_', ' ')}
                </label>
                <input
                  type="text"
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter ${field.replace('_', ' ')}`}
                />
              </div>
            ))}
           
           <div  className="flex flex-col">
                <label htmlFor='email' className="mb-2 font-medium text-sm capitalize">
                  University Email
                </label>
                <input
                  type="text"
                  id='email'
                  name='university_email'
                  value={formData.university_email}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter University Email`}
                />
              </div>

              <div  className="flex flex-col">
                <label htmlFor='address' className="mb-2 font-medium text-sm capitalize">
                  University Address
                </label>
                <input
                  type="text"
                  id='address'
                  name='university_address'
                  value={formData.university_address}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter University Address`}
                />
              </div>
              <div  className="flex flex-col">
                <label htmlFor='email' className="mb-2 font-medium text-sm capitalize">
                 University Pincode
                </label>
                <input
                  type="text"
                  id='pincode'
                  name='pincode'
                  value={formData.pincode}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter University Pincode`}
                />
              </div>
              <div  className="flex flex-col">
                <label htmlFor='phone' className="mb-2 font-medium text-sm capitalize">
                  University Contact Number
                </label>
                <input
                  type="text"
                  id='phone'
                  name='university_ph_no'
                  value={formData.university_ph_no}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                  placeholder={`Enter University Phone No`}
                />
              </div>


            {/* CKEditor for Main Intro */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="main_intro" className="mb-2 font-medium text-sm">University Main Intro</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.main_intro}
                onChange={(event, editor) => setFormData(prev => ({ ...prev, main_intro: editor.getData() }))}
              />
            </div>

            {/* CKEditor for Sub Intro */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="sub_intro" className="mb-2 font-medium text-sm">University Sub Intro</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.sub_intro}
                onChange={(event, editor) => setFormData(prev => ({ ...prev, sub_intro: editor.getData() }))}
              />
            </div>

            {/* Keywords Section */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="keywords" className="mb-2 font-medium text-sm">Keywords</label>
              {formData.keywords.map((keyword, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    placeholder="Keyword Title"
                    value={keyword.title}
                    onChange={(e) => handleKeywordChange(index, 'title', e.target.value)}
                    className="border border-gray-300 p-2 rounded w-full mr-2"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Keyword Detail"
                    value={keyword.details}
                    onChange={(e) => handleKeywordChange(index, 'details', e.target.value)}
                    className="border border-gray-300 p-2 rounded w-full mr-2"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveKeyword(index)}
                    className="text-red-600 hover:text-red-800 ml-2"
                  >
                    ×
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddKeyword}
                className="p-2 bg-blue-600 text-white rounded-md flex items-center"
              >
                <LuPlus className="mr-2" />
                Add Keyword
              </button>
            </div>

            {/* Dropdowns for Featured and Popular College */}
            {['featuredcollege', 'popularcollege'].map((field) => (
              <div key={field} className="flex flex-col">
                <label htmlFor={field} className="mb-2 font-medium text-sm capitalize">{field.replace('_', ' ')}</label>
                <select
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            ))}

<div  className="flex flex-col">
                <label htmlFor='status' className="mb-2 font-medium text-sm capitalize">Status</label>
                <select
                  id='status'
                  name='is_active'
                  value={formData.is_active}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>

            {/* Logo and Pictures Upload */}
            {[
              { label: 'Logo', name: 'logo', multiple: false },
              { label: 'Upload Pictures', name: 'pictures', multiple: true },
              { label: 'Upload Video', name: 'video', multiple: false, accept: 'video/*' },
            ].map(({ label, name, multiple, accept }) => (
              <div key={name} className="flex flex-col md:col-span-2">
                <label htmlFor={name} className="mb-2 font-medium text-sm">{label}</label>
                <input
                  type="file"
                  id={name}
                  name={name}
                  onChange={handleFileChange}
                  className="border border-gray-300 rounded"
                  multiple={multiple}
                  accept={accept}
                  required={name === 'logo' || name === 'pictures'}
                />
                {name === 'video' && (
                  <p className="text-sm text-gray-500 mt-1">
                    Supported formats: MP4, WebM, Ogg (Max size: 100MB)
                  </p>
                )}
              </div>
            ))}
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">
            {loading ? ( // Show spinner when loading
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddUniversity;
