import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HiPencil, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';

const AllAdminFaq = () => {
  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/faq`);
        setFaqs(response.data.reverse());
      } catch (error) {
        console.error('Failed to fetch FAQs:', error.response?.data || error.message);
        setError('Failed to fetch FAQs. Please try again.');
      }
    };

    fetchFaqs();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${Base_URL}/faq/${id}`);
      setFaqs(faqs.filter((faq) => faq.id !== id));
    } catch (error) {
      console.error('Failed to delete FAQ:', error.response?.data || error.message);
      setError('Failed to delete FAQ. Please try again.');
    }
  };

  const handleEditClick = (faq) => {
    navigate(`/admin/faqs/edit/${faq.id}`, { state: { faq } });
  };

  const filteredEntries = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredEntries.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredEntries.length / entriesPerPage);

  // Column headers based on AddAdminFaq fields
  const columns = [
    'Question',
    'Answer',
    'University',
    'User ID',
    'Registration Type',
    'Actions'
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderTitle subTitle={subTitle} mainTitle={mainTitle} />

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="font-poppins" style={{
            color: 'rgb(49, 42, 42)',
            fontSize: '18.5px',
            fontWeight: 600,
            lineHeight: '26.25px',
            height: '45px',
          }}>
            All FAQs
          </div>
          <button
            className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded text-sm hover:bg-[rgb(82,89,199)]"
            onClick={() => navigate('/admin/faqs/add')}
          >
            + Add New
          </button>
        </div>

        <div className="flex items-center justify-end mb-4">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-white">
                {columns.map((heading) => (
                  <th key={heading} className="text-left border-b" style={{
                    color: 'rgb(49, 42, 42)',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '21px',
                    padding: '14px 13px',
                    borderBottomColor: 'rgb(230, 230, 230)'
                  }}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((faq) => (
                  <tr key={faq.id} className="hover:bg-white hover:shadow-md" style={{
                    borderTopWidth: '1px',
                    borderTopColor: 'rgb(230, 230, 230)',
                    verticalAlign: 'top'
                  }}>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      {faq.question}
                    </td>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      {faq.answer}
                    </td>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      {faq.university_name}
                    </td>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      {faq.user_id}
                    </td>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      {faq.registration_typeid}
                    </td>
                    <td className="text-left border-t border-b" style={{
                      color: 'rgb(115, 123, 139)',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '13.125px',
                      fontWeight: 400,
                      lineHeight: '19.6px',
                      padding: '9.1px 13.125px',
                      borderBottomColor: 'rgb(230, 230, 230)'
                    }}>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditClick(faq)}
                          className="p-2 rounded bg-[rgb(106,115,250)] text-white hover:bg-[rgb(82,89,199)]"
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(faq.id)}
                          className="p-2 rounded bg-red-500 text-white hover:bg-red-700"
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4">No FAQs found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded hover:bg-[rgb(82,89,199)] disabled:opacity-50"
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-[rgb(106,115,250)] text-white rounded hover:bg-[rgb(82,89,199)] disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAdminFaq;
