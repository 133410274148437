import React,{useState,useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import '../styles/college.css'
import Header from '../components/Header/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Navbar from '../components/Header/Navbar';
import Footer from '../components/Footer/Footer'
import ReviewCard from '../components/College/ReviewCard/ReviewCard'
import Overview from '../components/College/Overview/Overview'
import Courses from '../components/College/Courses/Courses'
import Admission from '../components/College/Admission/Admission'
import Scholarship from '../components/College/Scholarship/Scholarship';
import Placements from '../components/College/Placements/Placements';
import QA from '../components/College/QA/QA';
import Gallery from '../components/College/Gallery/Gallery'
import NewsEvents from '../components/College/NewsEvents/NewsEvents'
import Chat from '../components/College/Chat/Chat'
import college_logo from '../assets/college_logo.png';
import college_grad from '../assets/college_grad.png';
import user1 from '../assets/user1.png'
import user2 from '../assets/user2.png'
import user3 from '../assets/user3.png'
import college_img1 from '../assets/college_img1.png'
import college_img2 from '../assets/college_img2.png'
import college_img3 from '../assets/college_img3.png'
import college_img4 from '../assets/college_img4.png'
import college_vid from '../assets/college_vid.mp4'
import Reviews from '../components/Landing/Reviews/Reviews';

import EnquiryForm from '../components/Forms/EnquiryForm';
import { Base_URL,Img_URL } from '../apiConfig';
const College = () => {
 
    const [colleges, setColleges] = useState([]);
    const [preview, setPreview] = useState(0);
    const [logoPath, setLogoPath] = useState('');
    const [pictures, setPictures] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [discipline, setDiscipline] = useState('');
    const [qualification, setQualification] = useState('');
    const [program, setProgram] = useState('');
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact_no: '',
        program: '',
    });
    const [programOptions, setProgramOptions] = useState([]);
    const [selectedCollege, setSelectedCollege] = useState('');
    const [error, setError] = useState(null);
    const mainSectionRef = useRef(null);
    const location = useLocation();
    const collegeDetails = location.state?.college; 
    const keywords = collegeDetails ? collegeDetails.keywords.slice(0, 6) : [];
    const [originalTop, setOriginalTop] = useState(null); // Initially null until loaded
    const [isSticky, setIsSticky] = useState(false);
    const [lastScrollPos, setLastScrollPos] = useState(0); // Track last scroll position
 // Track original top position
    const subheaderRef = useRef(null);
  
    useEffect(() => {
      const fetchUniversities = async () => {
          try {
              const response = await fetch(`${Base_URL}/universities/popular/colleges`);
              const data = await response.json();
              if (Array.isArray(data)) {
                  const processedData = data.map(item => ({
                      ...item,
                      pictures: JSON.parse(item.pictures),
                  }));
                  setColleges(processedData);
              } else {
                  setColleges([]);
              }
          } catch (error) {
              console.error("Error fetching universities:", error);
          }
      };

      const fetchPrograms = async () => {
          try {
              const response = await axios.get('${Base_URL}/get/program_master');
              setProgramOptions(response.data);
          } catch (error) {
              console.error("Error fetching programs:", error);
          }
      };

      fetchUniversities();
      fetchPrograms();
  }, []);
  useEffect(() => {
    const updateOriginalTop = () => {
        if (subheaderRef.current) {
            setOriginalTop(subheaderRef.current.getBoundingClientRect().top + window.scrollY);
        }
    };

    // Set original top position after component loads
    setTimeout(updateOriginalTop, 100);

    const handleScroll = () => {
        if (originalTop !== null && window.scrollY >= originalTop) {
            setIsSticky(true);
        } else if (originalTop !== null && window.scrollY < originalTop) {
            setIsSticky(false);
        }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateOriginalTop);

    return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateOriginalTop);
    };
}, [originalTop]);

    
    const formSectionRef = useRef(null);
    const [videoPath, setVideoPath] = useState('');
    

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, [location]);
    
  //   useEffect(() => {
  //     // Ensure collegeDetails has a logo before making the API request
  //     if (collegeDetails && collegeDetails.logo) {
  //         const logoUrl = `https://api.collegedwarka.com/api/files/${collegeDetails.logo}`;

  //         // Set the logo path
  //         setLogoPath(logoUrl);
  //     }
  // }, [collegeDetails])
  useEffect(() => {
    // Fetch the logo, video and pictures if available
    if (collegeDetails) {
        if (collegeDetails.logo) {
            const logoUrl = `${Img_URL}/${collegeDetails.logo}`;
            setLogoPath(logoUrl);
        }

        // Fetch video if available
        if (collegeDetails.video) {
            const videoUrl = `${Img_URL}/${collegeDetails.video}`;
            setVideoPath(videoUrl);
        }

        // Fetch pictures if available
        if (collegeDetails.pictures) {
            try {
                let picturesArray;
                if (Array.isArray(collegeDetails.pictures)) {
                    picturesArray = collegeDetails.pictures;
                } else if (typeof collegeDetails.pictures === "string") {
                    picturesArray = collegeDetails.pictures.split(",");
                } else {
                    throw new Error("Invalid format for pictures.");
                }

                const pictureUrls = picturesArray.map(picture => 
                    `${Img_URL}/${picture.trim()}`
                );

                setPictures(pictureUrls);
            } catch (error) {
                console.error("Error parsing pictures:", error);
            }
        }
    }
}, [collegeDetails]);

const handleSubmit = async (e) => {
  e.preventDefault();

  // Validation: Check if all fields are filled
  if (!name || !email || !contactNo || !discipline || !program) {
    setMessage('Please fill all fields.');
    return;
  }

  const formData = {
    name,
    email,
    contact_no: contactNo,
    
    program,
  };

  try {
    const response = await fetch(`${Base_URL}/create/enquiry_form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (response.ok) {
      setMessage('Form submitted successfully!');
      setTimeout(() => setMessage(''), 3000); 
    } else {
      setMessage(`Error: ${data.message || 'Something went wrong'}`);
      setTimeout(() => setMessage(''), 3000); 
    }
  } catch (error) {
    setMessage('Error submitting form. Please try again.');
    setTimeout(() => setMessage(''), 3000); 
    console.error(error);
  }
};

const openModal = (collegeName) => {
  setSelectedCollege(collegeName);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

  
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handlePreview = (view) => {
      setPreview(view);
      
      // Only scroll if the subheader is sticky
      if (isSticky) {
        const headerHeight = 90; // Height of the main header
        const subheaderHeight = 60; // Height of the subheader
        
        // Calculate the position to scroll to
        // This will be the original position of the subheader
        const scrollToPosition = originalTop - headerHeight - subheaderHeight;
        
        // Scroll to show header, sticky subheader, and content
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
      }
    };
    
    const handleInputChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
  };
const handleLeftClick = () => {
  setPreview((prev) => (prev > 0 ? prev - 1 : 8)); // Move to the previous option or loop back to the last one
};

const handleRightClick = () => {
  setPreview((prev) => (prev < 8 ? prev + 1 : 0)); // Move to the next option or loop back to the first one
};

const scrollToForm = () => {
  if (formSectionRef.current) {
    const yOffset = -100; // Offset to account for fixed header
    const element = formSectionRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  }
};

if (!collegeDetails) {
  return <div>No college data available</div>; 
}


    return (
        <div className='college_main'>
        
      <Header />
 
     

{/* Top Section */}
<div className="py-5 mx-auto mt-20 ">
    {/* College Title */}
    <div className="flex flex-col items-center  justify-center gap-3 p-5 md:flex-row md:gap-5">
        {/* College Logo */}
        <div className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]">
            <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
        </div>
        
        {/* College Text */}
        <p className="text-center md:text-left text-xl md:text-3xl font-[500] text-black">
            {collegeDetails.university_name}
        </p>
    </div>

    {/* Slider Section */}
<div className="flex justify-center p-5">
    <div className="w-full max-w-[357px] md:max-w-[1000px]">
        <Slider {...settings}>
            {/* Video Slide - Now using dynamic video path */}
            {videoPath && (
                <div className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px] flex justify-center items-center">
                        <video 
                            src={videoPath}
                            alt="college-video" 
                            controls 
                            className="w-full h-full object-cover rounded-2xl" 
                        />
                    </div>
                </div>
            )}

            {/* Image Slides */}
            {pictures && pictures.map((picture, index) => (
                <div key={index} className="flex justify-center items-center">
                    <div className="w-full h-[336px] md:h-[450px] flex justify-center items-center">
                        <img 
                            src={picture}
                            alt={`college-image-${index + 1}`} 
                            className="w-full h-full object-cover rounded-2xl" 
                        />
                    </div>
                </div>
            ))}
        </Slider>
    </div>
</div>



                {/* College tags */}
                <div className="tags-container flex justify-center gap-2 p-3">
                    {/* <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Highest Package 3 Crore</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Private University</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>NIRF Rank #46</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Year of Establishment 2005</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>4.6  View Reviews</p></div>
                    <div className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs"><p>Location Punjab</p></div> */}
                  
                {keywords.map((item, index) => (
          <div key={index} className="text-white tags-item bg-[#003D83] px-2 py-1 rounded-xl text-xs">
            <p>{item.title}: {item.details}</p>
          </div>
        ))}
                </div>
                {/* Apply buttons */}
                <div className="flex buttons-container justify-center gap-10 p-5">
                    <button  onClick={() => openModal('College Name')}  className="text-white rounded-[10px] apply_buttons bg-[#3ACA2E] px-10 py-2 md:rounded-xl text-base">Apply Now</button>
               
                    <button 
                        onClick={scrollToForm}
                        className="px-10 py-2 counselling_buttons rounded-[10px] md:w-[250px] md:h-[42px] md:rounded-xl text-base text-black shadow-lg" 
                        style={{ border: '1px solid #3ACA2E' }}
                    >
                        Get Free Counselling
                    </button>
                </div>
                {error && <div className=" fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-md shadow-md">{error}</div>}
                {isModalOpen && (
                <EnquiryForm
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    selectedCollege={selectedCollege}
                />
            )}
         
            <ToastContainer />
            </div>

            {/* Options - sub header */}
            <div
  id="subheader"
ref={subheaderRef}
  className={` subheader  ${isSticky ? 'fixed' : ''}   top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header   bg-white shadow-md md:justify-center`
  } style={{
    position: isSticky ? "fixed" : "static",
    top: isSticky ? "90px" : "auto",
    width: "100%",
    
}}
>
  <p className={`college_sub_header_option ${preview === 0 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(0)}>Overview</p>
  <p className={`college_sub_header_option ${preview === 1 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(1)}>Courses</p>
  <p className={`college_sub_header_option ${preview === 2 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(2)}>Admission</p>
  <p className={`college_sub_header_option ${preview === 3 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(3)}>Scholarship</p>
  <p className={`college_sub_header_option ${preview === 4 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(4)}>Placements</p>
  <p className={`college_sub_header_option ${preview === 5 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(5)}>Gallery</p>
  <p className={`college_sub_header_option ${preview === 6 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(6)}>News & Events</p>
  <p className={`college_sub_header_option ${preview === 7 ? 'college_sub_header_active' : ''}`} onClick={() => handlePreview(7)}>Q/A</p>
  <p className={`college_sub_header_option ${preview === 8 ? 'college_sub_header_active' : ''}`} onClick={() => window.location.href = "https://wa.me/6005130753"}>Chat</p>
</div>








            {/* Main Section */}
            {/* <div className="" ref={mainSectionRef}>
    {preview === 0 && (<Overview collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 1 && (<Courses collegeDetails={collegeDetails} />)}
    {preview === 2 && (<Admission collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 3 && (<Scholarship collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 4 && (<Placements collegeDetails={collegeDetails}  />)}
    {preview === 5 && (<Gallery collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 6 && (<NewsEvents collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {preview === 7 && (<QA collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {preview === 8 && (<Chat collegeDetails={collegeDetails}  />)}
</div> */}
<div className="" ref={mainSectionRef}>
    {preview === 0 && (<Overview collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 1 && (<Courses collegeDetails={collegeDetails} />)}
    {preview === 2 && (<Admission collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 3 && (<Scholarship collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 4 && (<Placements collegeDetails={collegeDetails}  />)}
    {preview === 5 && (<Gallery collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 6 && (<NewsEvents collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {preview === 7 && (<QA collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {/* Redirect to WhatsApp for preview 7 */}
    {preview === 8 && (<Chat />
        
    )}
    
   
</div>


            {/* Details Form Section*/}
            <div className="flex flex-wrap  justify-center gap-20 items-center px-5 py-20 mobile-form-shadow " ref={formSectionRef}>
  <div className="flex flex-col items-center  gap-5 max-w-[700px] mx-auto px-4 md:px-0 md:text-center">
    <p className="text-2xl text-black font-medium text-center md:text-center">
      Unlock Your Future with Quality Education - Join Us for a Transformative College Experience!
    </p>
    <div className="w-full max-w-[400px]">
      <img src={college_grad} className="w-full rounded-xl" alt="College Graduation" />
    </div>
  </div>

  <div className="flex flex-col gap-3 p-8 mx-auto max-w-full md:max-w-[400px] border border-gray-300 rounded-lg shadow-md ">
    <p className="text-black text-base font-medium md:text-center text-left">Get Started</p>
    <p className="text-black text-base font-medium md:text-center text-left">Fill your details and get your Information</p>
    <form onSubmit={handleSubmit}>
    <div className="flex flex-col gap-3 w-full">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='name'>Name</label>
        <input type="text" placeholder="Enter your Full Name" className="focus:outline-none rounded border border-gray-300 p-2"
         value={name}
         onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='email'>Email</label>
        <input type="email" placeholder="Enter your Email" className="focus:outline-none rounded border border-gray-300 p-2"
         value={email}
         onChange={(e) => setEmail(e.target.value)}/>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='contact'>Contact Number</label>
        <input type="tel" placeholder="Enter your Phone Number" className="rounded border border-gray-300 p-2 focus:outline-none" 
        value={contactNo}
        onChange={(e) => setContactNo(e.target.value)}/>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='qualification'>Qualification</label>
        <input type="text" placeholder="Enter your highest Qualification" className="rounded border border-gray-300 p-2 focus:outline-none"
        value={qualification}
        onChange={(e) => setQualification(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='discipline'>Discipline Interested in</label>
        <input type="text" placeholder="Enter the Discipline of Interest" className="rounded border border-gray-300 p-2 focus:outline-none" 
         value={discipline}
         onChange={(e) => setDiscipline(e.target.value)} />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label className="text-black text-sm font-medium" htmlFor='program'>Program</label>
        <input type="text" placeholder="Enter the Program" className="rounded border border-gray-300 p-2 focus:outline-none" 
       value={program}
       onChange={(e) => setProgram(e.target.value)} />
      </div>
      <div className="flex justify-center py-3">
        <button type="submit" className="bg-[#3ACA2E] rounded-3xl px-10 py-2 text-medium text-white text-lg">Submit</button>
      </div>
    </div>
    </form>
    {message && (
          <div className={`mt-5 p-3 rounded ${message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
            {message}
          </div>
        )}
  </div>
</div>


            {/* Student Reviews */}
           
  <Reviews />


<Footer />

          
        </div>
    )
}

export default College;