import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

const AddPlacements = () => {
  const [formData, setFormData] = useState({
    university_id: null,
    placement_desc: "",
    company_master_id: null,
  });

  const [universities, setUniversities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false);

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error("Failed to load universities");
      }
    };

    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/company_master`);
        const companyOptions = response.data.map((comp) => ({
          value: comp.id,
          label: comp.name,
        }));
        setCompanies(companyOptions);
      } catch (error) {
        console.error("Error fetching companies:", error);
        toast.error("Failed to load companies");
      }
    };

    fetchCompanies();
    fetchUniversities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyChange = (selectedOption) => {
    setSelectedCompany(selectedOption);
    setFormData((prev) => ({ ...prev, company_master_id: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.university_id || !formData.placement_desc || !formData.company_master_id) {
      toast.error("Please fill all the required fields");
      return;
    }

    try {
      await axios.post(`${Base_URL}/create/placement_admin`, formData, {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`, // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success("Placement information added successfully!");
      setFormData({
        university_id: null,
        placement_desc: "",
        company_master_id: null,
      });
    } catch (error) {
      console.error(
        "Failed to add placement information:",
        error.response?.data || error.message
      );
      toast.error("Failed to add placement information. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                onChange={(selectedOption) => setFormData({ ...formData, university_id: selectedOption.value })}
                placeholder="Select a university"
                className="basic-single"
                classNamePrefix="select"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="company_master_id" className="mb-2 font-medium text-sm">Select Company</label>
              <Select
                options={companies}
                value={selectedCompany}
                onChange={handleCompanyChange}
                placeholder="Select a company"
                className="basic-single"
                classNamePrefix="select"
                isClearable
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="placement_desc" className="mb-2 font-medium text-sm">Placement Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={formData.placement_desc}
              onChange={(event, editor) => setFormData((prev) => ({
                ...prev,
                placement_desc: editor.getData(),
              }))}
            />
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit Placement'
            )}
          </button>
        </form>

        <ToastContainer />
      </div>
    </div>
  );
};

export default AddPlacements;
