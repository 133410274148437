import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 


const EditFAQ = () => {
  const { id } = useParams(); // Get the professor ID from the URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    university_id: 2,
    user_id: 2,
    registration_typeid: 3
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [faq, setFaq] = useState(null);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //const { professorId } = useProfessor();
  useEffect(() => {
   
      const fetchFaqDetails = async () => {
        try {
          const response = await axios.get(`${Base_URL}/faq/${id}`);
          setFormData({
            question: response.data.question,// Use nullish coalescing to handle null
            answer: response.data.answer,
            university_id: 2, // Assuming 2 is your default
            user_id: 2,
            registration_typeid: 3
          });
        } catch (error) {
          console.error('Error fetching faq details:', error);
          setError('Failed to fetch faq details.');
        }
      };
  
      fetchFaqDetails();
    }
 , [id]);
  
  
 const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('question', formData.question);
    formDataToSubmit.append('answer', formData.answer);
    formDataToSubmit.append('university_id', 2)
    formDataToSubmit.append('registration_typeid', 3)
    formDataToSubmit.append('user_id', 2)
    
   

    const url = `${Base_URL}/ambassadors/${id}`; // Ensure id is properly defined

    await axios.put(url, formDataToSubmit, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    navigate('/dashboard/faqs/all');
  } catch (error) {
    console.error('Error updating FAQs:', error.response ? error.response.data : error.message);
    toast.error('Failed to update FAQ details.');
  }
};




  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection
        title="Edit FAQ"
        breadcrumb={['FAQ', 'Edit FAQ']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <h2 className="text-gray-800 text-2xl font-semibold mb-4">Edit FAQ</h2>
        {error && <div className="text-red-600 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="question"
              className="text-gray-700 mb-2 font-semibold"
            >
              Question
            </label>
            <input
              type="text"
              id="question"
              name="question"
              value={formData.question}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="answer"
              className="text-gray-700 mb-2 font-semibold"
            >
              Answer
            </label>
            <input
              type="text"
              id="answer"
              name="answer"
              value={formData.answer}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-customblue text-white rounded"
          >
            Update
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditFAQ;
