import React, { useEffect } from 'react'
import Header from '../Header/Header'
import Footer from './Footer'

const ContactUs = () => {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header/>

        <section
          className="py-8 mt-20 border-b-2 border-gray-200"
          style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}
        >
          <div className="container mx-auto text-center">
            <h2 className="text-3xl text-slate-400 font-semibold">
              We would love to hear from you
            </h2>
          </div>
        </section>
        <section className="py-12">
      <h2 className="text-2xl font-semibold text-center mb-6">Contact Us</h2>
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 px-4">
        {/* Contact Information Box */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex items-center mb-4">
            {/* Address Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
</svg>

            <span className="ml-4 text-lg">Trikuta Nagar,Jammu,J&K</span>
          </div>
          <div className="flex items-center mb-4">
            {/* Mail Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>

            <span className="ml-4 text-lg">info@krishradinnovations.com</span>
          </div>
          <div className="flex items-center">
            {/* Phone Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

            <span className="ml-4 text-lg">+91 6005130753</span>
          </div>
        </div>
        
        {/* Map Placeholder */}
        <div className="bg-gray-200 rounded-lg flex items-center justify-center text-gray-600 font-semibold">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.1390719611395!2d74.88283587516715!3d32.68234718881902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e9ca552080edb%3A0x75f68baba72b76ce!2sTrikuta%20Nagar%2C%20Jammu%2C%20180012!5e0!3m2!1sen!2sin!4v1730028476895!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      {/* <div className="text-center mt-6 text-gray-600">
        Lorem ipsum odor amet, consectetur adipiscing elit.
      </div> */}
    </section>
    <Footer/>
    </div>
  )
}

export default ContactUs