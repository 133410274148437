import React, { useState } from 'react';
import EnquiryForm from '../../../Forms/EnquiryForm';

const CourseCard = ({
    program_master_name,
    program_fees,
    program_duration,
    study_mode,
    eligibility,
    exams_accepted,
    id, 
    onViewMore
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState('');
    const openModal = () => {
        setSelectedCollege(program_master_name);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="bg-white p-5 rounded-lg flex flex-col gap-3 shadow-md w-[300px]">
                <div>
                    <p className="text-lg text-black font-semibold">{program_master_name}</p>
                </div>

                {/* Study Mode */}
                <div className="flex justify-between items-center gap-5 border-b-2 py-2">
                    <p className="text-xs">Study Mode:</p>
                    <p className="text-sm text-black font-semibold">{study_mode}</p>
                </div>

                {/* Duration */}
                <div className="flex justify-between items-center gap-5 border-b-2 py-2">
                    <p className="text-xs">Duration:</p>
                    <p className="text-sm text-black font-semibold">{program_duration}</p>
                </div>

                {/* Eligibility */}
                <div className="flex justify-between items-center gap-5 border-b-2 py-2">
                    <p className="text-xs">Eligibility:</p>
                    <p className="text-sm text-black font-semibold">{eligibility}</p>
                </div>

                {/* Exams Accepted */}
                <div className="flex justify-between items-center gap-5 border-b-2 py-2">
                    <p className="text-xs">Exams Accepted:</p>
                    <p className="text-sm text-black font-semibold">
                        {Array.isArray(exams_accepted) ? exams_accepted.join(", ") : "N/A"}
                    </p>
                </div>

                {/* Fee */}
                <div className="flex justify-between items-center gap-5 border-b-2 py-2">
                    <p className="text-xs">Program Fees:</p>
                    <p className="text-sm text-black font-semibold">{program_fees}</p>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between items-center pt-4">
                    <button className="text-[#3ACA2E] flex gap-1 items-center">
                        <p className="text-xs font-semibold underline" onClick={() => onViewMore(id)}>View All</p>
                    </button>
                    <button 
                        className="py-1 px-4 rounded-2xl bg-[#3ACA2E] text-white text-sm font-semibold"
                        onClick={openModal}
                    >
                        Apply
                    </button>
                </div>

            </div>

            {isModalOpen && (
                <EnquiryForm
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    selectedCollege={selectedCollege}
                />
            )}
        </>
    );
};

export default CourseCard;
