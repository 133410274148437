import React, { useState, useEffect } from 'react';
import { Parser } from 'htmlparser2';
import { Base_URL } from '../../../apiConfig';

const Scholarship = ({ collegeDetails, logoPath }) => {
    const [scholarshipData, setScholarshipData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchScholarshipData = async () => {
            if (collegeDetails?.university_id) {
                try {
                    const response = await fetch(`${Base_URL}/get/scholarship/university/${collegeDetails.university_id}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch scholarship data");
                    }
                    const data = await response.json();
                    if (data.length > 0) {
                        setScholarshipData(data[0]);
                    } else {
                        setScholarshipData(null);
                    }
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchScholarshipData();
    }, [collegeDetails?.university_id]);

    const parseHtml = (html) => {
        const result = [];
        let currentElement = null;
        const elementStack = [];

        const parser = new Parser(
            {
                onopentag(name, attributes) {
                    const newElement = { name, attributes, children: [] };
                    if (currentElement) {
                        currentElement.children.push(newElement);
                        elementStack.push(currentElement);
                    } else {
                        result.push(newElement);
                    }
                    currentElement = newElement;
                },
                ontext(text) {
                    if (currentElement) {
                        currentElement.children.push(text);
                    } else {
                        result.push(text);
                    }
                },
                onclosetag(tagname) {
                    if (elementStack.length > 0) {
                        currentElement = elementStack.pop();
                    } else {
                        currentElement = null;
                    }
                },
            },
            { decodeEntities: true }
        );

        parser.write(html);
        parser.end();

        return result;
    };

    const renderContent = (content) => {
        return content.map((item, index) => {
            if (typeof item === "string") {
                return item;
            }

            const Tag = item.name;

            const isVoidElement = [
                "br",
                "img",
                "hr",
                "input",
                "meta",
                "link",
            ].includes(Tag);

            const props = {
                ...item.attributes,
                key: index,
                className: Tag === "table" 
                    ? "min-w-full border-collapse border border-gray-300 my-4"
                    : "",
            };

            if (Tag === "tr") {
                props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
            }

            if (Tag === "td" || Tag === "th") {
                props.className = "border border-gray-300 px-4 py-2";
            }

            if (isVoidElement) {
                return React.createElement(Tag, props);
            }

            return React.createElement(
                Tag,
                props,
                item.children && renderContent(item.children)
            );
        });
    };

    if (loading) {
        return <p>Loading scholarship data...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const parsedHtml = scholarshipData?.scholarship_desc 
        ? parseHtml(scholarshipData.scholarship_desc) 
        : [];

    return (
        <div className="px-5 py-8 bg-[#DCFFC4]">
            {/* University Header */}
            <div className="flex flex-col items-center md:flex-row md:items-center md:justify-center gap-3 mb-5">
                <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                    <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
                </div>
                <p className="md:text-3xl text-[22px] text-black md:font-semibold font-[700] text-left md:text-left">
                    {collegeDetails?.university_name}
                </p>
            </div>

            {/* Popular Scholarships Title */}
            <div className="mb-5">
                <p className="py-3 text-center text-lg text-black font-[500]">About Scholarship 2024</p>
            </div>

            {/* Scholarship Details */}
            <div className="prose max-w-none">
                {scholarshipData?.scholarship_desc 
                    ? renderContent(parsedHtml)
                    : <p>No scholarship information available.</p>
                }
            </div>
        </div>
    );
};

export default Scholarship;
