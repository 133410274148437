import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
const AddExamStream = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      exam_master_id: '',
      // created_by: 1,
      stream_id: ''
    });
    const [streams, setStreams] = useState([]); // To store the fetched stream values
    const[exams,setExams]=useState([])
    const location = useLocation();
    const { mainTitle, subTitle } = location.state || {};
    const [loading, setLoading] = useState(false); // Track loading state
    const { registration_id, token,name } = useSelector((state) => state.auth);
  
  
  

  
    useEffect(() => {
      // Fetch stream data from the API

      const fetchExams = async () => {
        try {
            const response = await axios.get(`${Base_URL}/get/exam_master`);
            console.log('exam',response.data)
            const examOptions = response.data.map((exam) => ({
              value: exam.id, 
              label: exam.exam_master_name, 
            }));
            setExams(examOptions);
        } catch (error) {
          console.error('Failed to fetch states:', error);
          toast.error('Failed to load state options.');
        }
        
      };
  
      fetchExams();
      const fetchStreams = async () => {
        try {
          const response = await axios.get(`${Base_URL}/get-values-array/streams`);
          const streamArray = response.data;
          setStreams(streamArray);
  
          // Pre-select the first stream id for submission (optional)
          if (streamArray.length > 0) {
            setFormData((prevData) => ({
              ...prevData,
              stream_id: streamArray[0][0] // Set the first value (e.g., 3) for submission
            }));
          }
        } catch (error) {
          console.error('Failed to fetch streams:', error);
          toast.error('Failed to load stream options.');
        }
      };
  
      fetchStreams();
    }, []);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  
    const handleStreamChange = (selectedOption) => {
      // Set the stream_id to the first value of the selected option
      setFormData((prevData) => ({
        ...prevData,
        stream_id: selectedOption.value // Set stream_id (the first value of the array)
      }));
    };

    const handleExamChange = (selectedOption) => {
       
        setFormData((prevData) => ({
          ...prevData,
          exam_master_id: selectedOption.value // Set stream_id (the first value of the array)
        }));
      };
  
    const handleSubmit = async (e) => {
      setLoading(true)
      e.preventDefault();
      try {
        const newExam = { ...formData };
        await axios.post(`${Base_URL}/create/stream_exam_master_mapping`, newExam, {
          headers: {
            'Content-Type': 'application/json',
             'authorization': `Bearer ${token}`
          },
          withCredentials: true // This allows cookies to be sent with the request
        });
        toast.success('Exam Stream added successfully!');
      } catch (error) {
        toast.error('Failed to add exam stream');
        console.error('Failed to add exam stream', error.response?.data || error.message);
        setLoading(false)
      }
      finally {
        setLoading(false); // Reset loading state after submission
      }
    };
  
  return (
    <div>
    <ToastContainer />
    <div className="bg-gray-100 p-6 h-screen">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          
          <div className="flex flex-col">
              <label htmlFor="name" className="mb-2 font-medium text-sm">Select Exam</label>
              <Select
                options={exams} 
                onChange={handleExamChange}
                placeholder={'Select Exam'} // Display placeholder text
                className="basic-single"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="stream_id" className="mb-2 font-medium text-sm">Select Stream</label>
              <Select
                options={streams.map((stream) => ({ value: stream[0], label: stream[1] }))} // Show the second value (stream name)
                onChange={handleStreamChange}
                placeholder={'Select Stream'} // Display placeholder text
                className="basic-single"
              />
            </div>
           
          </div>

          <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
          {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add Exam Stream'
              )}
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}

export default AddExamStream