import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const ProgramCourses = () => {
  const { programId } = useParams(); // Get programId from the URL
  const [courses, setCourses] = useState([]); // State to store courses
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // Log the programId for debugging
        console.log("Program ID from URL:", programId);

        // Fetch the course_master data from the API
        const response = await axios.get("https://api.collegedwarka.com/api/get/course_master");

        // Check the response data
        console.log("Course data fetched:", response.data);

        // Ensure that programId is an integer for comparison
        const filteredCourses = response.data.filter(
          (course) => course.program_id === parseInt(programId)
        );

        console.log("Filtered Courses:", filteredCourses); // Log filtered courses for debugging

        setCourses(filteredCourses); // Update state with filtered courses
        setLoading(false); // Set loading to false
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Failed to fetch courses"); // Set error message
        setLoading(false); // Set loading to false
      }
    };

    fetchCourses();
  }, [programId]); // Re-run effect when programId changes

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Function to handle course click
  const handleCourseClick = (courseId) => {
    // Navigate to the CareerMaster component with course_master_id as a parameter
    navigate(`/careerMaster/${courseId}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
          <div style={{height: "50px", borderbottom: "2px solid #ccc", boxshadow: "0 4px 6px rgba(0, 0, 0, 0.1)"}}><Header /></div>

      {/* Main content section */}
      <div className="flex-grow  p-6 mt-20 bg-[#F2FCEB]"> {/* Background and padding for main content */}
        <div className="container mx-auto"> {/* Center the content */}
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
            Courses for Program {programId}
          </h2>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {courses.length > 0 ? (
              courses.map((course) => (
                <div
                  key={course.id}
                  className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
                >
                  <div className="flex-grow" />
                  <div className="absolute bottom-0 w-full">
                    <button
                      className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
                      onClick={() => handleCourseClick(course.id)} // Call the function on click
                    >
                      {course.course_master_name}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No courses found for this program.</p>
            )}
          </div>
        </div>
      </div>

      <Footer /> {/* Add the Footer component */}
    </div>
  );
};

export default ProgramCourses;
