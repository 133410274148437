import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';

const AddCourseMasterDetails = () => {
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token); // Get token from Redux state
    const [courses, setCourses] = useState([]);
    const [modes, setModes] = useState([]);
    const [duration, setDuration] = useState([]);
    const [formData, setFormData] = useState({
      overview: '',
      jobs: '',
      salary: '',
      semester: '',
      avg_fee: '',
      course_master_id: null,
      mode_master_id: null,
      duration_master_id: null,
      intro: ''
    });

    const location = useLocation();
    const { mainTitle, subTitle } = location.state || {};
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchCourses = async () => {
        try {
          const response = await axios.get(`${Base_URL}/get-values-array/course_master`);
          const courseOptions = response.data.map((course) => ({
            value: course[0],
            label: course[1],
          }));
          setCourses(courseOptions);
        } catch (error) {
          toast.error('Failed to fetch courses');
          console.error('Failed to fetch courses:', error);
        }
      };
      fetchCourses();

      const fetchModes = async () => {
        try {
          const response = await axios.get(`${Base_URL}/get-values-array/mode_master`);
          const modeOptions = response.data.map((mode) => ({
            value: mode[0],
            label: mode[1],
          }));
          setModes(modeOptions);
        } catch (error) {
          toast.error('Failed to fetch modes');
          console.error('Failed to fetch modes:', error);
        }
      };
      fetchModes();

      const fetchDuration = async () => {
        try {
          const response = await axios.get(`${Base_URL}/get-values-array/duration_master`);
          const durationOptions = response.data.map((duration) => ({
            value: duration[0],
            label: duration[1],
          }));
          setDuration(durationOptions);
        } catch (error) {
          toast.error('Failed to fetch duration');
          console.error('Failed to fetch duration:', error);
        }
      };
      fetchDuration();
    }, []);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };

    const handleCourseChange = (selectedOption) => {
      setFormData({ ...formData, course_master_id: selectedOption.value });
    };

    const handleModeChange = (selectedOption) => {
      setFormData({ ...formData, mode_master_id: selectedOption.value });
    };

    const handleDurationChange = (selectedOption) => {
      setFormData({ ...formData, duration_master_id: selectedOption.value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const newCourse = { ...formData };
        await axios.post(`${Base_URL}/create/course_master_details`, newCourse, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Course Details added successfully!');
      } catch (error) {
        toast.error('Failed to add course details.');
        console.error('Failed to add course details:', error.response?.data || error.message);
        setLoading(false)
      } finally {
        setLoading(false);
      }
    };

    return (
      <div>
        <ToastContainer />
        <div className="bg-gray-100 p-6 min-h-screen">
          <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

          <div className="bg-white p-4 rounded-md">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="mb-2 font-medium text-sm">Select Course</label>
                  <Select
                    options={courses}
                    value={courses.find(course => course.value === formData.course_master_id)}
                    onChange={handleCourseChange}
                    placeholder="Select Course"
                    className="basic-single"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-medium text-sm">Select Mode</label>
                  <Select
                    options={modes}
                    value={modes.find(mode => mode.value === formData.mode_master_id)}
                    onChange={handleModeChange}
                    placeholder="Select Mode"
                    className="basic-single"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-2 font-medium text-sm">Select Duration</label>
                  <Select
                    options={duration}
                    value={duration.find(duration => duration.value === formData.duration_master_id)}
                    onChange={handleDurationChange}
                    placeholder="Select Duration"
                    className="basic-single"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="intro" className="mb-2 font-medium text-sm">Brief Intro</label>
                  <input
                    type="text"
                    id="intro"
                    name="intro"
                    value={formData.intro}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="semester" className="mb-2 font-medium text-sm">Total Semesters</label>
                  <input
                    type="text"
                    id="semester"
                    name="semester"
                    value={formData.semester}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="avg_fee" className="mb-2 font-medium text-sm">Average Fees</label>
                  <input
                    type="text"
                    id="avg_fee"
                    name="avg_fee"
                    value={formData.avg_fee}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                    required
                  />
                </div>

                <div className="flex flex-col md:col-span-2">
                  <label htmlFor="overview" className="mb-2 font-medium text-sm">Course Overview</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.overview}
                    onChange={(event, editor) => setFormData((prev) => ({ ...prev, overview: editor.getData() }))}
                  />
                </div>

                <div className="flex flex-col md:col-span-2">
                  <label htmlFor="jobs" className="mb-2 font-medium text-sm">Course Related Jobs</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.jobs}
                    onChange={(event, editor) => setFormData((prev) => ({ ...prev, jobs: editor.getData() }))}
                  />
                </div>

                <div className="flex flex-col md:col-span-2">
                  <label htmlFor="salary" className="mb-2 font-medium text-sm">Salary Range</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.salary}
                    onChange={(event, editor) => setFormData((prev) => ({ ...prev, salary: editor.getData() }))}
                  />
                </div>
              </div>

              <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    Submitting...
                  </div>
                ) : (
                  'Add Course Details'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default AddCourseMasterDetails;
