import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";



const EditDropDownNews = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // For loading spinner

  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id || 1; // Get login_id dynamically from Redux
  const { registration_id, token,name } = useSelector((state) => state.auth);
  


  const [formData, setFormData] = useState({
    title: '',
    content: '',
    announcement_date: '',
    trending_news: '',
  });
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');

  useEffect(() => {
    const dropDownNewsData = location.state?.news;
    if (dropDownNewsData) {
      try {
        const formattedDate = dropDownNewsData.announcement_date 
          ? new Date(dropDownNewsData.announcement_date).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0];

        setFormData({
          title: dropDownNewsData.title || '',
          content: dropDownNewsData.content || '',
          announcement_date: formattedDate,
          trending_news: dropDownNewsData.trending_news ? '1' : '0',
        });
      } catch (error) {
        console.error('Error formatting date:', error);
        toast.error('Error processing date. Using today\'s date as fallback.');
        
        setFormData({
          title: dropDownNewsData.title || '',
          content: dropDownNewsData.content || '',
          announcement_date: new Date().toISOString().split('T')[0],
          trending_news: dropDownNewsData.trending_news ? '1' : '0',
        });
      }
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('picture', image);
      }

      const response = await axios.put(`${Base_URL}/create-upload/news_announcements/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
           'authorization': `Bearer ${token}`
        },
        withCredentials: true // This allows cookies to be sent with the request
        
      });

      console.log('Updated dropdown news data response:', response.data);
      toast.success('Dropdown news data updated successfully!');
      setTimeout(() => {
        navigate('/dashboard/dropdown-news/all');
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to update dropdown news data:', error.response?.data || error.message);
      toast.error('Failed to update dropdown news data. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg min-h-screen shadow-md">
      <HeaderSection
        title="Edit DropDown News"
        breadcrumb={['DropDown News', 'Edit DropDown News']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="title" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="content" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Content</label>
              <textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="announcement_date" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Announcement Date</label>
              <input
                type="date"
                id="announcement_date"
                name="announcement_date"
                value={formData.announcement_date}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="trending_news" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Trending News</label>
              <select
                id="trending_news"
                name="trending_news"
                value={formData.trending_news}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              >
                <option value="">Select an option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="picture" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Upload Picture</label>
              <div className="flex border border-gray-300 rounded-md mt-2 overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]">
                  <span>Choose File</span>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded-md hover:bg-hovergreen"
          >
            Update
          </button>
          {error && <div className="text-red-600 mt-2">{error}</div>}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditDropDownNews;
